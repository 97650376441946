import { useMemo } from "react";

import {
  CreateCourseRequest,
  FilePurpose,
  PricingScheme,
  SortSchema,
  TaxRateDto,
  UpdateCourseRequest,
  UpdateCourseRequestSettings,
  WeekDay,
} from "@justraviga/classmanager-sdk";

import { classDurations, classYearData, transformData } from "../../classUtils";
import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { useLocationsAndRooms } from "../../data/useLocationsAndRooms";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { ImagePickerValue } from "../../forms/formComponentProps";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { enumLabel } from "../../translateUtils";
import { saveFile } from "../../uploadFile";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { useSettings } from "../useSettings";

type SettingSchema = UpdateCourseRequestSettings;
type ExtraSchema = {
  limitedCapacity: boolean; // used to determine whether we need to send a capacity to the API
};
export type CreateSchema = CreateCourseRequest &
  ExtraSchema &
  SettingSchema & { image: ImagePickerValue };
export type UpdateSchema = UpdateCourseRequest &
  ExtraSchema &
  SettingSchema & { image: ImagePickerValue };
type Schema = CreateSchema & UpdateSchema;
type Action = "create" | "update";

interface ClassFormDefinition {
  pricingScheme: PricingScheme;
  taxRates: TaxRateDto[];
}

const useDefinition = <Mode extends Action>({
  pricingScheme,
  mode,
  taxRates,
}: ClassFormDefinition & { mode: Action }) => {
  const { useApi } = getPlatformFunctions();

  const isAdvanced = mode !== "update";

  const { data: staffList } = useApi("listStaff", {
    selectAll: true,
    sort: { firstname: SortSchema.Asc, lastname: SortSchema.Asc },
  });
  const { locations, rooms } = useLocationsAndRooms();
  const { enabled: taxEnabled } = useSettings("tax");
  const { locale, use12HourClock } = useSettings("general");
  const clockType = use12HourClock ? "12-hour" : "24-hour";

  // Provide a list of rooms with their location, sorted alphabetically
  const roomOptions = useMemo(() => {
    return (rooms || [])
      .map(room => {
        const location = locations?.find(
          location => location.id === room.locationId,
        );

        return {
          label: `${location?.name} - ${room.name}`,
          value: room.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [rooms, locations]);

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic Information", ["name"])
    .group("Frequency & time", ["dayOfWeek", "startTime", "durationInMinutes"])
    .group("Pricing", ["price"])
    .group("Class information", ["image", "description", "roomId", "staffId"], {
      advanced: isAdvanced,
    })
    .group(
      "Class details",
      ["minAgeMonths", "maxAgeMonths", "capacity", "limitedCapacity"],
      {
        advanced: isAdvanced,
      },
    )
    .group("Customization", ["colorId"], {
      advanced: isAdvanced,
    })
    .group("Portal enrollment", ["portalEnrolmentEnabled"], {
      advanced: isAdvanced,
    })
    .group("Trials", ["trialsEnabled"], {
      advanced: isAdvanced,
    })
    .group("Billing", ["discountable"], { advanced: isAdvanced })
    .text("name", { label: "Name", required: true })
    .select("dayOfWeek", {
      label: "Repeats weekly on",
      description:
        "Recurring classes will take place once a week from the start to the end of the season.",
      required: true,
      data: Object.values(WeekDay).map(value => ({
        label: enumLabel("weekDays", value),
        value,
      })),
    })
    .time("startTime", {
      label: "Start time",
      locale: locale,
      clockType: clockType,
      required: true,
    })
    .select("durationInMinutes", {
      label: "Duration",
      required: true,
      data: classDurations,
    })
    .conditional(["price"], ["price"], () => {
      return pricingScheme == PricingScheme.None;
    })
    .money("price", {
      label: "Price per month",
      required: pricingScheme == PricingScheme.None,
    })
    .image("image", {
      label: "Class image",
      description:
        "For best results, please upload a 16:9 ratio image. We accept PNG and JPG format (maximum 8MB).",
    })
    .text("description", { label: "Description", numberOfLines: 10 })
    .select("roomId", {
      label: "Location",
      data: roomOptions,
    })
    .select("staffId", {
      label: "Teacher/instructor",
      data: (staffList?.data ?? []).map(value => {
        return {
          label: getFullName(value),
          value: value.id,
        };
      }),
    })
    .select("minAgeMonths", {
      label: "Minimum age",
      data: classYearData(),
    })
    .select("maxAgeMonths", {
      label: "Maximum age",
      data: classYearData(),
    })
    .switch("limitedCapacity", {
      label: "Limit class capacity",
      description:
        "Set a limit for the number of students that can take this class",
    })
    .conditional(["capacity"], ["limitedCapacity"], v => {
      return v.limitedCapacity === true;
    })
    .integer("capacity", { label: "Capacity", required: true })
    .colorPalette("colorId", { label: "Color" })
    .switch("portalEnrolmentEnabled", {
      label: "Allow portal enrollment",
      description: "Allow members to enroll on the portal",
      checked: true,
    })
    .switch("trialsEnabled", {
      label: "Member Portal trial registration",
      description:
        "Let your members sign up to trial this class through the Member Portal.",
    })
    .switch("discountable", {
      label: "Apply global discount schemes to this class",
      description:
        "Global discount schemes will apply and the class will count towards the student's total number of classes or hours.",
      checked: true,
    });

  if (taxEnabled) {
    builder
      .group("Tax", ["taxRateId"], { advanced: isAdvanced })
      .select("taxRateId", {
        label: "Tax rate",
        required: true,
        data: makeTaxRateSelectOptions(taxRates),
        notFoundLabel: (
          <ContentPlaceholder
            icon={"helpCircleOutline"}
            title={"No tax rates yet"}
            description={"Go to settings to add tax rates"}
          />
        ),
      });
  }

  return builder.getDefinition() as FormDefinition<
    Mode extends "create" ? CreateSchema : UpdateSchema
  >;
};

export const makeClassCreateFormDefinition =
  ({ pricingScheme, taxRates }: ClassFormDefinition) =>
  () =>
    useDefinition({ pricingScheme, mode: "create", taxRates });

export const makeClassUpdateFormDefinition =
  ({ pricingScheme, taxRates }: ClassFormDefinition) =>
  () =>
    useDefinition({ pricingScheme, mode: "update", taxRates });

export const makeClassCreateRequest =
  ({ seasonId }: { seasonId: string }) =>
  async (data: CreateSchema) => {
    const { api } = getPlatformFunctions();
    const { entity } = await api.courses.createCourse({
      createCourseRequest: {
        seasonId,
        ...transformData(data),
      } as CreateCourseRequest,
    });
    // Upload the image if it's not null
    if (data.image) {
      await saveFile(entity.id, FilePurpose.ClassImage, data.image);
    }
    return entity;
  };

export const makeClassUpdateRequest =
  ({ id, existingImage }: { id: string; existingImage: string | null }) =>
  async (data: UpdateSchema) => {
    const { api } = getPlatformFunctions();

    if (data.image) {
      // Upload the image if supplied and it's not a URL
      if (!data.image.startsWith("http")) {
        await saveFile(id, FilePurpose.ClassImage, data.image);
      }
    } else if (existingImage) {
      // Delete the image if we had one previously and it's no longer supplied
      await api.files.deleteFilesForEntity({
        entityId: id,
        purpose: FilePurpose.ClassImage,
      });
    }
    return api.courses.updateCourse({
      id,
      updateCourseRequest: transformData(data) as UpdateCourseRequest,
    });
  };
