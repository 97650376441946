/**
 *  Our global Hubspot account ID
 */
export const portalId = "139761362" as const;

export const hubspotForms = {
  registrationFormGuid: "882c0d64-ee1e-43ce-ac27-8b058d9eeb4a",
} as const;

export enum HubspotRecordTypeID {
  Contact = "0-1",
  Company = "0-2",
}
