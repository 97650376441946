/* eslint-disable camelcase */
import { SearchParamsObject } from "@algolia/client-search";
import {
  ErrorResponse,
  instanceOfErrorResponse,
} from "@justraviga/classmanager-sdk";
import { ConfigurationOptions as TypesenseConfigurationOptions } from "typesense/lib/Typesense/Configuration";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

import { SearchKeyLoader } from "./searchKeyLoader";
import { SearchCollection } from "./searchTypes";
import { Logger } from "../components/logger/loggerTypes";

const defaultConfig: TypesenseConfigurationOptions = {
  nodes: [],
  apiKey: "",
  connectionTimeoutSeconds: 5,
};

export interface BuildSearchClientConfigParams {
  host: string;
  protocol?: string;
  port?: number;
  apiKey?: string;
}

export function buildSearchClientConfig({
  host,
  port = 443,
  protocol = "https",
  apiKey = "",
}: BuildSearchClientConfigParams) {
  if (!host) {
    throw new Error("a host is required");
  }

  return {
    ...defaultConfig,
    nodes: [
      {
        host,
        port,
        protocol,
      },
    ],
    apiKey,
  };
}

const baseCollectionSearchOptions = {
  limit: 10,
};

export function buildTypsenseInstantsearchAdapterConfig(
  baseConfig: TypesenseConfigurationOptions,
  apiKey: string,
) {
  return {
    server: {
      ...baseConfig,
      apiKey,
    },
    additionalSearchParameters: {},
    collectionSpecificSearchParameters: {
      [SearchCollection.student]: {
        query_by: "firstname,lastname,familyName",
        ...baseCollectionSearchOptions,
      },
      [SearchCollection.family]: {
        query_by:
          "name,contacts.firstname,contacts.lastname,contacts.email,contacts.phone",
        ...baseCollectionSearchOptions,
      },
      [SearchCollection.staff]: {
        query_by: "firstname,lastname,email,phone,jobTitle",
        ...baseCollectionSearchOptions,
      },
      [SearchCollection.season]: {
        query_by: "name",
        ...baseCollectionSearchOptions,
      },
      [SearchCollection.course]: {
        query_by: "name,season,location,room,teacher",
        ...baseCollectionSearchOptions,
      },
    },
  };
}

export class SearchClientBuilder {
  private baseConfig: TypesenseConfigurationOptions;
  private keyLoader: SearchKeyLoader;
  private logger: Logger;

  constructor(
    keyLoader: SearchKeyLoader,
    baseConfig: TypesenseConfigurationOptions,
    logger: Logger,
  ) {
    this.keyLoader = keyLoader;
    this.baseConfig = baseConfig;
    this.logger = logger;
  }

  async resetKey() {
    return this.getClient();
  }

  async getClient() {
    let apiKey;
    try {
      apiKey = await this.keyLoader.load();
    } catch (err) {
      if (instanceOfErrorResponse(err as ErrorResponse)) {
        if ((err as ErrorResponse).statusCode === 401) {
          this.logger.error(
            (err as ErrorResponse).messages[0] || "Error loading search key",
          );
          return null;
        }
      }
    }

    if (!apiKey) {
      this.logger.error("Unexpected error generating search key for client");
      throw new Error("Unexpected error generating search key for client");
    }

    return this.buildClient(apiKey);
  }

  private buildClient(apiKey: string): TypesenseInstantsearchAdapter {
    const baseClient = new TypesenseInstantsearchAdapter(
      buildTypsenseInstantsearchAdapterConfig(this.baseConfig, apiKey),
    );

    const adaptedClient = {
      ...baseClient,
      searchClient: {
        ...baseClient.searchClient,
        search: async (
          requests: Array<{ indexName: string; params: SearchParamsObject }>,
        ) => {
          const shouldSearch = requests.some(
            ({ params: { query } }) => query !== "",
          );
          if (shouldSearch) {
            return baseClient.searchClient.search(requests);
          }
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        },
      },
    };

    return adaptedClient as TypesenseInstantsearchAdapter;
  }
}
