import { CourseDto, LessonDto } from "@justraviga/classmanager-sdk";

import { uniqueValuesForKey } from "../../../../collectionUtils";
import { colors } from "../../../../colors";
import { cn } from "../../../../cssUtils";
import { dateToday, generateDateRange } from "../../../../dateUtils";
import { formatDate } from "../../../../intlFormatter";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ContentPlaceholder } from "../../../ui/ContentPlaceholder";
import {
  GoToAttendancesFunction,
  GoToLessonFunction,
  LessonCard,
} from "../dashboard/LessonCard";

export const StaffScheduleWidget = ({
  goToAttendances,
  goToLesson,
  staffId,
}: {
  goToAttendances: GoToAttendancesFunction;
  goToLesson: GoToLessonFunction;
  staffId: string;
}) => {
  const { BaseCard } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  // Get all lessons for the week
  const startDate = dateToday();
  const dates = generateDateRange(startDate, 7);
  const endDate = dates[6];
  const { data: lessons, isLoading: isLoadingLessons } = useApi(
    "listOnDateLesson",
    {
      fromDate: startDate,
      toDate: endDate,
      where: {
        staffId: {
          equals: staffId,
        },
      },
    },
  );

  // Fetch all attendance stats for these lessons
  const { data: lessonAttendances, isLoading: isLoadingAttendances } = useApi(
    "statsAttendanceStats",
    {
      lessons: (lessons ?? []).map(lesson => ({
        classId: lesson.classId,
        date: lesson.date,
        time: lesson.startTime,
      })),
    },
    { enabled: !!lessons && lessons.length > 0 },
  );

  // Get all courses that relate to these lessons
  const courseIds = lessons ? uniqueValuesForKey("classId", lessons) : [];
  const { data: courses, isLoading: isLoadingCourses } = useApi("listCourse", {
    where: {
      id: {
        in: courseIds,
      },
      staffId: {
        equals: staffId,
      },
    },
  });
  const getCourse = (courseId: string) =>
    courses?.data.find(c => c.entity.id === courseId)?.entity;

  const getAttendance = (lesson: LessonDto) => {
    const attendance = lessonAttendances?.find(
      a =>
        a.classId === lesson.classId &&
        a.date === lesson.date &&
        a.time === lesson.startTime,
    );
    return attendance?.completePercent ?? 0;
  };

  const isLoading =
    isLoadingLessons || isLoadingCourses || isLoadingAttendances;

  return (
    <BaseCard
      collapsible={true}
      title={"Lessons"}
      bodySlot={
        !isLoading && (
          <StaffScheduleCardBody
            dates={dates}
            endDate={endDate}
            goToAttendances={goToAttendances}
            goToLesson={goToLesson}
            lessons={lessons!}
            getCourse={getCourse}
            getAttendance={getAttendance}
          />
        )
      }
    />
  );
};

const StaffScheduleCardBody = ({
  lessons,
  dates,
  endDate,
  goToAttendances,
  goToLesson,
  getCourse,
  getAttendance,
}: {
  lessons: LessonDto[];
  dates: string[];
  endDate: string;
  goToAttendances: GoToAttendancesFunction;
  goToLesson: GoToLessonFunction;
  getCourse: (courseId: string) => CourseDto | undefined;
  getAttendance: (lesson: LessonDto) => number;
}) => {
  const { Icon, Text, View } = useGenericComponents();

  return (
    <View>
      <Text className="text-heading4-600 font-semibold text-grey-900">
        Next 7 days
      </Text>
      {!lessons || lessons.length === 0 ? (
        <ContentPlaceholder
          icon="calendarClearOutline"
          title="No lessons today"
        />
      ) : (
        <View className="flex flex-col">
          {dates.map(date => {
            const lessonsForDay = lessons.filter(l => l.date === date);
            return (
              <View
                key={date}
                className={cn("border-grey-300 flex flex-col space-y-3 py-5", {
                  "border-b": date !== endDate,
                })}>
                <View>
                  <Text className="text-label-400 text-grey-600">
                    {formatDate(date, "weekdayDayMonthYear")}
                    {lessonsForDay.length > 0
                      ? ` (${lessonsForDay.length} ${lessonsForDay.length === 1 ? "class" : "classes"})`
                      : ""}
                  </Text>
                </View>

                {lessonsForDay.length === 0 ? (
                  <View className="flex flex-row space-x-2 items-center my-5">
                    <Icon
                      name={"helpCircleOutline"}
                      size={24}
                      color={colors.grey[800]}
                    />
                    <Text className="text-body-400 text-grey-900">
                      No classes on this day
                    </Text>
                  </View>
                ) : (
                  lessonsForDay.map(lesson => {
                    const course = getCourse(lesson.classId);
                    if (!course) {
                      return null;
                    }
                    const percentComplete = getAttendance(lesson);
                    return (
                      <View
                        key={[
                          lesson.classId,
                          lesson.date,
                          lesson.startTime,
                        ].join("")}>
                        <LessonCard
                          goToAttendances={goToAttendances}
                          goToLesson={goToLesson}
                          course={course}
                          lesson={lesson}
                          percentComplete={percentComplete}
                        />
                      </View>
                    );
                  })
                )}
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};
