import {
  Breadcrumb,
  useEnrolmentsActions,
  useStudentTrialsDatatable,
  useTrialActions,
} from "shared/components";
import { getFullName } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StudentTrialsPage = ({ studentId }: { studentId: string }) => {
  const actions = useTrialActions();
  const enrolmentActions = useEnrolmentsActions();

  const { data: student } = useApi("getStudent", { id: studentId });

  const { datatable } = useStudentTrialsDatatable({
    Datatable,
    api,
    studentId,
    goToClass: id => Router.push("ClassDetails", { id }),
    showUpdateForm: item => actions.showUpdateForm(item),
    enrollForClass: item =>
      enrolmentActions.showCreateForm({
        studentIds: [item.studentId],
        showStudentSelector: false,
      }),
    markAsNotContinuing: actions.archive,
    deleteOne: actions.deleteOne,
  });

  if (!student) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Students",
      onClick: () => Router.push("StudentList"),
    },
    {
      text: getFullName(student),
      onClick: () => Router.push("StudentDetails", { id: student.id }),
    },
    {
      text: "Trials",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Trials"} />
        <div>
          <ProtectedOverlay permission="enrolments:manage">
            <Button
              text={"Add"}
              size="sm"
              onClick={() =>
                actions.showCreateForm({ studentIds: [studentId] })
              }
              variant="brand"
            />
          </ProtectedOverlay>
        </div>
      </CompanyTitleBar>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  StudentTrialsPage,
  "Student trials",
);

export { PermissionChecked as StudentTrialsPage };
