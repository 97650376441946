import { IconName } from "./availableIcons";
import { colors } from "./colors";
import { Permission } from "./permission";

export type BannerVariant =
  | "default"
  | "success"
  | "error"
  | "info"
  | "warning"
  | "critical"
  | "important"
  | "brand";

export type BannerIcon = {
  name: IconName;
  color?: string;
};

export type BannerAction = {
  text: string;
  onClick: () => void;
  permission?: Permission;
};

export const bannerVariantContainerClass: Record<BannerVariant, string> = {
  default: "border-grey-300 bg-grey-100",
  success: "border-grey-300 bg-grey-100",
  info: "border-grey-300 bg-grey-100",
  error: "border-grey-300 bg-grey-100",
  warning: "border-grey-300 bg-grey-100",
  critical: "border-red-600 bg-red-600",
  important: "border-grey-900 bg-grey-900",
  brand: "border-brand-900 bg-brand-900",
};

export const bannerVariantTextColorClass: Record<BannerVariant, string> = {
  default: "text-grey-900",
  success: "text-grey-900",
  info: "text-grey-900",
  error: "text-grey-900",
  warning: "text-grey-900",
  critical: "text-white",
  important: "text-white",
  brand: "text-white",
};

export const bannerVariantIconColor: Record<BannerVariant, string> = {
  default: colors.grey["900"],
  success: colors.grey["900"],
  info: colors.grey["900"],
  error: colors.grey["900"],
  warning: colors.grey["900"],
  critical: colors.white,
  important: colors.white,
  brand: colors.white,
};

export const bannerIcon: Partial<
  Record<BannerVariant, BannerIcon | undefined>
> = {
  success: { name: "checkmarkCircle", color: colors.green["600"] },
  info: { name: "informationCircle", color: colors.blue["600"] },
  error: { name: "alertCircle", color: colors.red["600"] },
  warning: { name: "warning", color: colors.yellow["600"] },
  critical: { name: "warning", color: colors.white },
};
