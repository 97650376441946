import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  Checkbox,
  CheckboxWithTextProps,
} from "@/modules/common/form/Checkbox";

const options: Array<Option<CheckboxWithTextProps>> = [
  {
    title: "Text & Titles",
    props: [
      { text: "Just text", checked: true, onChange: () => {} },
      { text: "Text", title: "With title", checked: false, onChange: () => {} },
    ],
  },
  {
    title: "Required",
    props: [
      {
        text: "Text",
        title: "With title",
        checked: false,
        required: true,
        onChange: () => {},
      },
      {
        text: "Just text",
        checked: false,
        required: true,
        onChange: () => {},
      },
    ],
  },
  {
    title: "States",
    props: [
      { text: "Checked", checked: true, onChange: () => {} },
      {
        text: "Indeterminate",
        checked: "indeterminate",
        onChange: () => {},
      },
      { text: "Unchecked", checked: false, onChange: () => {} },
    ],
  },
  {
    title: "Disabled",
    props: [
      {
        text: "just text",
        checked: false,
        onChange: () => {},
        disabled: true,
      },
    ],
  },
  {
    title: "Clickable Areas",
    props: [
      {
        title: "Medium clickable area",
        text: "Used in most forms. Has negative margins to align with labels and other form elements",
        clickableArea: "md",
        checked: false,
        onChange: () => {},
      },
      {
        title: "Large clickable area",
        text:
          "Use in datatable selection columns. Label (and hence, clickable area) expands to fill the available space. " +
          "Relies on containing component to adjust position as required.",
        clickableArea: "lg",
        checked: false,
        onChange: () => {},
      },
    ],
  },
];
export const CheckboxPage = () => {
  return (
    <>
      <DocWrapper title="Checkbox" component={Checkbox} options={options} />
    </>
  );
};
