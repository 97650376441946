import { useCallback } from "react";

import { StudentDto } from "@justraviga/classmanager-sdk";

import { dateAdd, dateToday, getAgeInYears } from "../../../../dateUtils";
import { formatDate } from "../../../../intlFormatter";
import { getFullName } from "../../../../personUtil";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";
import { Avatar } from "../../../ui/Avatar";
import { CollapsibleWidget } from "../../../ui/CollapsibleWidget";

const UpcomingBirthdaysWidget = () => {
  const { View, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const today = dateToday();
  const endDate = dateAdd(today, 7, "day");

  const { data: students, isLoading } = useApi("listBirthdaysStudent", {
    fromDate: today,
    toDate: endDate,
  });

  const studentAgeOnBirthDay = useCallback((student: StudentDto) => {
    const age = getAgeInYears(student.dateOfBirth!) + 1;
    return `${age} year${age > 1 ? "s" : ""}`;
  }, []);

  return (
    <CollapsibleWidget
      title={"Upcoming birthdays"}
      loading={isLoading}
      empty={!students?.length}
      emptyText="No upcoming birthdays"
      content={
        <View className={"space-y-2"}>
          <Text className={"text-2xl text-heading4-600"}>Next 7 days</Text>
          <View className={"flex flex-col space-y-2"}>
            {(students ?? []).map((student: StudentDto) => (
              <View
                className={
                  "flex flex-row items-center justify-between space-x-3 py-2"
                }
                key={student.id}>
                <View>
                  <Avatar
                    person={student}
                    size={"md"}
                    src={student.profilePicture}
                  />
                </View>
                <View className={"flex-grow space-y-1"}>
                  <Text className={"text-label-600"}>
                    {getFullName(student)}
                  </Text>
                  <Text className={"text-caption-400 text-grey-600"}>
                    {formatDate(student.dateOfBirth!, "dayMonthYear")}
                  </Text>
                </View>
                <Text className={"text-grey-600"}>
                  {studentAgeOnBirthDay(student)}
                </Text>
              </View>
            ))}
          </View>
        </View>
      }
    />
  );
};

export const PermissionChecked = withPermissionCheck(
  "members:view",
  UpcomingBirthdaysWidget,
);

export { PermissionChecked as UpcomingBirthdaysWidget };
