import { AccountRole, LoginDto } from "@justraviga/classmanager-sdk";

import {
  useAuthState,
  useCatalogueContext,
  useCourseSearch,
  useFormActions,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginForm } from "@/modules/auth/common/LoginForm";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { useAuthRedirect } from "@/modules/auth/redirect/useAuthRedirect";
import { Router } from "@/routing/router";

export const CatalogueLoginPage = () => {
  const { company } = useCatalogueContext();
  const { setUser, setAccount, onLogin, setOnLogin } = useAuthState();
  const { redirect, getRedirectProps } = useAuthRedirect();
  const { searchValues } = useCourseSearch();
  const { submit, isLoading: loginLoading } = useFormActions();

  const { name: redirectTarget } = getRedirectProps() || {};

  const onSuccess = async ({ user, token }: LoginDto) => {
    setUser(user, token);

    const accounts = await api.auth.listAccounts();
    const account = accounts.data.find(account => {
      return (
        account.role === AccountRole.Family &&
        account.company?.id === company.id
      );
    });

    if (account) {
      setAccount(account);
    } else {
      Router.push("CatalogueCreateAccount", {
        slug: company.slug,
      });
    }

    // Allow us to redirect to a different page after successful login
    if (onLogin !== undefined) {
      onLogin();
      setOnLogin(undefined);
    } else {
      Router.push("CompanyLandingPage", {
        slug: company.slug,
      });
    }
  };

  return (
    <NonAuthLayout branded>
      <AuthPageCard
        header={
          <LoginHeader
            companyLogo={company.logo}
            handleBackButton={() => {
              if (redirectTarget === "MemberBasket") {
                Router.push("Courses", {
                  ...searchValues,
                  query: searchValues.query || "",
                  ages: searchValues.ages.map(String),
                  slug: company.slug,
                });
              } else {
                redirect();
              }
            }}
          />
        }
        title={company.name}
        subtitle={"Login to your Class Manager account"}
        content={
          <LoginForm
            variant="brand"
            submitHandler={submit}
            isLoading={loginLoading}
            defaultValues={{}}
            onSuccess={onSuccess}
          />
        }
        footer={
          <SwitchAuthType
            message="New to Class Manager?"
            onClick={() =>
              Router.push("CatalogueRegister", { slug: company.slug })
            }
            linkText="Register">
            <PoweredBy />
          </SwitchAuthType>
        }
      />
    </NonAuthLayout>
  );
};
