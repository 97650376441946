import { SearchResultTitleType } from "shared/lib";

export const SearchResultTitle = ({ title, count }: SearchResultTitleType) => {
  return (
    <div className={`flex items-center px-4 py-3 text-grey-600`}>
      <p className={`line-clamp-1 flex-grow`}>
        {title}
        <span className="ml-2 flex-shrink-0">
          ({count > 10 ? "10+" : count})
        </span>
      </p>
    </div>
  );
};
