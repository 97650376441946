import { useEffect, useState } from "react";

import { LessonDto, SortSchema } from "@justraviga/classmanager-sdk";

import {
  GoToAttendancesFunction,
  GoToLessonFunction,
  LessonCard,
} from "./LessonCard";
import { uniqueValuesForKey } from "../../../../collectionUtils";
import { getFullName, getInitials } from "../../../../personUtil";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { SelectComponent, SelectItem } from "../../../interfaces";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";
import { ContentPlaceholder } from "../../../ui/ContentPlaceholder";

const TodaysScheduleWidget = ({
  Select,
  goToAttendances,
  goToLesson,
}: {
  Select: SelectComponent;
  goToAttendances: GoToAttendancesFunction;
  goToLesson: GoToLessonFunction;
}) => {
  const { BaseCard } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);

  // We should always have an account at this point
  const { account } = useAuthState();
  const staffId = account!.entityId!;

  useEffect(() => {
    setSelectedStaffId(staffId);
  }, [staffId]);

  // Get all staff
  const { data: staff } = useApi("listStaff", {
    selectAll: true,
    sort: { firstname: SortSchema.Asc, lastname: SortSchema.Asc },
  });

  // Get all lessons for today
  const today = new Date().toISOString().split("T")[0];
  const { data: lessons } = useApi("listOnDateLesson", {
    fromDate: today,
    toDate: today,
  });

  // Fetch all attendance stats for today's lessons
  const { data: lessonAttendances } = useApi(
    "statsAttendanceStats",
    {
      lessons: (lessons ?? []).map(lesson => ({
        classId: lesson.classId,
        date: lesson.date,
        time: lesson.startTime,
      })),
    },
    { enabled: !!lessons && lessons.length > 0 },
  );

  // Get all courses that relate to today's lessons
  const courseIds = lessons ? uniqueValuesForKey("classId", lessons) : [];
  const { data: courses } = useApi("listCourse", {
    where: {
      id: {
        in: courseIds,
      },
    },
  });
  const getCourse = (courseId: string) =>
    courses?.data.find(c => c.entity.id === courseId)?.entity;

  // Filter lessons down to only those that relate to the selected staff member
  const filteredCourseIds = courses
    ? courses.data
        .filter(c => !selectedStaffId || c.entity.staffId === selectedStaffId)
        .map(course => course.entity.id)
    : [];

  const filteredLessons = lessons
    ? lessons.filter(lesson => filteredCourseIds.includes(lesson.classId))
    : [];

  const staffOptions: Array<SelectItem> = (staff?.data ?? []).map(staff => ({
    avatar: staff.profilePicture ?? getInitials(staff),
    label: getFullName(staff) + (staff.id === staffId ? " (Me)" : ""),
    description: staff.jobTitle ?? undefined,
    value: staff.id,
  }));

  const getAttendance = (lesson: LessonDto) => {
    const attendance = lessonAttendances?.find(
      a =>
        a.classId === lesson.classId &&
        a.date === lesson.date &&
        a.time === lesson.startTime,
    );
    return attendance?.completePercent ?? 0;
  };

  const { Text, View } = useGenericComponents();

  return (
    <BaseCard
      collapsible={true}
      title={"Lessons"}
      bodySlot={
        <View className="">
          <Text className="mb-2 text-heading4-600 font-semibold text-grey-900">
            Today
          </Text>
          <View className="mb-5 max-w-[256px]">
            <Select
              data={[{ label: "Everyone", value: null }, ...staffOptions]}
              value={selectedStaffId}
              onSelect={v => setSelectedStaffId(v === null ? null : String(v))}
            />
          </View>
          {filteredLessons.length === 0 ? (
            <ContentPlaceholder
              icon="calendarClearOutline"
              title="No lessons today"
            />
          ) : (
            <View className="flex flex-col space-y-3">
              {filteredLessons.map(lesson => {
                const course = getCourse(lesson.classId);
                if (!course) {
                  return null;
                }
                const percentComplete = getAttendance(lesson);
                return (
                  <View
                    key={[lesson.classId, lesson.date, lesson.startTime].join(
                      "",
                    )}>
                    <LessonCard
                      goToAttendances={goToAttendances}
                      goToLesson={goToLesson}
                      course={course}
                      lesson={lesson}
                      percentComplete={percentComplete}
                    />
                  </View>
                );
              })}
            </View>
          )}
        </View>
      }
    />
  );
};

const PermissionChecked = withPermissionCheck(
  "schedule:view",
  TodaysScheduleWidget,
);
export { PermissionChecked as TodaysScheduleWidget };
