import { PropsWithChildren } from "react";

import { InstantSearch } from "react-instantsearch-core";

import { ContentPlaceholder } from "shared/components";
import {
  GlobalSearchStatus,
  useSearchClient,
  useSearchStatus,
} from "shared/lib";

import { GlobalSearchInput } from "@/modules/common/search/GlobalSearchInput";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { SearchResults } from "@/modules/common/search/SearchResults";
import { SearchResultsCount } from "@/modules/common/search/SearchResultsCount";

const StyledSearchContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <div
    data-testid="search-dialog-container"
    className={`min-h-96 relative flex h-[100vh] min-w-min flex-col md:h-fit md:max-h-[calc(100vh-(2*4rem))]`}>
    {children}
  </div>
);

const SearchContainer = ({ children }: PropsWithChildren) => {
  const { status } = useSearchStatus();
  const { searchClient: adaptedTypesenseSearchClient } = useSearchClient();

  if (status === GlobalSearchStatus.LOADING) {
    return (
      <>
        <LoadingPlaceholder />
      </>
    );
  }

  if (!adaptedTypesenseSearchClient || status !== GlobalSearchStatus.OK) {
    return (
      <>
        <SearchResultsListContainer>
          <ContentPlaceholder
            icon="helpCircleOutline"
            title="Search feature unavailable"
            description="The search feature could not be loaded at this time. Please close the window and try again later."
          />
        </SearchResultsListContainer>
      </>
    );
  }

  return (
    <InstantSearch
      searchClient={adaptedTypesenseSearchClient.searchClient}
      indexName={""}>
      <GlobalSearchInput />
      {children}
    </InstantSearch>
  );
};

const SearchResultsListContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <div
    className={
      "flex grow flex-col overflow-x-hidden px-1 py-1 md:justify-center"
    }
    data-testid="search-results-container">
    {children}
  </div>
);

const SearchDialogContent = () => {
  const { status } = useSearchStatus();

  if (status === GlobalSearchStatus.OK) {
    return (
      <>
        <SearchResultsCount />
        <SearchResultsListContainer>
          <SearchResults />
        </SearchResultsListContainer>
      </>
    );
  }

  return (
    <SearchResultsListContainer>
      <ContentPlaceholder
        icon="helpCircleOutline"
        title="Search feature unavailable"
        description="The search feature could not be loaded at this time. Please close the window and try again later."
      />
    </SearchResultsListContainer>
  );
};

export const SearchDialog = () => {
  return (
    <StyledSearchContainer>
      <SearchContainer>
        <SearchDialogContent />
      </SearchContainer>
    </StyledSearchContainer>
  );
};
