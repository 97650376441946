import { ClassColor, CourseDto, LessonDto } from "@justraviga/classmanager-sdk";

import { displayLessonTimesExact } from "../../../../classUtils";
import { colors } from "../../../../colors";
import { stopPropagation } from "../../../../eventUtils";
import { colorPaletteColors } from "../../../../forms/formComponentProps";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface LessonKey {
  courseId: string;
  date: string;
  time: string;
}

export type GoToAttendancesFunction = (lessonKey: LessonKey) => void;
export type GoToLessonFunction = (lessonKey: LessonKey) => void;

export const LessonCard = ({
  goToAttendances,
  goToLesson,
  course,
  lesson,
  percentComplete,
}: {
  goToAttendances: GoToAttendancesFunction;
  goToLesson: GoToLessonFunction;
  course: CourseDto;
  lesson: LessonDto;
  percentComplete: number;
}) => {
  const { Button, Icon, Pressable, Text, View } = useGenericComponents();
  const backgroundColor =
    colorPaletteColors[course?.colorId ?? ClassColor.Grey];
  return (
    <Pressable
      onClick={() =>
        goToLesson({
          courseId: course.id,
          date: lesson.date,
          time: lesson.startTime,
        })
      }>
      <View
        className="px-3 py-2 rounded flex flex-col space-y-1 cursor-pointer"
        style={{
          backgroundColor,
        }}>
        <View className="flex flex-row justify-between items-center space-x-1 relative">
          <View className="flex-1">
            <Text
              className="text-grey-900 text-body-600 font-semibold"
              truncate={true}>
              {course.name}
            </Text>
          </View>
          <View className="flex-initial">
            <View className="-my-2 -mx-2">
              <Button
                variant="tertiary"
                text="Take attendance"
                size="xs"
                onClick={e => {
                  stopPropagation(e);
                  goToAttendances({
                    courseId: course.id,
                    date: lesson.date,
                    time: lesson.startTime,
                  });
                }}
              />
            </View>
          </View>
        </View>
        <View className="flex flex-row items-center justify-between">
          <View className="flex-1">
            <Text className="text-label-400 text-grey-900" truncate={true}>
              {displayLessonTimesExact(lesson)}
            </Text>
          </View>

          <View className="flex-initial flex flex-row items-center gap-x-1 text-label-400 text-grey-900">
            <Icon
              name={
                percentComplete < 100 ? "alertCircleOutline" : "checkmarkCircle"
              }
              size={16}
              color={colors.grey[900]}
            />
            <View>
              <Text>{percentComplete}% complete</Text>
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};
