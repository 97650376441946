import { useState } from "react";

import {
  CreateSeasonRequest,
  PricingScheme,
  TaxRateDto,
  UpdateSeasonRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { colors } from "../../colors";
import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { useSettings } from "../useSettings";

type UpdateSchema = UpdateSeasonRequest;
type CreateSchema = CreateSeasonRequest;

export const useSeasonFormDefinition = (taxRates: TaxRateDto[]) => {
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const { enabled: taxEnabled } = useSettings("tax");

  const builder = new FormDefinitionBuilder<CreateSeasonRequest>()
    .group("Basic Information", ["name", "startAt", "endAt"] as Array<
      keyof CreateSeasonRequest
    >)
    .text("name", {
      label: "Name",
      required: true,
      description: "Recommended maximum length: 20 characters",
    })
    .date("startAt", {
      label: "Start date",
      required: true,
      maxDate: endDate ? new Date(endDate) : undefined,
    })
    .conditional(["startAt"], ["startAt"], formVals => {
      setStartDate(_ => formVals.startAt as string);
      return true;
    })
    .date("endAt", {
      label: "End date",
      required: true,
      minDate: startDate ? new Date(startDate) : undefined,
    })
    .conditional(["endAt"], ["endAt"], formVals => {
      setEndDate(_ => formVals.endAt as string);
      return true;
    })
    .group("Registration", [
      "registrationOpen",
      "registrationFee",
      "maxRegistrationFee",
    ])
    .switch("registrationOpen", {
      label: "Open Member Portal registration",
      description:
        "This class will be available for registration on the Member Portal.",
    })
    .money("registrationFee", {
      label: "Registration fee per student",
      keepZeroValue: true,
      description:
        "Each time a new student registers for this season, a fixed fee will be applied.",
    })
    .money("maxRegistrationFee", {
      label: "Maximum registration fee per family",
      description:
        "The maximum registration fee will cap the amount charged when enrolling multiple students from the same family.",
    });

  if (taxEnabled) {
    builder.select("registrationFeeTaxRateId", {
      label: "Registration fee tax rate",
      required: true,
      data: makeTaxRateSelectOptions(taxRates),
      notFoundLabel: (
        <ContentPlaceholder
          icon={"helpCircleOutline"}
          title={"No tax rates yet"}
          description={"Go to settings to add tax rates"}
        />
      ),
    });
  }

  return builder.getDefinition();
};

export const useUpdatePricingSchemeDefinition = () => {
  return new FormDefinitionBuilder<UpdateSeasonRequest>()
    .bigRadio("pricingScheme", {
      singleColOnly: true,
      options: [
        {
          icon: "cash",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Price per class per month",
          description: "Charge a fixed monthly fee for each class.",
          value: PricingScheme.None,
        },
        {
          icon: "personAdd",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of classes",
          description:
            "Charge per month based on the number of classes a student takes per week.",
          value: PricingScheme.ClassCount,
        },
        {
          icon: "time",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of hours",
          description:
            "Charge per month based on the number of hours of classes a student takes per week.",
          value: PricingScheme.TotalDuration,
        },
      ],
    })
    .getDefinition();
};

export const makeSeasonCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.seasons.createSeason({
      createSeasonRequest: formData,
    });
  };

export const makeSeasonUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.seasons.updateSeason({
      id,
      updateSeasonRequest: formData,
    });
  };
