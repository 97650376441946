import { FC } from "react";

import { ChartAxisDto } from "@justraviga/classmanager-sdk";

import { ChartWidget } from "./ChartWidget";
import { dateSubtract, dateToday, dateToMonth } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";

interface Props {
  EnrolmentTrendChart: FC<{ data: ChartAxisDto[] }>;
}

const EnrolmentTrendsWidget = ({ EnrolmentTrendChart }: Props) => {
  const { useApi } = getPlatformFunctions();

  const today = dateToday();

  const { data, isLoading } = useApi("chartByMonthEnrolment", {
    fromDate: dateToMonth(dateSubtract(today, 5, "month")),
    toDate: dateToMonth(today),
  });

  const empty =
    !data ||
    !data.length ||
    data.every(month =>
      month.data.every(d => d.values.every(v => v.value === 0)),
    );

  return (
    <ChartWidget
      title="Enrollment trends"
      chartTitle="Last 6 months"
      lastUpdated={new Date()}
      loading={isLoading}
      empty={empty}
      emptyText="Nothing to show here">
      <EnrolmentTrendChart data={data!} />
    </ChartWidget>
  );
};

const PermissionChecked = withPermissionCheck(
  "financial:view",
  EnrolmentTrendsWidget,
);

export { PermissionChecked as EnrolmentTrendsWidget };
