import { StudentDto } from "@justraviga/classmanager-sdk";

import { actionMenuBuilder } from "../../../../action-menu/actionMenuBuilder";
import { StudentImplementedActions } from "../../../../actions/useSharedStudentActions";

export const useStudentCardHeaderMenu = (
  studentActions: StudentImplementedActions,
  student: StudentDto,
) =>
  actionMenuBuilder()
    .item({
      text: "Edit",
      icon: "createOutline",
      onClick: () => studentActions.showUpdateForm(student),
      permission: "members:manage",
    })
    .item({
      text: "Archive",
      icon: "archiveOutline",
      onClick: () => studentActions.archive(student),
      permission: "members:manage",
    })
    .item({
      text: "Delete",
      icon: "trashOutline",
      variant: "destructive",
      onClick: () => studentActions.deleteOne(student),
      permission: "members:manage",
    });
