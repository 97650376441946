import { useAuthState } from "../../auth/useAuthState";
import { enumLabel } from "../../translateUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";

export const PermissionDeniedPlaceholder = () => {
  const { Text, View } = useGenericComponents();
  const { user, account } = useAuthState();

  return (
    <View className={"flex flex-col items-center p-2"}>
      <ContentPlaceholder
        icon={"helpCircleOutline"}
        title={"You don't currently have access to this content."}
        description={
          "Contact the owner of your company if you need them to grant you permission to use it."
        }
      />
      <View
        className={
          "flex flex-col items-center space-y-4 pt-8 text-center text-label-400 text-sm text-grey-600"
        }>
        <Text>
          Account: {user?.firstname} {user?.lastname}
        </Text>
        <Text>
          Role: {enumLabel("accountPermissionSet", account?.permissionSet)}
        </Text>
      </View>
    </View>
  );
};
