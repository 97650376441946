import type { BaseHit, Hit } from "instantsearch.js";
import { Highlight, HighlightProps } from "react-instantsearch";

export const StyledHighlight = <T extends Hit<BaseHit>>(
  props: HighlightProps<T>,
) => {
  return (
    <Highlight
      {...props}
      classNames={{
        ...props.classNames,
        highlighted: "bg-grey-300 rounded-sm",
      }}
    />
  );
};
