import {
  AggregateClassDto,
  EnrolmentAdjustmentDto,
  EnrolmentDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import {
  dateAdd,
  dateSubtract,
  dateToday,
  toLocalIsoDateString,
} from "./dateUtils";

export interface DetailedEnrolment extends EnrolmentDto {
  enrolmentAdjustments: EnrolmentAdjustmentDto[];
  class: AggregateClassDto;
  season: SeasonDto;
  student: StudentDto;
}

export function getEnrolmentStartDate(detailedEnrolment: {
  season: SeasonDto;
  enrolmentAdjustments: EnrolmentAdjustmentDto[];
}): string {
  return detailedEnrolment.enrolmentAdjustments.reduce<string>(
    (currentStartDate, adj) => {
      if (
        adj.reason === "delayed-start-enrolment" &&
        adj.endAt > currentStartDate
      ) {
        return dateAdd(adj.endAt, 1, "day");
      }
      return currentStartDate;
    },
    detailedEnrolment.season.startAt,
  );
}

export function getEnrolmentEndDate(detailedEnrolment: {
  season: SeasonDto;
  enrolmentAdjustments: EnrolmentAdjustmentDto[];
}): string {
  return detailedEnrolment.enrolmentAdjustments.reduce<string>(
    (currentEndDate, adj) => {
      if (
        adj.reason === "cancelled-enrolment" &&
        adj.startAt < currentEndDate
      ) {
        return dateSubtract(adj.startAt, 1, "day");
      }
      return currentEndDate;
    },
    detailedEnrolment.season.endAt,
  );
}

export type EnrolmentFilter = "current" | "upcoming" | "past";

export const buildEnrolmentFilter = (
  targetFilter: EnrolmentFilter,
): ((detailedEnrolment: DetailedEnrolment) => boolean) => {
  const today = dateToday();
  const filters = {
    current: {
      startAt: (startAt: string) => startAt <= today,
      endAt: (endAt: string) => endAt >= today,
    },
    upcoming: {
      startAt: (startAt: string) => startAt > today,
      endAt: () => true,
    },
    past: {
      startAt: () => true,
      endAt: (endAt: string) => endAt < today,
    },
  };

  return (detailedEnrolment: DetailedEnrolment) => {
    const startAt = getEnrolmentStartDate(detailedEnrolment);
    const endAt = getEnrolmentEndDate(detailedEnrolment);
    const chosenFilters = filters[targetFilter];

    return chosenFilters.startAt(startAt) && chosenFilters.endAt(endAt);
  };
};

export function createEnrolmentStartDate(season: SeasonDto) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const seasonStart = new Date(season.startAt);
  seasonStart.setHours(0, 0, 0, 0);
  const seasonEnd = new Date(season.endAt);
  seasonEnd.setHours(0, 0, 0, 0);

  let returnDate = today;
  if (today <= seasonStart || today >= seasonEnd) {
    returnDate = seasonStart;
  }

  return toLocalIsoDateString(returnDate);
}
