import { StudentDto } from "@justraviga/classmanager-sdk";

import { useStudentCardHeaderMenu } from "shared/components";
import { displayStudentAge, getInitials } from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Router } from "@/routing/router";

export const StudentCard = ({ student }: { student: StudentDto }) => {
  const studentActions = useStudentActions();

  const actionMenu = useStudentCardHeaderMenu(studentActions, student);

  const showStudentDetail = () =>
    Router.push("StudentDetails", { id: student.id });

  return (
    <BaseCard
      leftSlot={
        <div className="flex-none">
          <Avatar
            size="lg"
            src={student.profilePicture ?? undefined}
            name={getInitials(student)}
          />
        </div>
      }
      onClick={showStudentDetail}
      actions={actionMenu}
      title={
        <div className="flex flex-col gap-1">
          <div>
            <h3 className="line-clamp-1 text-body-600 leading-relaxed text-grey-900">
              {student.firstname}
            </h3>
          </div>
          <div>
            <p className="text-label-400 text-grey-600">
              {displayStudentAge(student)}
            </p>
          </div>
        </div>
      }
    />
  );
};
