import { createContext } from "react";

import {
  GlobalSearchStatus,
  SearchClientContextType,
  SearchDialogContextType,
  SearchStatusContextType,
} from "./searchTypes";

export const SearchStatusContext = createContext<SearchStatusContextType>({
  status: GlobalSearchStatus.PENDING,
});

export const SearchDialogContext = createContext<SearchDialogContextType>({
  isSearchOpen: false,
  handleOpenChange: () => {},
});

export const SearchClientContext = createContext<SearchClientContextType>({
  searchClient: null,
});
