import { ReactNode } from "react";

import {
  ActionMenuItem,
  ActionMenuItemItem,
  ActionMenuProps,
  ActionMenuSize,
} from "../interfaces";

export function actionMenuBuilder() {
  return new ActionMenuBuilder();
}

export class ActionMenuBuilder {
  #items: ActionMenuItem[] = [];
  #title: string | undefined = undefined;
  #size: ActionMenuSize = "md";

  items() {
    return this.#items;
  }

  title(title: string) {
    this.#title = title;
    return this;
  }

  size(size: ActionMenuSize) {
    this.#size = size;
    return this;
  }

  separator() {
    this.#items.push({ type: "separator" });
    return this;
  }

  item(item: Omit<ActionMenuItemItem, "type">) {
    this.#items.push({ type: "item", ...item });
    return this;
  }

  groupHeading(text: string) {
    this.#items.push({ type: "group-heading", text });
    return this;
  }

  component(component: ReactNode) {
    this.#items.push({ type: "component", component });
    return this;
  }

  definition(): Omit<ActionMenuProps, "trigger"> {
    return {
      items: this.#items,
      title: this.#title,
      size: this.#size,
    };
  }
}
