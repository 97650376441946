import { ActivityLogList } from "./activityLog/ActivityLogList";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { CollapsibleWidget } from "../../../ui/CollapsibleWidget";

type Props = {
  goToStudent: (studentId: string) => void;
  viewAllActivities: () => void;
};

export const RecentActivityWidget = ({
  goToStudent,
  viewAllActivities,
}: Props) => {
  const { Text, BaseCard } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const { data: activityLogItems, isLoading } = useApi("listActivityLog", {
    pageSize: 5,
  });

  if (isLoading) {
    return (
      <BaseCard
        collapsible
        title={"Recent activity"}
        bodySlot={<Text>Loading...</Text>}
      />
    );
  }

  return (
    <CollapsibleWidget
      title="Recent activity"
      emptyText="Nothing to show here"
      headerAction={{
        text: "View all",
        onClick: viewAllActivities,
      }}
      loading={isLoading}
      empty={!activityLogItems?.data.length}
      content={
        <ActivityLogList
          activityLogItems={activityLogItems?.data ?? []}
          goToStudent={goToStudent}
        />
      }
    />
  );
};
