import { useEmailsDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useEmailActions } from "@/modules/company/messages/useEmailActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const EmailsPage = () => {
  const actions = useEmailActions();

  const { datatable } = useEmailsDatatable({
    Datatable,
    api,
    showCreateForm: actions.showCreateForm,
    showUpdateForm: actions.showUpdateForm,
    deleteOne: actions.deleteOne,
    showPreview: actions.showPreview,
    editAndResend: item => actions.editAndResend(item, actions.showUpdateForm),
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "messaging:manage",
  EmailsPage,
  "Emails",
);

export { PermissionChecked as EmailsPage };
