import { RoomDto } from "@justraviga/classmanager-sdk";

import { colors } from "../../../../../colors";
import { actionMenuBuilder } from "../../../../action-menu/actionMenuBuilder";
import { useRoomActions } from "../../../../actions/useRoomActions";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const RoomCard = ({ room }: { room: RoomDto }) => {
  const { BaseCard } = useGenericComponents();
  const roomActions = useRoomActions();

  const actions = actionMenuBuilder()
    .item({
      text: "Edit",
      icon: "createOutline",
      onClick: () =>
        roomActions.showUpdateForm(room, { locationId: room.locationId }),
    })
    .separator()
    .item({
      text: "Delete",
      icon: "trashOutline",
      onClick: () => roomActions.deleteOne(room),
      variant: "destructive",
    });

  return (
    <BaseCard
      actions={actions}
      title={room.name}
      titleColor={"grey-900"}
      titleSize={16}
      titleWeight={600}
      border={false}
      fillColor={colors.grey[200]}
    />
  );
};
