import { FC, Suspense } from "react";

import { AccountList } from "./accountSelection/AccountList";
import { AccountSkeletonCard } from "./accountSelection/AccountSkeletonCard";
import { useSelectAccountCallback } from "../../../authUtils";
import { useAuthState } from "../../AuthStateProvider";
import { useGenericComponents } from "../../GenericComponentsProvider";

type AccountChangeHandler = () => void;
type OnLogoutHandler = () => void;

export interface AccountSelectionProps {
  onAccountChange: AccountChangeHandler;
  autoSelectSingleAccount?: boolean;
  onLogout: OnLogoutHandler;
  Version: FC;
  CompanyLogo: FC<{ logo: string | null | undefined }>;
  showLoginLink?: boolean;
}

export const AccountSelectionContent = ({
  onAccountChange,
  autoSelectSingleAccount = true,
  CompanyLogo,
}: AccountSelectionProps) => {
  const { Text, View } = useGenericComponents();
  const { account, user } = useAuthState();
  const selectAccount = useSelectAccountCallback(account, onAccountChange);

  return (
    <View className={"flex flex-col items-center space-y-8"}>
      <View>
        <Text className={"text-heading5-600 text-grey-900 font-semibold"}>
          Hello, {user?.firstname} 👋
        </Text>
      </View>

      <View className={"w-full"}>
        <Suspense fallback={<AccountSkeletonCard />}>
          <AccountList
            autoSelectSingleAccount={autoSelectSingleAccount}
            selectAccount={selectAccount}
            CompanyLogo={CompanyLogo}
          />
        </Suspense>
      </View>
    </View>
  );
};
