import { cn, colors } from "shared/lib";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const ActionMenuItemContainer = ({
  item,
}: {
  item: ActionMenuItemProps;
}) => {
  const shouldHaveDestructiveStyle =
    item.destructive &&
    (item.hasDestructiveStyle === undefined || item.hasDestructiveStyle);

  return (
    <section
      className={cn("flex space-x-4", {
        "justify-between": item.justifyContent === "between",
      })}>
      <div
        className={cn("flex justify-start space-x-4", {
          "items-start": item.description,
          "items-center": !item.description,
        })}>
        {item.leftIcon && (
          <Icon
            className={cn({
              "mt-1": !item.description,
            })}
            name={item.leftIcon}
            size={24}
            color={
              shouldHaveDestructiveStyle
                ? colors.red["600"]
                : colors.grey["900"]
            }
          />
        )}
        <div className={"flex flex-col"}>
          <div
            className={cn(`text-md font-normal`, {
              "text-red-600": shouldHaveDestructiveStyle,
              "text-grey-900": !shouldHaveDestructiveStyle,
            })}>
            {item.title}
          </div>
          {item.description && (
            <span className={"pt-1 text-sm font-normal text-grey-600"}>
              {item.description}
            </span>
          )}
        </div>
      </div>
      {item.rightIcon && (
        <div>
          <Icon
            size={24}
            name={item.rightIcon}
            color={
              shouldHaveDestructiveStyle
                ? colors.red["600"]
                : colors.grey["900"]
            }
          />
        </div>
      )}
    </section>
  );
};
