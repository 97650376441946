import { ContactDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeContactCreateRequest,
  makeContactUpdateRequest,
  useContactCreateFormDefinition,
  useContactUpdateFormDefinition,
} from "../formDefinitions/contactForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";

type Model = ContactDto;

export type ContactActions = Omit<
  EntityActions<Model>,
  "archiveMany" | "unarchiveMany" | "archive" | "unarchive"
> & {
  makePrimary: (entity: Model) => void;
};

export const useSharedContactActions = (): ContactActions => {
  const { Button, GenericForm, View } = useGenericComponents();
  const { api, usePlatformEntityActions } = getPlatformFunctions();

  const defaultActions = usePlatformEntityActions<ContactDto>({
    entity: "contact",
  });

  const deleteOne = (entity: Model) =>
    defaultActions.deleteOne(() =>
      api.contacts.deleteContact({ id: entity.id }),
    );

  return {
    deleteOne,

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.contacts.deleteManyContact({
            deleteManyContactRequest: { ids },
          }),
        ids.length,
      ),

    showCreateForm: (familyId: string) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeContactCreateRequest({ api, familyId })}
            defaultValues={{ isAdditional: true }}
            formDefinitionHook={useContactCreateFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <>
            <GenericForm
              apiRequest={makeContactUpdateRequest({ api, id: entity.id })}
              defaultValues={entity}
              formDefinitionHook={useContactUpdateFormDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
            <View className={"-ml-4"}>
              <Button
                text={"Delete Contact"}
                size={"sm"}
                variant={"destructive-tertiary"}
                onClick={() =>
                  deleteOne(entity).then(() => defaultActions.hideForm())
                }
              />
            </View>
          </>
        ),
      }),

    makePrimary: (entity: Model) =>
      api.contacts.markAsPrimaryContact({ id: entity.id }).then(() => {
        showAlert({
          content: "Primary contact updated successfully",
        });
      }),
  };
};
