import { AddressDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeAddressesUpdateRequest,
  useAddressUpdateFormDefinition,
} from "../formDefinitions/addressForm";
import { useGenericComponents } from "../GenericComponentsProvider";

export function useSharedAddressActions(closeForm: () => void) {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { Button, GenericForm, ProtectedOverlay, View } =
    useGenericComponents();

  const defaultActions = usePlatformEntityActions<AddressDto>({
    entity: "address",
  });

  async function deleteForEntity(entityId: string) {
    await api.addresses.deleteAddresses({
      deleteAddressesRequest: {
        addressEntityId: entityId,
      },
    });

    showAlert({
      content: "Address deleted successfully.",
    });
  }

  function showUpdateForm(entityId: string, address: AddressDto) {
    const hasAddress = address && Object.keys(address).length !== 0;

    return defaultActions.showUpdateForm({
      form: (
        <View>
          <GenericForm
            apiRequest={makeAddressesUpdateRequest({ api, entityId })}
            defaultValues={address}
            formDefinitionHook={useAddressUpdateFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
          {hasAddress && (
            <View className={"-ml-4"}>
              <ProtectedOverlay permission="members:manage">
                <Button
                  variant={"destructive-tertiary"}
                  isFullWidth={false}
                  size={"sm"}
                  text={"Delete Address"}
                  onClick={() => deleteForEntity(entityId).then(closeForm)}
                />
              </ProtectedOverlay>
            </View>
          )}
        </View>
      ),
    });
  }

  return {
    showUpdateForm,
  };
}
