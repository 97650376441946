import { PricingScheme } from "@justraviga/classmanager-sdk";

import {
  PricingTiersContentCard,
  usePricingTierDatatable,
} from "shared/components";
import { useBreakpoint } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Banner } from "@/modules/common/ui/Banner";
import { BaseCard, WebBaseCardProps } from "@/modules/common/ui/card/BaseCard";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import {
  CompanyLayout,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { usePricingTierActions } from "@/modules/company/pricingTiers/usePricingTierActions";
import { Router } from "@/routing/router";

export const PricingTiersPage = ({ seasonId }: { seasonId: string }) => {
  const breakpoints = useBreakpoint();
  const seasonActions = useSeasonActions();

  const pricingSchemeActions = usePricingTierActions();

  const { data: season } = useApi("getSeason", { id: seasonId });

  const { listLength, datatable } = usePricingTierDatatable({
    Datatable,
    api,
    seasonId: seasonId,
    showCreateForm:
      season && season.pricingScheme !== PricingScheme.None
        ? () => pricingSchemeActions.showCreateForm(season, listLength + 1)
        : undefined,
    showUpdateForm: item => pricingSchemeActions.showUpdateForm(item, season),
    deleteOne: pricingSchemeActions.deleteOne,
    useApi,
  });

  if (!season) {
    // todo: This should be standardised across all/most pages
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        crumbs={[
          {
            text: "Seasons",
            onClick: () => {
              Router.push("SeasonList");
            },
          },
          {
            text: season?.name ?? "",
            onClick: () => {
              Router.push("SeasonDetails", { id: seasonId });
            },
          },
          {
            text: "Tuition & pricing",
          },
        ]}
        isArchived={false}
        header={<CompanyTitleBarTitle title={"Tuition & pricing"} />}
        archivedEntityName={""}
        spacingVariant={"tight"}
        isDesktop={breakpoints.md}>
        <div className={"space-y-8"}>
          <PricingTiersContentCard<WebBaseCardProps>
            BaseCard={BaseCard}
            season={season}
            seasonActions={seasonActions}
          />
          {season.pricingScheme === PricingScheme.None ? (
            <Banner
              content={
                <Banner.content>
                  When creating or editing a class, you can set the price of
                  each class individually.
                </Banner.content>
              }
              variant={"info"}
            />
          ) : (
            <div>{datatable}</div>
          )}
        </div>
      </DetailPageLayout>
    </CompanyLayout>
  );
};
