import { useLinkProps } from "@swan-io/chicane";

import { useAuthState } from "shared/components";
import {
  cn,
  LinkedSearchResultType,
  SearchResultType,
  useSearchDialog,
} from "shared/lib";

import { getEntityLink } from "@/routing/entityLinks";

interface LinkProps {
  to: string; // The route you're linking to (required)
  className?: string;
  replace?: boolean; // Replace instead of push (defaults to `false`)
  activeClassName?: string;
  activeStyle?: React.CSSProperties;
  onLinkClick: () => void;
  children: React.ReactNode;
}

const SearchLink: React.FC<LinkProps> = ({
  to,
  className,
  activeClassName,
  onLinkClick,
  children,
  ...props
}) => {
  const { active, onClick } = useLinkProps({ href: to });

  return (
    <a
      {...props}
      onClick={evt => {
        onLinkClick();
        onClick(evt);
      }}
      className={cn(className, active && activeClassName)}>
      {children}
    </a>
  );
};

export const LinkedSearchResult = ({
  id,
  children,
}: LinkedSearchResultType) => {
  const { account } = useAuthState();
  const { handleOpenChange } = useSearchDialog();

  const target = account?.role && getEntityLink(account.role, id);

  const onLinkClick = () => {
    handleOpenChange(false);
  };

  return target ? (
    <SearchLink data-testid="search-link" to={target} onLinkClick={onLinkClick}>
      {children}
    </SearchLink>
  ) : (
    <div data-testid="no-link" key={id}>
      {children}
    </div>
  );
};

export const SearchResultContent: React.FC<SearchResultType> = ({
  title,
  description,
  children,
}) => (
  <div
    className={`cursor-pointer px-4 py-2 last:pb-4 hover:bg-grey-100`}
    data-testid="search-result-content">
    <div className={`flex justify-start gap-3`}>
      <div className={`flex flex-col justify-center`}>{children}</div>
      <div
        className={`flex flex-col ${description ? "justify-between gap-y-1" : "justify-center"}`}>
        <div className={`line-clamp-1 text-base font-semibold`}>{title}</div>
        {description && (
          <div className={`line-clamp-1 text-body-400 text-sm  text-grey-600`}>
            {description}
          </div>
        )}
      </div>
    </div>
  </div>
);

export const SearchResult = (props: SearchResultType) => (
  <LinkedSearchResult id={props.id}>
    <SearchResultContent {...props} />
  </LinkedSearchResult>
);
