import { enumLabel } from "@shared/translateUtils";

import { hubspotEnabled } from "@/lib/hubspot/hubspotEnabled";
import { hubspotForms, HubspotRecordTypeID } from "@/lib/hubspot/hubspotTypes";
import { submitToHubspot } from "@/lib/hubspot/submitToHubspot";
import { CompanyRegistrationDtoWithFormData } from "@/modules/auth/companyRegistration/registrationFormDefinition";

export async function submitRegistrationDataToHubSpot(
  data: CompanyRegistrationDtoWithFormData,
) {
  if (!hubspotEnabled()) {
    return;
  }

  const hubspotData = {
    fields: [
      {
        objectTypeId: HubspotRecordTypeID.Contact,
        name: "email",
        value: data.user.email,
      },
      {
        objectTypeId: HubspotRecordTypeID.Contact,
        name: "firstname",
        value: data.user.firstname,
      },
      {
        objectTypeId: HubspotRecordTypeID.Contact,
        name: "lastname",
        value: data.user.lastname,
      },
      {
        objectTypeId: HubspotRecordTypeID.Contact,
        name: "phone", //contact phone
        value: data.account.company?.phone,
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "v3",
        value: "Yes",
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "name", //company name
        value: data.account.company?.name,
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "phone", // company phone
        value: data.account.company?.phone,
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "cm_company_industry",
        value: data.form.industry,
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "number_of_students",
        value: enumLabel("companySize", data.form.size),
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "country",
        value: data.form.countryCode,
      },
      {
        objectTypeId: HubspotRecordTypeID.Company,
        name: "v3_company_id",
        value: data.account.company?.id,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
  };

  return submitToHubspot(hubspotForms.registrationFormGuid, hubspotData);
}
