import { useInstantSearch } from "react-instantsearch-core";

interface SearchTotalCount {
  count: number;
  totalReturnedCount: number;
}

export const useSearchTotalCount = (): SearchTotalCount => {
  const { scopedResults } = useInstantSearch();

  return scopedResults.reduce(
    (acc, scopedResult) => {
      return {
        count: acc.count + scopedResult?.results?.nbHits || 0,
        totalReturnedCount:
          acc.totalReturnedCount + scopedResult?.results?.hits.length || 0,
      };
    },
    {
      count: 0,
      totalReturnedCount: 0,
    },
  );
};
