import {
  FamilyDto,
  InviteFamilyRequest,
  LinkDto,
} from "@justraviga/classmanager-sdk";

import { useSharedFamilyActions } from "shared/components";
import { showAlert } from "shared/lib";

import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { AvatarUploadForm } from "@/modules/common/form/AvatarUploadForm";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { FamilyInviteForm } from "@/modules/company/members/family/forms/FamilyInviteForm";
import { Router } from "@/routing/router";

type Model = FamilyDto;

export const useFamilyActions = () => {
  const defaultActions = usePlatformEntityActions<FamilyDto>({
    entity: "family",
  });

  const sharedActions = useSharedFamilyActions(defaultActions, family =>
    Router.push("FamilyDetails", { id: family.id }),
  );

  const { openSheet: showForm, closeSheet: hideForm } = useSheet();

  const onInviteSuccess = () => {
    showAlert({
      content: `Invitation sent successfully`,
    });
    hideForm();
  };

  return {
    ...sharedActions,

    showInviteForm(entity: Model, args: InviteFamilyRequest): Promise<LinkDto> {
      return new Promise(resolve => {
        showForm({
          title: "Invite Family",
          content: (
            <FamilyInviteForm
              familyId={entity.id}
              defaultValues={args}
              onSuccess={linkData => {
                onInviteSuccess();
                resolve(linkData);
              }}
            />
          ),
          footer: <UpdateFooter saveButtonText={"Send invitation"} />,
        });
      });
    },

    showAvatarUploadSheet: (entity: Model) => {
      showForm({
        title: "Add Profile Image",
        content: (
          <AvatarUploadForm
            dto={{
              firstname: entity.name,
              lastname: undefined,
              ...entity,
            }}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
  };
};
