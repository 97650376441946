import {
  AgeCutOffType,
  CompanyEnrolmentSettingsDto,
  PricingScheme,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { calculateWeeksBetweenDates, dateAdd, dateToday } from "./dateUtils";
import { formatDate } from "./intlFormatter";

export const getSeasonTimeFrame = (
  season: SeasonDto,
): { dateRange: string; weeksCount: number } => {
  const dateRange = `${formatDate(season.startAt, "dayMonthYear")} - ${formatDate(season.endAt, "dayMonthYear")}`;
  const weeksCount = calculateWeeksBetweenDates(
    new Date(season.startAt),
    new Date(season.endAt),
  );

  return { dateRange, weeksCount };
};

// selected item should come from current, then upcoming then past
export const seasonPriority = (season: SeasonDto): number => {
  const status = getSeasonStatus(season);

  return status === "upcoming" ? 1 : status === "past" ? 2 : 0;
};

export const loadingSeason: SeasonDto = {
  companyId: "",
  endAt: "",
  hasMaxRegistrationFee: false,
  name: "Loading seasons",
  pricingScheme: PricingScheme.None,
  registrationFee: 0,
  registrationOpen: false,
  startAt: "",
  id: "NA",
};

export const getSeasonCutOffDate = (
  enrolmentSettings: Pick<
    CompanyEnrolmentSettingsDto,
    "ageCutOffType" | "ageCutOffDays" | "ageCutOffDate"
  >,
  startAt: string,
) => {
  switch (enrolmentSettings.ageCutOffType) {
    case AgeCutOffType.DaysAfterStart:
      return dateAdd(startAt, enrolmentSettings.ageCutOffDays!, "day");
    case AgeCutOffType.OnSpecificDate: {
      const [year, month, day] = startAt.split("-");
      const seasonStartYear = Number.parseInt(year, 10);
      // We need to be taking the date before the season starts, therefore, If
      // the age cutoff date is after the season starts, we need use the previous year
      return `${month}-${day}` >= enrolmentSettings.ageCutOffDate!
        ? `${seasonStartYear}-${enrolmentSettings.ageCutOffDate}`
        : `${seasonStartYear - 1}-${enrolmentSettings.ageCutOffDate}`;
    }
    case AgeCutOffType.FirstDayOfSeason:
    default:
      return startAt;
  }
};

export type SeasonStatus = "current" | "upcoming" | "past";

export const getSeasonStatus = (
  season: Pick<SeasonDto, "startAt" | "endAt">,
): SeasonStatus => {
  const today = dateToday();

  return season.startAt > today
    ? "upcoming"
    : season.endAt < today
      ? "past"
      : "current";
};
