import {
  Breadcrumb,
  DiscountApplicationInfoCard,
  useCurrencySymbol,
} from "shared/components";
import { getDiscountSchemeDetails, IconName } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard, WebBaseCardProps } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { DiscountSchemeTiersDatatable } from "@/modules/company/billing/discountSchemes/tiers/DiscountSchemeTiersDatatable";
import { useDiscountSchemeActions } from "@/modules/company/billing/discountSchemes/useDiscountSchemeActions";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

import { replaceCurrencySymbol } from "../../../../../shared/src/discountScheme";

export const DiscountSchemeDetails = ({ id }: { id: string }) => {
  const breakpoints = useBreakpoint();
  const { data: discountScheme } = useApi("getDiscountScheme", { id });
  const { deleteOne, updateStatus } = useDiscountSchemeActions();

  //@TODO find way to handle undefined discountScheme properly
  if (discountScheme === undefined) {
    return <div></div>;
  }

  const discountSchemeDetails = getDiscountSchemeDetails(discountScheme);

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Delete",
      onClick: () =>
        deleteOne(discountScheme).then(() => Router.push("DiscountSchemeList")),
      destructive: true,
    },
  ];

  if (!breakpoints.md) {
    menuItems.push({
      title: discountSchemeDetails.actionTitle,
      onClick: () => updateStatus(discountScheme.id, !discountScheme.enabled),
    });
  }

  const crumbs: Breadcrumb[] = [
    {
      text: "Discount schemes",
      onClick() {
        Router.push("DiscountSchemeList");
      },
    },
    { text: discountSchemeDetails.title },
  ];

  const DiscountDetails = ({
    title,
    description,
    icon,
  }: {
    title: string;
    description: string;
    icon: IconName;
  }) => {
    return (
      <div className={"flex flex-row gap-3"}>
        <Icon name={icon} size={24} />
        <div className={"flex flex-col gap-1"}>
          <p className={"text-body-400 text-grey-900"}>{title}</p>
          <p className={"text-body-400 text-sm text-grey-600"}>{description}</p>
        </div>
      </div>
    );
  };

  const DiscountDetailsSection = () => {
    const currencySymbol = useCurrencySymbol();

    return (
      <div className={"flex flex-col space-y-4"}>
        <div className={"flex items-center justify-between"}>
          <p className={"text-body-600 text-grey-900"}>Discount details</p>
          <div className={"md:hidden"}>
            <Chip
              label={discountSchemeDetails.chipLabel}
              variant={discountSchemeDetails.chipVariant}
            />
          </div>
        </div>
        <DiscountDetails
          title={replaceCurrencySymbol(
            discountSchemeDetails.typeDetails.title,
            currencySymbol,
          )}
          description={replaceCurrencySymbol(
            discountSchemeDetails.typeDetails.description,
            currencySymbol,
          )}
          icon={discountSchemeDetails.typeDetails.icon}
        />
        <DiscountDetails
          title={replaceCurrencySymbol(
            discountSchemeDetails.applicationDetails.title,
            currencySymbol,
          )}
          description={replaceCurrencySymbol(
            discountSchemeDetails.applicationDetails.description,
            currencySymbol,
          )}
          icon={discountSchemeDetails.applicationDetails.icon}
        />
        <DiscountApplicationInfoCard<WebBaseCardProps>
          BaseCard={BaseCard}
          props={{ gap: 1 }}
        />
      </div>
    );
  };

  const DesktopHeader = () => {
    return (
      <div className={"hidden items-center space-x-4 md:flex"}>
        <h5 className={"text-heading5-600 text-xl"}>
          {discountSchemeDetails.title}
        </h5>
        <Chip
          label={discountSchemeDetails.chipLabel}
          variant={discountSchemeDetails.chipVariant}
        />
      </div>
    );
  };

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        title={<DesktopHeader />}
        mobileTitle={discountSchemeDetails.title}
        isDesktop={breakpoints.md}
        archivedEntityName={"Discount scheme"}
        additionalActions={
          <Button
            variant={"secondary"}
            size={"sm"}
            onClick={() =>
              updateStatus(discountScheme.id, !discountScheme.enabled)
            }
            text={discountSchemeDetails.actionTitle}
          />
        }
        crumbs={crumbs}
        actions={menuItems}
        spacingVariant={"none"}>
        <DiscountDetailsSection />
        {discountScheme && (
          <DiscountSchemeTiersDatatable discountScheme={discountScheme} />
        )}
      </DetailPageLayout>
    </CompanyLayout>
  );
};
