import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ActionMenuItemItem, ProtectedOverlayComponent } from "../interfaces";
import { usePermission } from "../permission/usePermission";

export const ActionMenuItemContainer = ({
  item,
  ProtectedOverlay,
  onActionPress,
  ClickableWrapper,
}: {
  item: ActionMenuItemItem;
  ProtectedOverlay: ProtectedOverlayComponent;
  onActionPress: () => void;
  ClickableWrapper: React.FC<{
    onClick: (e: { stopPropagation: () => void }) => void;
    disabled?: boolean;
    className: string;
    // if you want to typesafe this be my guest :)
    style?: unknown[];
    children: React.ReactNode;
  }>;
}) => {
  const { hasPermission } = usePermission();
  const { Icon, Text, View } = useGenericComponents();
  // item will be assumed to be "not disabled" if disabled is not set
  const isDisabled = item.disabled === true;
  return (
    <View className={"relative"}>
      <ClickableWrapper
        onClick={e => {
          e.stopPropagation();
          if (
            isDisabled ||
            (item.permission && !hasPermission(item.permission))
          ) {
            return;
          }

          item.onClick();
          onActionPress();
        }}
        disabled={isDisabled}
        className={"px-2 py-3 cursor-pointer"}>
        <ProtectedOverlay
          permission={item.permission}
          protector={item.protector}>
          <View className={"flex flex-row justify-start gap-x-3 items-start"}>
            <View>
              <Icon
                name={item.icon}
                size={24}
                color={
                  item.variant === "destructive"
                    ? colors.red["600"]
                    : colors.grey["900"]
                }
              />
            </View>
            <View className={"flex flex-col gap-y-1 flex-1"}>
              <View className={"pt-0.5"}>
                <Text
                  className={cn("text-body-400", {
                    "text-red-600": item.variant === "destructive",
                    "text-grey-900": item.variant !== "destructive",
                  })}
                  truncate>
                  {item.text}
                </Text>
              </View>
              {item.description && (
                <View>
                  <Text className={"text-sm font-normal text-grey-600"}>
                    {item.description}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </ProtectedOverlay>
      </ClickableWrapper>
      {isDisabled ? (
        <View
          className={
            "absolute top-0 left-0 right-0 bottom-0 cursor-not-allowed bg-overlay-white-heavy"
          }
        />
      ) : null}
    </View>
  );
};
