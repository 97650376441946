import { RefObject, useRef, useState } from "react";

import { cn, BaseInputProps as SharedBaseInputProps } from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";

export type BaseInputValue = string | null;

export interface BaseInputProps extends SharedBaseInputProps {
  hidden?: boolean;
  name?: string;
}

export const BaseInput = ({
  append,
  borderRadius,
  className,
  description,
  disabled,
  error,
  height = "md",
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  prefix,
  prepend,
  required,
  selectTextOnFocus = false,
  type = "text",
  hidden = false,
  value,
  numberOfLines = 1,
  keepZeroValue = false,
}: BaseInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  // Keep a copy of value as internal state for those situations (like docs pages), where the inputs aren't controlled
  const [internalValue, setInternalValue] = useState<BaseInputValue>(
    value ?? "",
  );

  const format = (
    value: string | number | null | undefined,
  ): BaseInputValue => {
    if (Array.isArray(value)) {
      return value.join("\n");
    }

    return value === null ||
      value === undefined ||
      String(value).trim().length === 0
      ? null
      : String(value);
  };

  if (hidden) {
    return null;
  }

  return (
    <Label text={label} required={required}>
      <div
        className={cn(
          ["flex items-center justify-between gap-x-3"],
          ["bg-background w-full border px-3 py-2 text-body-400"],
          ["placeholder:text-muted-foreground cursor-text"],
          ["disabled:opacity-50"],
          ["placeholder:text-grey-50"],
          className,
          numberOfLines === 1
            ? {
                "h-6": height === "xs",
                "h-8": height === "sm",
                "h-10": height === "md",
              }
            : null,
          borderRadius === "rounded-full" ? "rounded-full" : "rounded",
          {
            "border-grey-300": !error && !isFocused,
            "border-red-600": error,
            "border-grey-900": isFocused,
            "bg-red-100": error,
            "cursor-not-allowed": disabled,
          },
        )}>
        {prepend && (
          <div className="flex items-center justify-center">{prepend}</div>
        )}
        <div className="flex grow items-center justify-between gap-x-2">
          {prefix && (
            <div className="text-body-400 text-grey-600">{prefix}</div>
          )}
          {numberOfLines > 1 ? (
            <textarea
              ref={inputRef as RefObject<HTMLTextAreaElement>}
              name={name}
              className={cn(
                "w-full bg-transparent text-body-400 text-grey-900 " +
                  "focus:outline-none disabled:opacity-50 " +
                  "placeholder:text-grey-500 ",
                {
                  "cursor-not-allowed": disabled,
                },
              )}
              placeholder={placeholder}
              rows={numberOfLines}
              onChange={e => {
                const newValue = format(e.target.value);
                if (onChange) {
                  onChange(newValue);
                } else {
                  // Handle uncontrolled component
                  setInternalValue(newValue);
                }
              }}
              onFocus={() => {
                setIsFocused(true);
                if (selectTextOnFocus) {
                  inputRef.current?.select();
                }
              }}
              onBlur={() => {
                setIsFocused(false);
                if (onBlur) {
                  onBlur();
                }
              }}
              value={format(onChange ? value : internalValue) ?? ""}></textarea>
          ) : (
            <input
              ref={inputRef as RefObject<HTMLInputElement>}
              name={name}
              type={type === "password" ? type : "text"}
              inputMode={type === "password" ? "text" : type}
              placeholder={placeholder}
              onChange={e => {
                const newValue = format(e.target.value);
                if (onChange) {
                  if (newValue === null) {
                    onChange(keepZeroValue ? "0" : null);
                  } else {
                    onChange(newValue);
                  }
                } else {
                  // Handle uncontrolled component
                  setInternalValue(newValue);
                }
              }}
              onFocus={() => {
                setIsFocused(true);
                if (selectTextOnFocus) {
                  inputRef.current?.select();
                }
              }}
              onBlur={() => {
                setIsFocused(false);
                if (onBlur) {
                  onBlur();
                }
              }}
              className={cn(
                "h-6 w-full bg-transparent text-body-400 text-grey-900 " +
                  "focus:outline-none disabled:opacity-50 " +
                  "placeholder:text-grey-500 ",
                {
                  "cursor-not-allowed": disabled,
                },
              )}
              disabled={disabled}
              value={format(onChange ? value : internalValue) ?? ""}
            />
          )}
        </div>
        {append}
      </div>
      <InputDescription description={description} />
    </Label>
  );
};
