import { ListStudent200Response } from "@justraviga/classmanager-sdk";

import { ContentPlaceholder } from "shared/components";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CardSection } from "@/modules/common/cardContainer/CardSection";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

import { StudentCard } from "./detail/StudentCard";

interface FamilyStudentGridProps {
  students: ListStudent200Response;
  createStudentWithFamily: () => void;
}

export const FamilyStudentGrid = ({
  students,
  createStudentWithFamily,
}: FamilyStudentGridProps) => {
  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Add student",
    onClick: () => createStudentWithFamily(),
    permission: "members:manage",
  };

  return (
    <CardSection
      title="Students"
      primaryAction={primaryAction}
      isEmpty={students.pagination.count === 0}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon="helpCircleOutline"
          title="No students yet!"
          action={
            <ProtectedOverlay permission="members:manage">
              <Button
                variant={"brand"}
                onClick={() => createStudentWithFamily()}
                text={"Add student"}
              />
            </ProtectedOverlay>
          }
        />
      </CardContainerPlaceholder>
      <CardContainerContent>
        {students.data.map((student, index) => {
          return <StudentCard key={index} student={student} />;
        })}
      </CardContainerContent>
    </CardSection>
  );
};
