import { WeekDay } from "@justraviga/classmanager-sdk";

import { range } from "./arrayUtils";
import {
  assertValidDateFormat,
  formatDate,
  formatDateTime,
  IntlFormatter,
} from "./intlFormatter";
import { dayjs, OpUnitType, QUnitType } from "./lib/dayjs";

export const oneSecondMs = 1000;
export const oneMinuteMs = 60 * oneSecondMs;
export const fiveMinutesMs = 5 * oneMinuteMs;
export const oneHourMs = 60 * oneMinuteMs;
export const oneDayMs = 24 * oneHourMs;

export type DateUnit = "day" | "week" | "month" | "year";

/**
 * Returns the **Company's** current date in the format YYYY-MM-DD.
 *
 * WARNING: Impure function; relies on the current company/account being set globally.
 */
export const dateToday = () => {
  return formatDateTime(new Date(), "isoDate");
};

export const dateAdd = (date: string, amount: number, unit: DateUnit) => {
  return dayjs(date).add(amount, unit).format("YYYY-MM-DD");
};

export const dateSubtract = (date: string, amount: number, unit: DateUnit) => {
  return dayjs(date).subtract(amount, unit).format("YYYY-MM-DD");
};

/**
 * Convert a date string to a month string, in ISO8601 format
 */
export const dateToMonth = (date: string) => {
  return date.substring(0, 7);
};

/**
 * Converts a JS Date to its corresponding ISO8601 date string in UTC timezone.
 * ** BE CAREFUL! ** This function is only suitable for a small number of scenarios where you are happy to use the
 * UTC timezone equivalent of a date, rather than a company's timezone.
 */
export const toUtcIsoDateString = (date: Date) => {
  return date.toISOString().split("T")[0];
};

/** Converts a date string to a Date object in the user's browser timezone */
export const toLocalDateObject = (date: string) => {
  assertValidDateFormat(date);

  return new Date(date + "T00:00:00");
};

/**
 * Converts a JS Date to its corresponding ISO8601 date string ** in the user's browser timezone **.
 * ** BE CAREFUL! ** This function is only suitable for a small number of scenarios where you are happy to use the
 * browser's timezone equivalent of a date, rather than a company's timezone.
 */
export const toLocalIsoDateString = (date: Date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const dateIsBetween = (date: Date, startDate: Date, endDate: Date) => {
  return dayjs(date).isBetween(startDate, endDate, "day", "[]");
};

export const interpretTwoDigitYear = (year: number) => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const cutoffYear = thisYear - 1900 - 95; // Adjusted threshold based on the current year

  if (year < 100) {
    // If year is less than cutoffYear, prefix with 20, else prefix with 19
    return year < cutoffYear ? year + 2000 : year + 1900;
  }
  return year;
};

const getAgeInFn = (format: QUnitType | OpUnitType) => {
  return (startDate: string, onDate?: string) => {
    const wrappedStartDate = dayjs(startDate);
    if (!wrappedStartDate.isValid()) {
      return NaN;
    }

    return dayjs(onDate ?? new Date()).diff(wrappedStartDate, format);
  };
};

export const getAgeInMonths = (startDate: string, onDate?: string) => {
  return getAgeInFn("M")(startDate, onDate);
};

export const getAgeInYears = (startDate: string, onDate?: string) => {
  return getAgeInFn("y")(startDate, onDate);
};

export const formatWeekDay = (locale: string | undefined, date: Date) => {
  return date.toLocaleDateString(locale, { weekday: "short" }).substring(0, 2);
};

export function convertAgeToBirthdate(
  age: number,
  onDate: Date = new Date(),
): string | null {
  const result = dayjs(onDate).subtract(age, "year").toDate();
  return formatDateTime(result, "isoDate");
}

export function presentDateRange(startDate: string, endDate: string): string {
  const start = formatDate(startDate, "dayMonthYear");
  const end = formatDate(endDate, "dayMonthYear");
  return startDate === endDate ? start : `${start} - ${end}`;
}

export function calculateWeeksBetweenDates(
  startDate: Date,
  endDate: Date,
): number {
  // Convert both dates to milliseconds
  const start = startDate.getTime();
  const end = endDate.getTime();

  // If equal, return 0

  if (start === end) {
    return 0;
  }

  // Calculate the difference in milliseconds
  const diff = end - start;

  // Convert back to weeks and return
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
}

export function convert24to12Hour(hour: number): number {
  return hour % 12 || 12;
}

export function monthsToYears(months: number) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  return { years, months: remainingMonths };
}

export const defaultDaysInMonths = [
  31, // January
  28, // February
  31, // March
  30, // April
  31, // May
  30, // June
  31, // July
  31, // August
  30, // September
  31, // October
  30, // November
  31, // December
];

export const dayMonthOrderForLocale = (
  locale: string,
): "day-month" | "month-day" => {
  const dateFormatter = new IntlFormatter({
    timezone: "UTC",
    locale,
    use12HourClock: false,
    currency: "USD", // This doesn't really matter for this function
  });

  // Figure out which way around this locale does month and day
  const fakeDate = "2000-11-22";
  const formatted = dateFormatter.formatDate(fakeDate, "dayMonthYear");
  // Remove the year to make it easier to compare
  const withoutYear = formatted.replace(/2000/, "").trim();
  // Month is text, so use the day to detect the order
  const dayIsFirst = withoutYear.startsWith("22");

  return dayIsFirst ? "day-month" : "month-day";
};

// Function accepts a start time and an interval in minutes and returns the end time as a string like "HH:mm"
export const calculateEndTime = (
  startTime: string,
  interval: number,
): string => {
  return dayjs()
    .startOf("day")
    .hour(Number(startTime.split(":")[0]))
    .minute(Number(startTime.split(":")[1]))
    .add(interval, "minute")
    .format("HH:mm");
};

// Functions to convert time strings to URL params and back
export const timeToUrlParam = (time: string) => time.replace(":", "-");
export const urlParamToTime = (param: string) => param.replace("-", ":");

/**
 * Generates an array of date strings based on the given start date and number of days
 * @param startDate
 * @param numberOfDays (inclusive of start date)
 */
export const generateDateRange = (
  startDate: string,
  numberOfDays: number,
): Array<string> =>
  range(numberOfDays).map((_, index) => dateAdd(startDate, index, "day"));

// Function to generate an array of years based on the given number of years
export const generateYearRange = (
  startOffset: number,
  numberOfYears: number,
): Array<number> => {
  return range(numberOfYears).map(
    (_, index) => dayjs().year() - startOffset - index,
  );
};

//Generate list of month names
export const generateMonthNames = (): Array<string> =>
  range(12).map(month => formatDateTime(new Date(2020, month, 1), "monthLong"));

export const isGreaterOrEqualThanToday = (dateString: string): boolean => {
  const inputDate = dayjs(dateString);
  const today = dayjs().startOf("day");

  return inputDate.isAfter(today) || inputDate.isSame(today);
};

export const isLessThanToday = (dateString: string): boolean => {
  const inputDate = dayjs(dateString);
  const today = dayjs().startOf("day");

  return inputDate.isBefore(today);
};

export const getDifferenceBetweenTwoDates = (
  firstDate: string,
  secondDate: string,
) => {
  const first = dayjs(firstDate);
  const second = dayjs(secondDate);

  return {
    years: second.diff(first, "years"),
    months: second.diff(first, "month"),
    days: second.diff(first, "days"),
    hours: second.diff(first, "hours"),
    minutes: second.diff(first, "minutes"),
    seconds: second.diff(first, "seconds"),
    milliseconds: second.diff(first, "milliseconds"),
  };
};

export const beginningOfToday = dayjs().startOf("day");

export const formatWeekDayType = (weekDay: WeekDay): string => {
  return weekDay.charAt(0).toUpperCase() + weekDay.slice(1, 3);
};
