import {
  checkProtection,
  lockedFeature,
  PermissionLayoutProps,
  unlockedFeature,
} from "shared/components";
import { cn, useAlert, useBreakpoint, usePermission } from "shared/lib";

import { InteractionOverlay } from "@/modules/common/overlays/InteractionOverlay";

const permissionMessage =
  "You do not have the necessary permissions for this. Contact the owner of your organization.";

export const ProtectedOverlay = ({
  protector = checkProtection([]),
  permission,
  children,
  shape,
}: PermissionLayoutProps) => {
  const { hasPermission } = usePermission();
  const { showAlert } = useAlert();
  const { md: isDesktop } = useBreakpoint();
  const permissionFeature =
    permission === undefined || hasPermission(permission)
      ? unlockedFeature()
      : lockedFeature(permissionMessage);
  const permissionProtector = checkProtection([permissionFeature]);
  const combinedProtector = protector.extend(permissionProtector);

  if (!combinedProtector.isProtected()) {
    return children;
  }

  if (isDesktop) {
    return (
      <InteractionOverlay
        enabled={combinedProtector.isProtected()}
        message={combinedProtector.message()}
        shape={shape}>
        {children}
      </InteractionOverlay>
    );
  }

  return (
    <div
      className="relative flex w-full"
      onClick={event => event.stopPropagation()}>
      {children}
      <div
        data-testid="protected-overlay-overlay"
        className={cn("absolute inset-0 bg-overlay-white-heavy", {
          "rounded-full": shape === "rounded-full",
          rounded: shape === "rounded",
        })}
        onClick={() =>
          showAlert({
            content: combinedProtector.message(),
            variant: "important",
          })
        }></div>
    </div>
  );
};
