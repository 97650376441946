import { convert24to12Hour, TimePickerProps } from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";
import { Select } from "@/modules/common/form/select/Select";

type HourValue = number | "HH";
type MinuteValue = number | "MM";
type AmPm = "am" | "pm";
type ClockType = "12-hour" | "24-hour";

interface TimeValue {
  hour: HourValue;
  minute: MinuteValue;
  amPm: AmPm;
}

function validateTimeValue(value: string | null) {
  if (value !== null && !value.match(/^([01]\d|2[0-3]|HH):([0-5]\d|MM)$/)) {
    console.error(
      `Invalid value for Timepicker [${value}]. Ensure only valid hours:minutes are passed`,
    );
    return false;
  }

  return true;
}

function parseHour(hourValue: string, clockType: ClockType) {
  if (hourValue === "HH") {
    return "HH";
  }

  return clockType === "12-hour"
    ? convert24to12Hour(Number(hourValue))
    : Number(hourValue);
}

function parseMinute(minuteValue: string) {
  if (minuteValue === "MM") {
    return "MM";
  }

  return Number(minuteValue);
}

function parseAmPm(hourValue: string) {
  if (hourValue === "HH") {
    return "am";
  }

  return Number(hourValue) >= 12 ? "pm" : "am";
}

function parseTimeValues(
  value: string | null,
  clockType: ClockType,
): TimeValue {
  const parts = value ? value.split(":") : ["HH", "MM"];

  return {
    hour: parseHour(parts[0], clockType),
    minute: parseMinute(parts[1]),
    amPm: parseAmPm(parts[0]),
  };
}

function marshallHourValue(hour: HourValue, amPm: AmPm, clockType: ClockType) {
  if (hour === "HH") {
    return "HH";
  }

  if (clockType === "24-hour") {
    return hour;
  }

  if (hour === 12) {
    return amPm === "am" ? 0 : 12;
  }

  return amPm === "pm" ? hour + 12 : hour;
}

function stringifyTime(value: HourValue | MinuteValue) {
  return value.toString().padStart(2, "0");
}

function marshallTimeValue(
  timeValue: TimeValue,
  clockType: "12-hour" | "24-hour",
) {
  const { hour, minute, amPm } = timeValue;
  const outputHour = stringifyTime(marshallHourValue(hour, amPm, clockType));
  const outputMinute = stringifyTime(minute);

  return `${outputHour}:${outputMinute}`;
}

interface SelectProps {
  error: boolean;
  name?: string;
  onBlur?: () => void;
}

interface HourSelectProps extends SelectProps {
  hour: HourValue;
  setHour: (hour: HourValue) => void;
  clockType: "12-hour" | "24-hour";
}

const HourSelect = ({
  hour,
  setHour,
  error,
  name,
  onBlur,
  clockType,
}: HourSelectProps) => {
  const hours = Array.from(
    { length: clockType === "12-hour" ? 12 : 23 },
    (_, i) => i + 1,
  );

  return (
    <Select
      error={error}
      name={name ? `${name}-hour` : undefined}
      placeholder="HH"
      data={hours.map(h => ({
        value: h,
        label: h.toString(),
      }))}
      value={hour === "HH" ? undefined : hour}
      onSelect={v => setHour(v === null ? "HH" : Number(v))}
      onBlur={onBlur}
    />
  );
};

interface MinuteSelectProps extends SelectProps {
  minute: MinuteValue;
  setMinute: (minute: MinuteValue) => void;
  minutesStep: number;
}

const MinuteSelect = ({
  minute,
  setMinute,
  error,
  name,
  onBlur,
  minutesStep,
}: MinuteSelectProps) => {
  const minutes = Array.from(
    { length: 60 / minutesStep },
    (_, i) => i * minutesStep,
  );

  return (
    <Select
      error={error}
      name={name ? `${name}-minute` : undefined}
      placeholder="MM"
      data={minutes.map(m => ({
        value: m,
        label: m.toString().padStart(2, "0"),
      }))}
      value={minute === "MM" ? undefined : minute}
      onSelect={v => setMinute(v === null ? "MM" : Number(v))}
      onBlur={onBlur}
    />
  );
};

interface AmPmSelectProps extends SelectProps {
  amPm: AmPm;
  setAmPm: (amPm: AmPm) => void;
}

const AmPmSelect = ({
  amPm,
  setAmPm,
  error,
  name,
  onBlur,
}: AmPmSelectProps) => {
  return (
    <Select
      error={error}
      name={name ? `${name}-ampm` : undefined}
      placeholder="Min"
      required={true}
      data={[
        { value: "am", label: "AM" },
        { value: "pm", label: "PM" },
      ]}
      value={amPm}
      onSelect={v => setAmPm(v ? (String(v) as AmPm) : "am")}
      clearable={false}
      onBlur={onBlur}
    />
  );
};

export const TimePicker = ({
  description,
  error = false,
  label,
  minutesStep = 5,
  name,
  onBlur,
  onChange,
  required = false,
  clockType,
  value = null,
}: TimePickerProps) => {
  const isValidTime = validateTimeValue(value);
  const parsedTimeValues = parseTimeValues(
    isValidTime ? value : null,
    clockType,
  );

  const updateTime = (newTime: TimeValue) => {
    const outputValue = marshallTimeValue(newTime, clockType);
    onChange?.(outputValue);
  };

  const setHour = (hour: HourValue) => {
    updateTime({
      ...parsedTimeValues,
      hour,
    });
  };
  const setMinute = (minute: MinuteValue) => {
    updateTime({
      ...parsedTimeValues,
      minute,
    });
  };
  const setAmPm = (amPm: AmPm) => {
    updateTime({
      ...parsedTimeValues,
      amPm,
    });
  };

  const { hour, minute, amPm } = parsedTimeValues;
  const hourError = error || (hour === null && minute !== null);
  const minuteError = error || (minute === null && hour !== null);

  return (
    <Label text={label} required={required} useNativeLabel={false}>
      <div className="flex flex-row gap-x-5">
        <div className="flex-1">
          <HourSelect
            hour={hour}
            setHour={setHour}
            error={hourError}
            name={name}
            onBlur={onBlur}
            clockType={clockType}
          />
        </div>
        <div className="flex-1">
          <MinuteSelect
            minute={minute}
            setMinute={setMinute}
            error={minuteError}
            name={name}
            onBlur={onBlur}
            minutesStep={minutesStep}
          />
        </div>
        {clockType === "12-hour" && (
          <div className="flex-1">
            <AmPmSelect
              amPm={amPm}
              setAmPm={setAmPm}
              error={error}
              name={name}
              onBlur={onBlur}
            />
          </div>
        )}
      </div>
      <InputDescription description={description} />
    </Label>
  );
};
