import { ComponentType } from "react";

import { FamilyDto } from "@justraviga/classmanager-sdk";

import { TransactionSummaryCell } from "./TransactionSummaryCell";
import { useFamilyTransactionsData } from "../../data/useFamilyTransactionsData";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { getBalanceColor } from "../../transactions/transactionStyles";
import { ShowPreviewType } from "../actions/useSharedTransactionActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { PermissionLayout } from "../permission/PermissionLayout";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";

interface RecentTransactionsCardProps {
  family: FamilyDto;
  LoadingPlaceholder: ComponentType;
  showPreview: ShowPreviewType;
  goToAccountStatement: (familyId: string) => void;
}

export const RecentTransactionsCard = ({
  family,
  LoadingPlaceholder,
  showPreview,
  goToAccountStatement,
}: RecentTransactionsCardProps) => {
  const { BaseCard } = useGenericComponents();

  return (
    <BaseCard
      title="Recent transactions"
      collapsible
      headerAction={{
        text: "View all",
        onClick: () => goToAccountStatement(family.id),
      }}
      permission="financial:view"
      bodySlot={
        <PermissionLayout permission="financial:view">
          <RecentTransactionsCardBody
            family={family}
            LoadingPlaceholder={LoadingPlaceholder}
            showPreview={showPreview}
          />
        </PermissionLayout>
      }
    />
  );
};

const RecentTransactionsCardBody = ({
  family,
  LoadingPlaceholder,
  showPreview,
}: Pick<
  RecentTransactionsCardProps,
  "family" | "LoadingPlaceholder" | "showPreview"
>) => {
  const { Text, View } = useGenericComponents();
  const { isLoading, balance, balances, transactions } =
    useFamilyTransactionsData(family.id);

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  return transactions.length ? (
    <View>
      <View className="flex flex-row items-baseline gap-x-1 pb-2">
        <Text
          className="text-heading4-600 font-semibold"
          style={{
            color: getBalanceColor(balance),
          }}>
          {formatMoneyFromInteger(balance)}
        </Text>
        <Text className="text-body-400 text-grey-600">Account balance</Text>
      </View>
      <View>
        {transactions.slice(0, 5).map(transaction => (
          <View className="mt-2" key={transaction.transaction.id}>
            <TransactionSummaryCell
              key={transaction.transaction.id}
              balance={balances[transaction.transaction.id]}
              transaction={transaction}
              showPreview={showPreview}
              showFamilyName={false}
            />
          </View>
        ))}
      </View>
    </View>
  ) : (
    <ContentPlaceholder
      icon="helpCircleOutline"
      title="Nothing to show here"
      description="Nothing has been billed on this account yet."
    />
  );
};
