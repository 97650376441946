import { CardTypeIcon } from "./CardTypeIcon";
import { useGenericComponents } from "../GenericComponentsProvider";
import { CardType } from "../interfaces";

interface SelectItemCardIconProps {
  cardType: CardType;
}

export const SelectItemCardIcon = ({ cardType }: SelectItemCardIconProps) => {
  const { View } = useGenericComponents();

  return (
    <View className="mr-3">
      <CardTypeIcon cardType={cardType} />
    </View>
  );
};
