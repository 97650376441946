import type { Hit } from "instantsearch.js";
import { match } from "ts-pattern";

import {
  findHighlightedAttributes,
  getInitials,
  SearchCollection,
  StaffDocument,
} from "shared/lib";

import { SearchResult } from "@/modules/common/search/searchResults/SearchResult";
import { StyledHighlight } from "@/modules/common/search/StyledHighlight";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";

interface StaffSearchResultProps {
  hit: Hit<StaffDocument>;
}

const StaffDescription = ({ hit: staff }: StaffSearchResultProps) => {
  const highlightedAttributes = findHighlightedAttributes<StaffDocument>(
    ["email", "phone", "jobTitle"],
    staff,
  );

  return match(highlightedAttributes)
    .with({ email: true }, () => (
      <StyledHighlight attribute="email" hit={staff} />
    ))
    .with({ phone: true }, () => (
      <StyledHighlight attribute="phone" hit={staff} />
    ))
    .with({ jobTitle: true }, () => (
      <StyledHighlight attribute="jobTitle" hit={staff} />
    ))
    .otherwise(() => <StyledHighlight attribute="email" hit={staff} />);
};

export const StaffSearchResult = ({ hit: staff }: StaffSearchResultProps) => {
  return (
    <SearchResult
      type={SearchCollection.staff}
      title={
        <>
          <StyledHighlight attribute="firstname" hit={staff} />{" "}
          <StyledHighlight attribute="lastname" hit={staff} />
        </>
      }
      description={<StaffDescription hit={staff} />}
      id={staff.id}>
      <Avatar
        name={getInitials(staff)}
        src={staff.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};
