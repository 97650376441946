import { ReactNode } from "react";

import { ContentPlaceholder } from "./ContentPlaceholder";
import { useGenericComponents } from "../GenericComponentsProvider";

interface Props {
  title: string;
  content: ReactNode;
  empty: boolean;
  emptyText: string;
  loading: boolean;
  headerAction?: {
    text: string;
    onClick: () => void;
  };
}

export const CollapsibleWidget = ({
  title,
  emptyText,
  headerAction,
  content,
  loading,
  empty,
}: Props) => {
  const { BaseCard, Text } = useGenericComponents();

  const body = loading ? (
    <Text>Loading...</Text>
  ) : empty ? (
    <ContentPlaceholder icon="helpCircleOutline" title={emptyText} />
  ) : (
    content
  );

  return (
    <BaseCard
      collapsible
      collapsed={loading || empty}
      title={title}
      bodySlot={body}
      headerAction={headerAction}
    />
  );
};
