import * as Sentry from "@sentry/react";

import { Logger, shouldSendToSentry } from "shared/components";

import { appEnvironment, appVersion } from "@/lib/appVersion";

const captureMessage = (
  message: string,
  level: "info" | "debug" | "log" | "warning" | "error" | "fatal",
  error?: Error,
) => {
  if (shouldSendToSentry(appEnvironment)) {
    Sentry.withScope(scope => {
      scope.setTag("appVersion", appVersion);
      if (error) {
        Sentry.captureException(error);
      }
      Sentry.captureMessage(message, level);
    });
  } else {
    console.log(`[${level.toUpperCase()}] ${message}`);
  }
};

export const logger: Logger = {
  info: (message: string) => captureMessage(message, "info"),
  debug: (message: string) => captureMessage(message, "debug"),
  log: (message: string) => captureMessage(message, "log"),
  warning: (message: string) => captureMessage(message, "warning"),
  error: (message: string, error?: Error) =>
    captureMessage(message, "error", error),
  fatal: (message: string) => captureMessage(message, "fatal"),
};
