import { ComponentType, ReactNode } from "react";

import {
  MobileActionMenuItemProps,
  WebActionMenuItemProps,
} from "./actionMenu";
import { IconName } from "../../availableIcons";
import { colors } from "../../colors";
import { Permission } from "../../permission";
import { ActionMenuBuilder } from "../action-menu/actionMenuBuilder";

export type TextColor = "grey-600" | "grey-900" | "white";

type IconPosition = "left" | "left-framed" | "top" | "title";
export const cardTextColorMap: Record<TextColor, string> = {
  white: colors.white,
  "grey-900": colors.grey["900"],
  "grey-600": colors.grey["600"],
};

export type BaseCardComponent = ComponentType<
  BaseCardProps<WebActionMenuItemProps | MobileActionMenuItemProps>
>;

export interface BaseCardProps<MenuItem> {
  // Variants
  border?: boolean;
  borderRadius?: "rounded" | "rounded-md";
  /**
   * If not provided, card is always white, with grey-100 hover state.
   * If provided, hover state will be overlay-white-light.
   * */
  fillColor?: string;
  /** The Y padding of the card. Defaults to 4 */
  padding?: 2 | 4;
  gap?: 0 | 1 | 2;

  collapsed?: boolean;
  collapsible?: boolean;

  // Title
  title?: string | ReactNode;
  titleColor?: TextColor;
  titleSize?: 14 | 16;
  titleWeight?: 400 | 600;
  titleTruncate?: boolean;

  // Description
  description?: string;
  descriptionSize?: 14 | 16 | 24;
  descriptionWeight?: 400 | 600;
  descriptionColor?: TextColor;
  descriptionTruncate?: boolean;

  // Actions
  onClick?: () => void; // Enables hover state
  headerAction?: { text: string; onClick: () => void; permission?: Permission }; // Shows as single button with text
  headerActions?: Array<MenuItem>; // Shows as a meatballs action menu
  headerMenu?: ReactNode; // Custom action menu
  actions?: ActionMenuBuilder; // Custom action menu
  footerAction?: { text: string; onClick: () => void; permission?: Permission }; // Shows as a single button with text

  // Slots
  headerRightSlot?: ReactNode; // Used for enabled features cards, etc
  leftSlot?: ReactNode; // Used for avatar, etc
  bodySlot?: ReactNode; // Used for cards with custom body content
  rightSlot?: ReactNode; // Used on cards that have status chips on right hand side
  footerSlot?: ReactNode; // Used for cards that have chips, etc

  // Icons
  icon?: IconName;
  iconPosition?: IconPosition;
  iconColor?: string;

  permission?: Permission;
}
