import type { Hit } from "instantsearch.js";
import { match } from "ts-pattern";

import {
  FamilyDocument,
  findFirstContactHit,
  getFirstLetters,
  SearchCollection,
} from "shared/lib";

import { SearchResult } from "@/modules/common/search/searchResults/SearchResult";
import { StyledHighlight } from "@/modules/common/search/StyledHighlight";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";

interface FamilySearchResultProps {
  hit: Hit<FamilyDocument>;
}

const FamilyDescription = ({ hit: family }: FamilySearchResultProps) => {
  const hitContact = findFirstContactHit(family);

  if (!hitContact) {
    return null;
  }

  if (!hitContact._highlightResult) {
    return (
      <>
        {`${hitContact.firstname} ${hitContact.lastname}`}
        {hitContact.email && (
          <>
            {" • "}
            {hitContact.email}
          </>
        )}
      </>
    );
  }

  const name = (
    <>
      <StyledHighlight attribute="firstname" hit={hitContact} />{" "}
      <StyledHighlight attribute="lastname" hit={hitContact} />
    </>
  );

  return match(hitContact)
    .with({ hitAttribute: "email" }, contact => (
      <>
        {name}
        {" • "}
        <StyledHighlight attribute="email" hit={contact} />
      </>
    ))
    .with({ hitAttribute: "phone" }, contact => (
      <>
        {name}
        {" • "}
        <StyledHighlight attribute="phone" hit={contact} />
      </>
    ))
    .otherwise(() => (
      <>
        {name}
        {hitContact.email && (
          <>
            {" • "}
            <StyledHighlight attribute="email" hit={hitContact} />
          </>
        )}
      </>
    ));
};

export const FamilySearchResult = ({
  hit: family,
}: FamilySearchResultProps) => {
  return (
    <SearchResult
      type={SearchCollection.family}
      title={<StyledHighlight attribute="name" hit={family} />}
      description={<FamilyDescription hit={family} />}
      id={family.id}>
      <Avatar
        name={getFirstLetters(family.name)}
        src={family.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};
