import { RegistrationFeeDto } from "@justraviga/classmanager-sdk";

import { useRegistrationFeeActions } from "@shared/components/actions/useRegistrationFeeActions";
import { useRegistrationFeesDatatable } from "@shared/components/datatables/useRegistrationFeesDatatable";
import { registrationFeeMobileRowDetailContent } from "@shared/registrationFeeUtils";

import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const RegistrationFeeListPage = () => {
  const defaultActions = usePlatformEntityActions<RegistrationFeeDto>({
    entity: "registrationFee",
  });
  const registrationFeeActions = useRegistrationFeeActions(defaultActions);
  const { datatable } = useRegistrationFeesDatatable({
    Datatable,
    showCreateForm: registrationFeeActions.showCreateForm,
    showUpdateForm: registrationFeeActions.showUpdateForm,
    deleteOne: registrationFeeActions.deleteOne,
    archive: registrationFeeActions.archive,
    restore: registrationFeeActions.restore,
    renderMobileDetails: item => (
      <div>
        <div className="text-label-400 capitalize leading-tight text-grey-600">
          {registrationFeeMobileRowDetailContent(item)}
        </div>
      </div>
    ),
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  RegistrationFeeListPage,
  "Registration fees",
);

export { PermissionChecked as RegistrationFeeListPage };
