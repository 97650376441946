import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { taxRateLabel } from "../../taxRateUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export const DisplayTaxRate = ({ taxRate }: { taxRate?: TaxRateDto }) => {
  const { Text } = useGenericComponents();

  if (!taxRate) {
    return <Text className={"text-right text-body-400"}>None</Text>;
  }

  return (
    <Text className={"text-right text-body-400"}>{taxRateLabel(taxRate)}</Text>
  );
};
