import { FC, ReactNode } from "react";

import { RegistrationFeeDto } from "@justraviga/classmanager-sdk";

import { DatatableAmountText } from "./DatatableAmountText";
import { DisplayTaxRate } from "./DisplayTaxRate";
import {
  DatatableColumnProps,
  DatatableConfiguration,
  DatatableQueryProps,
} from "../../datatable";
import { getPlatformFunctions } from "../../platformSpecific";
import { intervalMapToLabel } from "../../registrationFeeUtils";
import { useAuthState } from "../AuthStateProvider";
import {
  RegistrationFeeFilterFormSchema,
  useRegistrationFeeFilterForm,
} from "../formDefinitions/registrationFeeFilterForm";

type Dto = RegistrationFeeDto;
type FilterForm = RegistrationFeeFilterFormSchema;

const renderAmount = (amount: number) => {
  return <DatatableAmountText amount={amount} />;
};

export const useRegistrationFeesDatatable = ({
  Datatable,
  showCreateForm,
  deleteOne,
  showUpdateForm,
  archive,
  restore,
  renderMobileDetails,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, FilterForm> }>;
  showCreateForm: () => void;
  deleteOne: (item: Dto) => Promise<void>;
  showUpdateForm: (item: Dto) => void;
  archive: (item: Dto) => Promise<void | Dto>;
  restore: (item: Dto) => Promise<Dto>;
  renderMobileDetails: (item: Dto) => ReactNode;
}) => {
  const { api } = getPlatformFunctions();
  const { account } = useAuthState();
  const hasTaxEnabled = account?.company?.settings.tax.enabled ?? false;
  const filterForm = useRegistrationFeeFilterForm();

  const fetchData = async (query: DatatableQueryProps) => {
    return api.registrationFees.listRegistrationFee(query.toRequest());
  };

  const desktopColumns: DatatableColumnProps<Dto>[] = [
    {
      label: "Name",
      placeholder: "tile",
      value: row => ({
        type: "tile",
        title: row.item.name,
        hasImage: false,
      }),
      sortableField: "name",
    },
    {
      label: "Frequency",
      placeholder: "text",
      value: row => ({
        type: "text",
        text: intervalMapToLabel[row.item.repetitionInterval],
      }),
    },
    {
      label: "Per student",
      placeholder: "text",
      value: row => ({
        type: "custom",
        children: renderAmount(row.item.amount),
      }),
    },
    {
      label: "Max per family",
      placeholder: "text",
      value: row => ({
        type: "custom",
        children: renderAmount(row.item.maxAmountPerFamily ?? 0),
      }),
    },
  ];

  if (hasTaxEnabled) {
    desktopColumns.push({
      label: "Tax rate",
      placeholder: "text",
      value: row => ({
        type: "custom",
        children: <DisplayTaxRate taxRate={row.item.taxRate ?? undefined} />,
      }),
    });
  }

  const config: DatatableConfiguration<Dto, RegistrationFeeFilterFormSchema> = {
    id: "registrationFee",
    title: "Registration fees",
    createAction: showCreateForm,
    fetchData,
    filterForm,
    hasPagination: true,
    placeholdersCount: 5,
    permissions: {
      create: "schedule:manage",
      archive: "schedule:manage",
      delete: "schedule:manage",
      restore: "schedule:manage",
      edit: "schedule:manage",
    },
    rowActions: {
      archive: archive,
      delete: deleteOne,
      restore: restore,
      edit: showUpdateForm,
    },
    columns: desktopColumns,
    contentPlaceholders: {
      noContent: {
        title: "No registration fees yet",
        description:
          "Here you will see a list of all registration fees. These are fees that can be charged to families when they register with your studio, annually or when they register for a season.",
        icon: "helpCircleOutline",
      },
    },
    mobileColumn: {
      hasImage: false,
      title: row => row.item.name,
      subtitle: row => intervalMapToLabel[row.item.repetitionInterval],
      children: row => renderMobileDetails(row.item),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
