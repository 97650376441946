import { ComponentType, ReactNode } from "react";

import { withPermissionCheck } from "@shared/components/permission/withPermissionCheck";
import { Permission } from "@shared/permission";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import {
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";

export const withCompanyLayoutPermissionCheck = <P extends object>(
  permission: Permission,
  Component: ComponentType<P>,
  title: string,
) =>
  withPermissionCheck(permission, Component, {
    renderPermissionError: (content: ReactNode) => (
      <CompanyLayout>
        <CompanyTitleBar isTopLevel={true}>
          <div className="-ml-2 flex flex-row items-center gap-x-2 md:hidden">
            <IconButton
              icon={"chevronBackOutline"}
              variant={"standard"}
              size={"lg"}
              onClick={() => history.back()}
            />
            {title}
          </div>
          <div className="hidden md:flex">
            <CompanyTitleBarTitle title={title} />
          </div>
        </CompanyTitleBar>
        {content}
      </CompanyLayout>
    ),
  });
