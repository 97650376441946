import { ReactNode } from "react";

import { PermissionDeniedPlaceholder } from "./PermissionDeniedPlaceholder";
import { usePermission } from "./usePermission";
import { Permission } from "../../permission";
import { ProtectionChecker } from "../protector";

export interface PermissionLayoutProps {
  protector?: ProtectionChecker;
  children: ReactNode;
  permission?: Permission;
  Tooltip?: (props: {
    trigger: ReactNode;
    children: ReactNode;
    side?: "top" | "right" | "bottom" | "left";
    align?: "start" | "center" | "end";
    showContent?: boolean;
    delayDuration?: number;
  }) => ReactNode;
  shape?: "rounded" | "rounded-full";
}

export const PermissionLayout = ({
  permission,
  children,
}: Omit<PermissionLayoutProps, "shape">) => {
  const { hasPermission, isLoading } = usePermission();

  if (isLoading) {
    return null;
  }

  if (permission === undefined || hasPermission(permission)) {
    return children;
  }

  return <PermissionDeniedPlaceholder />;
};
