import { ContactDto, FamilyDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeFamilyCreateRequest,
  makeFamilyUpdateRequest,
  useFamilyCreateFormDefinition,
  useFamilyUpdateFormDefinition,
} from "../formDefinitions/familyForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = FamilyDto;

/**
 * These actions don't need any frontend so can be shared
 */
export const useSharedFamilyActions = (
  defaultActions: DefaultEntityActions<Model>,
  goToDetailsPage: (family: Model) => void,
) => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  return {
    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.makeOnCreateSuccess({
              redirect: goToDetailsPage,
            })}
            apiRequest={makeFamilyCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useFamilyCreateFormDefinition}
          />
        ),
      }),

    showUpdateForm: (
      entity: Model,
      { primaryContact }: { primaryContact: ContactDto },
    ) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.onUpdateSuccess}
            apiRequest={makeFamilyUpdateRequest({ api, id: entity.id })}
            defaultValues={{
              firstname: primaryContact.firstname,
              lastname: primaryContact.lastname as string | undefined,
              email: primaryContact.email as string | undefined,
              phone: primaryContact.phone as string | undefined,
            }}
            formDefinitionHook={useFamilyUpdateFormDefinition}
          />
        ),
      }),

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.families.deleteFamily({ id: entity.id }),
      ),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.families.deleteManyFamily({ deleteManyFamilyRequest: { ids } }),
        ids.length,
      ),

    archive: (entity: Model) =>
      defaultActions.archive(
        () => api.families.archiveFamily({ id: entity.id }),
        {
          extendedDescription:
            "They will no longer be able to access Class Manager.",
        },
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.families.unarchiveFamily({ id: entity.id }),
      ),

    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () => api.families.archiveFamilies({ archiveFamiliesRequest: { ids } }),
        ids.length,
        {
          extendedDescription:
            "They will no longer be able to access Class Manager.",
        },
      ),

    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.families.unarchiveFamilies({ unarchiveFamiliesRequest: { ids } }),
      ),

    cancelInvite: (entity: Model) =>
      api.families.inviteCancelFamily({ id: entity.id }).then(() => {
        showAlert({
          content: "Invitation canceled successfully",
        });
      }),

    revokeAccess: (entity: Model) =>
      api.auth
        .revokeAccounts({ revokeAccountsRequest: { entityId: entity.id } })
        .then(() =>
          showAlert({ content: "Account access removed successfully" }),
        ),

    getLinkData: (entity: Model) =>
      api.families.getFamilyInvitation({ id: entity.id }),
    getUserData: (entity: Model) =>
      api.families.getFamilyUser({ id: entity.id }),
  };
};
