import { useState } from "react";

import { ContentPlaceholder } from "shared/components";
import { colors, SelectItem, SelectProps, SelectValue } from "shared/lib";

import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Select } from "@/modules/common/form/select/Select";
import { Icon } from "@/modules/common/ui/icon/Icon";

const StatefulSelect = (props: SelectProps) => {
  const [value, setValue] = useState<SelectValue>(null);

  return <Select {...props} value={value} onSelect={setValue} />;
};

const items: SelectItem[] = [
  {
    value: "laravel",
    label: "Laravel",
    description: "PHP",
  },
  {
    value: "symfony",
    label: "Symfony",
    description: "PHP",
  },
  {
    value: "node.js",
    label: "Node.js",
    description: "Javascript",
  },
  {
    value: "remix",
    label: "Remix",
    description: "Javascript",
  },
  {
    value: "astro",
    label: "Astro",
    description: "Javascript",
  },
  {
    value: "django",
    label: "Django",
    description: "Python",
  },
  {
    value: "react.js",
    label: "React.js",
    description: "Javascript",
  },
  {
    value: "vue.js",
    label: "Vue.js",
    description: "Javascript",
  },
];

const itemsWithAvatarAndDisabledOption = [
  ...items.map((item: SelectItem) => {
    return {
      avatar: "https://picsum.photos/200",
      ...item,
    };
  }),
  {
    value: "disabled",
    label: "Disabled",
    avatar: "https://picsum.photos/200",
    disabled: true,
  },
  {
    value: "disabled2",
    label: "Disabled 2",
    avatar: "https://picsum.photos/200",
    disabled: true,
  },
];

const itemsWithDualContent = items.map((item: SelectItem) => {
  return {
    description: "Here is dual content",
    ...item,
    prepend: (
      <div className="mr-3">
        <Icon name="homeOutline" color={colors.red[600]} />
      </div>
    ),
    append: (
      <div className="ml-3">
        <Icon name="exitOutline" color={colors.green[600]} />
      </div>
    ),
  };
});

const itemsWithDisabledOption = [
  ...items,
  {
    value: "disabled",
    label: "Disabled",
    disabled: true,
  },
  {
    value: "disabled2",
    label: "Disabled 2",
    disabled: true,
  },
  {
    value: "disabledWithAvatar",
    label: "Disabled with avatar",
    disabled: true,
    avatar: "https://github.com/shadcn.png",
  },
];

const options: Array<Option<SelectProps>> = [
  {
    title: "Simple select",
    props: [
      {
        data: items,
        placeholder: "Choose an option",
      },
    ],
  },
  {
    title: "Disabled select",
    props: [
      {
        data: items,
        disabled: true,
        placeholder: "Choose an option",
      },
    ],
  },
  {
    title: "With dual content",
    props: [
      {
        data: itemsWithDualContent,
      },
    ],
  },
  {
    title: "With avatar and disabled items",
    props: [
      {
        data: itemsWithAvatarAndDisabledOption,
      },
    ],
  },
  {
    title: "With disabled items",
    props: [
      {
        data: itemsWithDisabledOption,
      },
    ],
  },
  {
    title: "With local search",
    props: [
      {
        data: items,
        localSearch: true,
      },
    ],
  },
  {
    title: "With loading state",
    props: [
      {
        data: itemsWithDualContent,
        loading: true,
      },
    ],
  },
  {
    title: "No data found",
    props: [
      {
        data: [],
      },
    ],
  },
  {
    title: "With no-content placeholder",
    props: [
      {
        data: [],
        notFoundLabel: (
          <ContentPlaceholder
            title={"Nothing to see here"}
            description={"Try adding something, see if it appears"}
            icon={"helpCircleOutline"}
          />
        ),
      },
    ],
  },
];

export const SelectPage = () => {
  const transformProps = (props: SelectProps[]): CodeProps<SelectProps>[] => {
    return props.map(function (prop: SelectProps) {
      return {
        ...prop,
        data: "{items}",
      };
    });
  };

  return (
    <DocWrapper
      title={"Select"}
      component={StatefulSelect}
      options={options}
      importPath='{ Select } from "@/modules/common/select/Select"'
      transformProps={transformProps}
    />
  );
};
