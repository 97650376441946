import React, { useEffect, useRef, useState } from "react";

import {
  BaseSelectProps,
  cn,
  colors,
  findObjectByKey,
  getPath,
  SelectItem,
} from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/modules/common/form/select/Command";
import { SelectTrigger } from "@/modules/common/form/select/SelectTrigger";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/modules/common/overlays/Popover";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const BaseSelect = ({
  clearable = true,
  data = [],
  description,
  disabled = false,
  error,
  isSelected = () => false,
  label,
  loading = false,
  localSearch = false,
  multiple = false,
  name,
  notFoundLabel,
  onClickRemoveAll,
  onSearch,
  onSelect,
  placeholder,
  remoteSearch = false,
  required,
  searchPlaceholder,
  onBlur,
}: BaseSelectProps) => {
  const [localData, setLocalData] = useState<SelectItem[]>(data);
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalData(data);
  }, [open, data]);

  useEffect(() => {
    if (triggerRef.current) {
      const width = triggerRef.current.offsetWidth;
      setTriggerWidth(width);
    }
  }, [isSelected]);

  const isAnyItemSelected = localData?.some(isSelected);

  // searches each word in the search string separately(using space as a separator)
  const searchInLocal = (search: string) => {
    if (!localSearch) {
      return;
    }

    if (search === "") {
      setLocalData(data);
      return;
    }

    const searchWords = search.toLowerCase().split(" ");

    const filteredData = data.filter(item => {
      const itemData = [
        item.label.toLowerCase(),
        String(item.value).toLowerCase(),
        item.description ? item.description.toLowerCase() : "",
      ].join(" ");

      return searchWords.every(word => itemData.includes(word));
    });

    setLocalData(filteredData);
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const q = getPath(e.target, "value") as string;
    !remoteSearch && searchInLocal(q);
    onSearch && onSearch(q);
  };

  const renderLoading = () => {
    return (
      <div className="flex justify-center py-8">
        <Button role="select-loading" loading={true} variant="tertiary" />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div
        className={cn("text-center text-sm", {
          "py-6":
            typeof notFoundLabel === "string" || notFoundLabel === undefined,
        })}>
        {notFoundLabel || "No data found."}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <Popover open={open} onOpenChange={setOpen}>
        <Label text={label} required={required}>
          <PopoverTrigger asChild>
            <SelectTrigger
              disabled={disabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (disabled) {
                  return;
                }

                setOpen(!open);
              }}
              ref={triggerRef}
              name={name}
              placeholder={placeholder}
              className={cn(
                "h-[40px] rounded focus:border-grey-900 focus:ring-0 focus:ring-offset-0 data-[state=open]:border-grey-900",
                {
                  "border-red-600": error,
                  "bg-red-100": error,
                },
              )}
              aria-expanded={open}>
              {clearable && isAnyItemSelected ? (
                <div className="-mr-2 ml-4">
                  <IconButton
                    disabled={disabled}
                    data-testid="baseSelectRemoveAllIcon"
                    variant="standard"
                    color={colors.grey[900]}
                    size="sm"
                    icon="closeOutline"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickRemoveAll();
                      onBlur?.();
                    }}
                  />
                </div>
              ) : (
                <div className="-mr-1 ml-4">
                  <IconButton
                    disabled={disabled}
                    variant="standard"
                    color={open ? colors.grey[900] : colors.grey[500]}
                    size="xs"
                    icon={open ? "chevronUp" : "chevronDown"}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpen(!open);
                    }}
                  />
                </div>
              )}
            </SelectTrigger>
          </PopoverTrigger>
          <InputDescription description={description} />
        </Label>
        <PopoverContent
          container={containerRef?.current as HTMLElement}
          className="min-w-fit"
          style={{ width: triggerWidth ? `${triggerWidth}px` : "max-content" }}>
          <Command shouldFilter={false}>
            {(localSearch || remoteSearch) && (
              <div className="pr-1">
                <CommandInput
                  onKeyUpCapture={handleSearch}
                  placeholder={searchPlaceholder || "Search"}
                />
              </div>
            )}
            {!loading && !localData.length && renderEmpty()}
            <CommandList>
              {loading
                ? renderLoading()
                : localData.map(item => {
                    const avatarProps =
                      item.avatar && item.avatar.startsWith("https://")
                        ? { src: item.avatar }
                        : { name: item.avatar };

                    return (
                      <CommandItem
                        key={String(item.value)}
                        className={`mb-1 ${item.disabled && "pointer-events-none hover:cursor-auto"}`}
                        value={String(item.value)}
                        onSelect={currentValue => {
                          if (item.disabled) {
                            return;
                          }

                          const findItem = findObjectByKey(
                            currentValue,
                            "value",
                            localData,
                          );
                          onSelect && onSelect(findItem!);
                          onBlur?.();
                          !multiple && setOpen(false);
                        }}>
                        <div className="flex items-center">
                          {item.avatar && <Avatar {...avatarProps} />}
                          {item.prepend && <span>{item.prepend}</span>}
                          <div
                            className={`flex flex-col gap-1 ${item.avatar && "ml-3"}`}>
                            <div
                              className={"flex flex-row items-center gap-x-2"}>
                              <span className="text-base text-grey-900">
                                {item.label}
                              </span>
                              {item.append ? <span>{item.append}</span> : null}
                            </div>
                            {item.description && (
                              <span className="text-grey-600">
                                {item.description}
                              </span>
                            )}
                          </div>
                        </div>
                        {isSelected(item) && (
                          <Icon name="checkmarkOutline" className="ml-2" />
                        )}
                        {item.disabled && (
                          <div className="absolute inset-0 bg-overlay-white-medium"></div>
                        )}
                      </CommandItem>
                    );
                  })}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
