import { useState } from "react";

import { CourseDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { ContentPlaceholder } from "shared/components";
import { groupClassLessonRecordsByDate, LessonRecord } from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ClassLessonRow } from "@/modules/company/classPlanner/classes/lessons/ClassLessonRow";
import { useLessonActions } from "@/modules/company/classPlanner/lessons/useLessonActions";

export const ClassLessonsList = ({
  courseDto,
  season,
  records,
}: {
  courseDto: CourseDto;
  season: SeasonDto;
  records: LessonRecord[];
}) => {
  if (!records || records.length === 0) {
    return (
      <ContentPlaceholder
        icon={"schoolOutline"}
        title={"No lessons created yet"}
      />
    );
  }

  const groupedRecordsByDate = groupClassLessonRecordsByDate(records);

  return (
    <div>
      <ClassUpcomingLessonsList
        courseDto={courseDto}
        season={season}
        records={groupedRecordsByDate.upcoming}
      />

      <ClassPastLessonsList
        courseDto={courseDto}
        records={groupedRecordsByDate.past}
      />
    </div>
  );
};

const ClassUpcomingLessonsList = ({
  courseDto,
  records,
  season,
}: {
  courseDto: CourseDto;
  season: SeasonDto;
  records: LessonRecord[];
}) => {
  const { showCreateForm } = useLessonActions();

  return (
    <section className={"space-y-2 md:space-y-5"}>
      <div className={"flex items-center justify-between"}>
        <h3 className={"text-heading6-600"}>Lessons</h3>
        <div>
          <Button
            variant={"tertiary"}
            text={"Add lesson"}
            onClick={() =>
              showCreateForm({
                classId: courseDto.id,
                date: season.startAt,
                time: courseDto.startTime,
              })
            }
          />
        </div>
      </div>
      <div className={"-mx-3 md:mx-0"}>
        {records.map((record: LessonRecord) => (
          <ClassLessonRow
            courseDto={courseDto}
            record={record}
            key={`upcoming-lesson-${record.lesson.date}-${record.lesson.startTime}-${record.lesson.endTime}`}
          />
        ))}
      </div>
    </section>
  );
};

const ClassPastLessonsList = ({
  courseDto,
  records,
}: {
  courseDto: CourseDto;
  records: LessonRecord[];
}) => {
  const [folded, setFolded] = useState(true);

  if (!records || records.length === 0) {
    return null;
  }

  return (
    <section className={"-mx-3 md:mx-0"}>
      <div className={"flex items-center space-x-2 px-3 py-4"}>
        <span className={"text-label-400 text-grey-600"}>Past lessons</span>
        <IconButton
          onClick={() => setFolded(!folded)}
          variant="standard"
          icon={folded ? "chevronDown" : "chevronUp"}
          size="xs"
          aria-label={folded ? "Open" : "Close"}
        />
      </div>
      {!folded && (
        <div>
          {records.map((record: LessonRecord) => (
            <ClassLessonRow
              courseDto={courseDto}
              record={record}
              key={`past-lesson-${record.lesson.date}-${record.lesson.startTime}-${record.lesson.endTime}`}
            />
          ))}
        </div>
      )}
    </section>
  );
};
