import { PropsWithChildren } from "react";

import { LoggerContext } from "./loggerContext";
import { Logger } from "./loggerTypes";

interface LoggerProviderProps extends PropsWithChildren {
  logger: Logger;
}

export const LoggerProvider = ({ logger, children }: LoggerProviderProps) => {
  return (
    <LoggerContext.Provider value={{ logger }}>
      {children}
    </LoggerContext.Provider>
  );
};
