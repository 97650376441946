import { DiscountSchemeDto } from "@justraviga/classmanager-sdk";

import {
  EntityActions,
  useSharedDiscountSchemeActions,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { DiscountSchemeCreateForm } from "@/modules/company/billing/discountSchemes/DiscountSchemeCreateForm";
import { Router } from "@/routing/router";

type Model = DiscountSchemeDto;

type ImplementedActions = Pick<
  EntityActions<Model>,
  "deleteOne" | "showCreateForm"
> & {
  updateStatus: (id: string, status: boolean) => void;
};

export const useDiscountSchemeActions = (): ImplementedActions => {
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "discountScheme",
  });
  const sharedActions = useSharedDiscountSchemeActions(
    defaultActions,
    api,
    DiscountSchemeCreateForm,
    (record: Model) => {
      Router.push("DiscountSchemeDetails", { id: record.id });
    },
  );

  return {
    ...sharedActions,
  };
};
