import { UpdateLocationRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = UpdateLocationRequest;

export const useLocationFormDefinition = () => {
  return new FormDefinitionBuilder<Schema>()
    .text("name", { label: "Name", required: true })
    .group("Basic information", ["name"])
    .getDefinition() as FormDefinition<Schema>;
};

export const makeLocationCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    return api.locations.createLocation({
      updateLocationRequest: formData,
    });
  };

export const makeLocationUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: Schema) => {
    return api.locations.updateLocation({
      id,
      updateLocationRequest: formData,
    });
  };
