import { useEffect, useState } from "react";

import {
  CreateSeasonRequest,
  PricingScheme,
  SeasonDto,
  TaxRateDto,
  UpdateSeasonRequest,
} from "@justraviga/classmanager-sdk";

import { FormDefinition } from "../../../../forms/formBuilderTypes";
import { getPlatformFunctions } from "../../../../platformSpecific";
import {
  makeSeasonCreateRequest,
  useSeasonFormDefinition,
  useUpdatePricingSchemeDefinition,
} from "../../../formDefinitions/seasonForm";
import { MultiStepsHeader } from "../../../forms/MultiStepsHeader";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  onSuccess: (newSeason: SeasonDto) => void;
  taxRates: TaxRateDto[];
  shouldCloseOnSuccess: () => boolean;
  closeSheet: () => void;
  setTitle: (title: string) => void;
  setCreateButtonText: (createButtonText: string) => void;
  setAllowCreateAdditional: (allowCreateAdditional: boolean) => void;
}

export const SeasonCreateForm = ({
  onSuccess,
  taxRates,
  shouldCloseOnSuccess,
  closeSheet,
  setTitle,
  setCreateButtonText,
  setAllowCreateAdditional,
}: Props) => {
  const [step, setStep] = useState(1);
  const { View } = useGenericComponents();
  const { api } = getPlatformFunctions();

  // data
  const [detailsData, setDetailsData] = useState<
    CreateSeasonRequest | undefined
  >();

  const handleStepOneSuccess = (detailsData: CreateSeasonRequest) => {
    setDetailsData(detailsData);
    setStep(2);
  };

  // form definitions
  const createFormDefinition = useSeasonFormDefinition(taxRates);
  const updatePricingSchemeDefinition = useUpdatePricingSchemeDefinition();

  // callbacks
  async function createSeason(formData: UpdateSeasonRequest) {
    if (detailsData === undefined) {
      return formData;
    }

    const newSeason = await makeSeasonCreateRequest({
      api,
    })({
      ...detailsData,
      pricingScheme: formData.pricingScheme!,
    });

    onSuccess(newSeason);

    if (shouldCloseOnSuccess()) {
      closeSheet();
    } else {
      setDetailsData(undefined);
      setStep(1);
    }

    return formData;
  }

  // helpers
  function getDefaultTaxRateId() {
    return taxRates.find(taxRate => taxRate.isDefault)?.id;
  }

  useEffect(() => {
    const [createButtonText, title] =
      step === 1 ? ["Continue", "Create season"] : ["Create", "Create season"];
    const allowCreateAdditional = step !== 1;

    setCreateButtonText(createButtonText);
    setTitle(title);
    setAllowCreateAdditional(allowCreateAdditional);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const form =
    step === 1 ? (
      <SeasonCreateStep1Form
        setDetailsData={handleStepOneSuccess}
        getDefaultTaxRateId={getDefaultTaxRateId}
        defaultValues={detailsData ?? undefined}
        createFormDefinition={createFormDefinition}
      />
    ) : (
      <SeasonCreateStep2Form
        detailsData={detailsData!}
        createSeason={createSeason}
        updatePricingSchemeDefinition={updatePricingSchemeDefinition}
      />
    );

  return (
    <View className={"flex flex-col space-y-4"}>
      <MultiStepsHeader
        currentStep={step}
        steps={[
          { index: 1, label: "Season details" },
          { index: 2, label: "Pricing scheme", goBack: () => setStep(1) },
        ]}
      />
      {form}
    </View>
  );
};

const SeasonCreateStep1Form = ({
  setDetailsData,
  getDefaultTaxRateId,
  defaultValues = {
    registrationFeeTaxRateId: getDefaultTaxRateId(),
  } as CreateSeasonRequest,
  createFormDefinition,
}: {
  setDetailsData: (detailsData: CreateSeasonRequest) => void;
  getDefaultTaxRateId: () => string | undefined;
  defaultValues?: CreateSeasonRequest;
  createFormDefinition: FormDefinition<CreateSeasonRequest>;
}) => {
  const { Text, GenericForm } = useGenericComponents();

  return (
    <>
      <Text className={"pb-4 text-body-400 text-grey-600"}>
        Seasons can be used to plan weekly classes and enroll students on an
        annual basis.
      </Text>
      <GenericForm
        onSuccess={() => {}}
        apiRequest={formData => {
          setDetailsData(formData);
          return Promise.resolve(formData);
        }}
        defaultValues={defaultValues}
        formDefinitionHook={() => createFormDefinition}
      />
    </>
  );
};

const SeasonCreateStep2Form = ({
  detailsData,
  createSeason,
  updatePricingSchemeDefinition,
}: {
  detailsData: CreateSeasonRequest;
  createSeason: (formData: UpdateSeasonRequest) => Promise<UpdateSeasonRequest>;
  updatePricingSchemeDefinition: FormDefinition<UpdateSeasonRequest>;
}) => {
  const { Text, View, GenericForm } = useGenericComponents();

  return (
    <>
      <View className={"mb-2"}>
        <Text className={"mb-1 text-base font-semibold text-grey-900"}>
          How do you price your classes?
        </Text>
        <Text className={"text-body-400 text-grey-600"}>
          You can charge for your classes in a few different ways in Class
          Manager. Select one of the pricing schemes below.
        </Text>
      </View>
      <GenericForm
        onSuccess={() => {}}
        apiRequest={formData => createSeason(formData)}
        defaultValues={{
          ...detailsData,
          pricingScheme: PricingScheme.None,
        }}
        formDefinitionHook={() => updatePricingSchemeDefinition}
      />
    </>
  );
};
