import {
  displayStudentAge,
  displayStudentDateOfBirth,
  displayStudentGender,
  StudentExtraInfoProps,
} from "shared/lib";

export const StudentExtraInfo = ({
  dateOfBirth,
  gender,
}: StudentExtraInfoProps) => {
  return (
    <div className={"flex flex-col space-y-4"}>
      <div
        className={
          "flex flex-col space-y-4 text-body-400 text-grey-900 md:flex-row md:items-center md:space-x-8 md:space-y-0"
        }>
        <span className={"whitespace-nowrap"}>
          {displayStudentAge({ dateOfBirth }, "Age: ")}
        </span>
        <span className={"whitespace-nowrap"}>
          {displayStudentDateOfBirth({ dateOfBirth })}
        </span>
        <span className={"whitespace-nowrap"}>
          {displayStudentGender({ gender })}
        </span>
      </div>
    </div>
  );
};
