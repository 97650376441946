import {
  AggregateClassDto,
  ClassColor,
  SeasonDto,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { displayLessonTimes } from "../../../../classUtils";
import { colors } from "../../../../colors";
import { colorPaletteColors } from "../../../../forms/formComponentProps";
import { ActionMenu } from "../../../action-menu/ActionMenu";
import { useSharedStudentActions } from "../../../actions/useSharedStudentActions";
import { useTrialActions } from "../../../actions/useTrialActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ActionMenuItem } from "../../../interfaces";
import { Chip } from "../../../ui/Chip";

export interface StudentLessonInstance {
  classId: string;
  date: string;
  time: string;
  type: "lesson" | "trial" | "makeUp";
  cancelled: boolean;
}

interface StudentLessonCardProps {
  student: StudentDto;
  goToCourse: (courseId: string) => void;
  lesson: StudentLessonInstance;
  trial?: TrialDto;
  course: AggregateClassDto;
  season: SeasonDto;
}

export const StudentLessonCard = ({
  student,
  goToCourse,
  lesson,
  trial,
  course,
  season,
}: StudentLessonCardProps) => {
  const { BaseCard, IconButton, View, Text } = useGenericComponents();

  const actionMenuItems = useHeaderActions(
    course,
    goToCourse,
    trial,
    lesson,
    student,
  );

  const backgroundColor =
    ["trial", "makeUp"].includes(lesson.type) || lesson.cancelled
      ? colors.white
      : colorPaletteColors[course.entity.colorId ?? ClassColor.Grey];

  const shouldHaveBorder =
    ["trial", "makeUp"].includes(lesson.type) || lesson.cancelled;

  const description =
    displayLessonTimes({
      startTime: lesson.time,
      durationInMinutes: course.entity.durationInMinutes,
    }) +
    " • " +
    season.name;

  return (
    <BaseCard
      headerMenu={
        <ActionMenu
          title={course.entity.name}
          size={"lg"}
          trigger={
            <IconButton icon={"ellipsisHorizontal"} variant={"standard"} />
          }
          items={actionMenuItems}
        />
      }
      border={shouldHaveBorder}
      description={description}
      descriptionColor={"grey-900"}
      descriptionSize={14}
      descriptionTruncate={true}
      fillColor={backgroundColor}
      padding={2}
      title={
        <View className="flex flex-row items-center gap-x-4">
          <View className="flex-shrink">
            <Text
              className="text-body-600 font-semibold text-grey-900"
              truncate={true}>
              {course.entity.name}
            </Text>
          </View>
          <View className="flex-grow flex flex-row items-center gap-x-2 justify-start">
            {lesson.type === "trial" && (
              <Chip label={"Trial"} variant={"neutral"} size={"sm"} />
            )}
            {lesson.type === "makeUp" && (
              <Chip label={"Make up"} variant={"neutral"} size={"sm"} />
            )}
            {lesson.cancelled && (
              <Chip label={"Cancelled"} variant={"danger"} size={"sm"} />
            )}
          </View>
        </View>
      }
    />
  );
};

function useHeaderActions(
  course: AggregateClassDto,
  goToCourse: (id: string) => void,
  trial: TrialDto | undefined,
  lesson: StudentLessonInstance,
  student: StudentDto,
) {
  const trialActions = useTrialActions();
  const studentActions = useSharedStudentActions();

  const actionMenuItems: ActionMenuItem[] = [
    {
      type: "item",
      text: "Go to class",
      icon: "openOutline",
      onClick: () => {
        goToCourse(course.aggregateId);
      },
      permission: "members:view",
    },
  ];

  if (trial) {
    actionMenuItems.push(
      {
        type: "separator",
      },
      {
        type: "group-heading",
        text: "Trial",
      },
      {
        type: "item",
        text: "Edit",
        icon: "createOutline",
        onClick: () => {
          trialActions.showUpdateForm(trial);
        },
        permission: "enrolments:manage",
      },
    );
  }

  if (lesson.type === "lesson") {
    actionMenuItems.push(
      {
        type: "separator",
      },
      {
        type: "group-heading",
        text: "Enrollment",
      },
      {
        type: "item",
        text: "Assign make up lesson",
        icon: "swapHorizontalOutline",
        onClick: () => {
          studentActions.makeUpLesson(student);
        },
        permission: "enrolments:manage",
      },
    );
  }

  return actionMenuItems;
}
