import { LocationDto } from "@justraviga/classmanager-sdk";

import {
  Breadcrumb,
  ContentPlaceholder,
  LocationCard,
  useGenericComponents,
} from "shared/components";
import { useLocationsAndRooms } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { Router } from "@/routing/router";

export const ArchivedLocationsPage = () => {
  const { View } = useGenericComponents();
  const { locations, roomCountForLocation, isEmpty } = useLocationsAndRooms({
    onlyArchived: true,
  });

  const crumbs: Breadcrumb[] = [
    {
      text: "Locations",
      onClick: () => Router.push("LocationList"),
    },
    {
      text: "Archived Locations",
    },
  ];

  function goToLocation(location: LocationDto) {
    Router.push("LocationDetails", { id: location.id });
  }

  return (
    <CompanyCardPage
      title={"Archived Locations"}
      isEmpty={isEmpty}
      crumbs={crumbs}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon={"archiveOutline"}
          title={"No archived locations"}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {locations.map((location, key) => (
          <View key={key}>
            <LocationCard
              location={location}
              roomCount={roomCountForLocation(location)}
              goToLocation={goToLocation}
            />
          </View>
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
