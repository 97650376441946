import { StatCountCard } from "./StatCountCard";
import { dateSubtract, dateToday } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";

const EnrolledStudentsWidget = () => {
  const { useApi } = getPlatformFunctions();

  const { data: total, isLoading: isLoadingTotal } = useApi(
    "getEnrolmentStudentCount",
    {},
    {},
  );

  const { data: recent, isLoading: isLoadingRecent } = useApi(
    "getEnrolmentStudentCount",
    {
      where: {
        createdAt: {
          greaterThanOrEqual: dateSubtract(dateToday(), 28, "day"),
        },
      },
    },
  );

  const isLoading = isLoadingTotal || isLoadingRecent;

  return (
    <StatCountCard
      isLoading={isLoading}
      title={"Total enrolled students"}
      totalCount={total?.count ?? 0}
      recentCount={recent?.count ?? 0}
    />
  );
};

const PermissionChecked = withPermissionCheck(
  "financial:view",
  EnrolledStudentsWidget,
);

export { PermissionChecked as EnrolledStudentsWidget };
