import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { CourseSnippet } from "./CourseSnippet";
import { useCourseSearch } from "./useCourseSearch";
import { courseIsSuitableForAge } from "../../classUtils";
import { useCatalogueData } from "../../data/useCatalogueData";
import { getPlatformFunctions } from "../../platformSpecific";
import { useCatalogueContext } from "../catalogue/useCatalogueContext";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  ButtonComponent,
  CourseImageComponent,
  FullScreenModal,
  IconButtonComponent,
  Sheet,
} from "../interfaces";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";

export const FilteredCourses = ({
  Button,
  CourseImage,
  IconButton,
  mode,
  onClick,
  sheet,
}: {
  Button: ButtonComponent;
  CourseImage: CourseImageComponent;
  IconButton: IconButtonComponent;
  mode: "enrol" | "trial";
  onClick: (courseId: string) => void;
  sheet: FullScreenModal | Sheet;
}) => {
  const { View, Pressable } = useGenericComponents();
  const { useApi } = getPlatformFunctions();
  const { company } = useCatalogueContext();

  const {
    searchKey,
    searchValues: { ages, days, locationIds, query, seasonIds },
    filterCount,
  } = useCourseSearch();
  const { courses, rooms } = useCatalogueData(useApi, true, mode);

  if (!courses || !rooms) {
    return null;
  }

  if (courses.length === 0) {
    return (
      <ContentPlaceholder
        icon={"helpCircleOutline"}
        title={"No classes available"}
        description={`There are currently no classes available for enrollment at ${company.name}. Check back later.`}
      />
    );
  }

  // Filter courses by age, day, location, and season
  const relevantRoomIds = [
    ...new Set(
      locationIds?.flatMap(locationId =>
        rooms
          ?.filter(room => room.locationId === locationId)
          .map(room => room.id),
      ) ?? [],
    ),
  ];

  const matchesAge = (course: AggregateClassDto) =>
    !ages.length ||
    ages.some(ageInYears => courseIsSuitableForAge(course, ageInYears));

  const matchesDay = (course: AggregateClassDto) =>
    !days.length || days.includes(course.entity.dayOfWeek);

  const matchesLocation = (course: AggregateClassDto) =>
    !locationIds.length || relevantRoomIds.includes(course.entity.roomId ?? "");

  const matchesQuery = (course: AggregateClassDto) =>
    !query.length ||
    course.entity.name.toLowerCase().includes(query.toLowerCase());

  const matchesSeason = (course: AggregateClassDto) =>
    !seasonIds.length || seasonIds.includes(course.entity.seasonId);

  const matchesMode = (course: AggregateClassDto) =>
    mode === "enrol" ? true : course.settings.trialsEnabled;

  const filteredCourses = (courses ?? [])
    .filter(matchesAge)
    .filter(matchesDay)
    .filter(matchesLocation)
    .filter(matchesQuery)
    .filter(matchesSeason)
    .filter(matchesMode);

  const numResults = filteredCourses.length;

  if (numResults === 0) {
    return (
      <View key={searchKey}>
        <NumberOfResults
          query={query}
          filterCount={filterCount}
          numResults={numResults}
        />
        <View className={"flex flex-row items-center space-x-2"}>
          <ContentPlaceholder
            title={"We couldn’t find what you’re looking for"}
            description={"Try broadening your search"}
            icon={"helpCircleOutline"}
          />
        </View>
      </View>
    );
  }

  return (
    <View key={searchKey}>
      <NumberOfResults
        query={query}
        filterCount={filterCount}
        numResults={numResults}
      />

      <View className="flex flex-col gap-y-8">
        {filteredCourses.map(course => (
          <Pressable
            onClick={() => onClick(course.entity.id)}
            key={course.entity.id}>
            <CourseSnippet
              key={course.entity.id}
              bookingMode={mode}
              course={course}
              sheet={sheet}
              Button={Button}
              CourseImage={CourseImage}
              IconButton={IconButton}
            />
          </Pressable>
        ))}
      </View>
    </View>
  );
};

const NumberOfResults = ({
  query,
  filterCount,
  numResults,
}: {
  query: string;
  filterCount: number;
  numResults: number;
}) => {
  const { Text } = useGenericComponents();

  if (query === "" && filterCount === 0) {
    return null;
  }

  return (
    <Text className="text-label-400 text-grey-900 mb-5">
      {numResults === 1 ? "1 result" : `${numResults} results`}
    </Text>
  );
};
