import {
  DiscountSchemeApplication,
  DiscountSchemeDto,
  DiscountSchemeType,
  DiscountSchemeValueDto,
  DiscountType,
  EntityName,
} from "@justraviga/classmanager-sdk";

import { IconName } from "./availableIcons";
import { MultiStepsHeaderStep } from "./forms/multiStepFormProps";
import { numberToOrdinal } from "./numberUtils";
import { entityTranslations, enumLabel } from "./translateUtils";

export const sortDiscountScheme = (
  data: Array<DiscountSchemeDto>,
): Array<DiscountSchemeDto> => {
  const order = [
    DiscountSchemeType.StudentQtyByFamily,
    DiscountSchemeType.CourseQtyByStudent,
    DiscountSchemeType.CourseQtyByFamily,
  ];

  return data.sort((a, b) => {
    return order.indexOf(a.scheme) - order.indexOf(b.scheme);
  });
};

export const currencyPlaceholder = "$";

export const discountSchemeSubtitle: Record<DiscountSchemeType, string> = {
  [DiscountSchemeType.StudentQtyByFamily]:
    "Apply a discount to any families with multiple students.",
  [DiscountSchemeType.CourseQtyByStudent]:
    "Apply a discount to any students taking multiple classes.",
  [DiscountSchemeType.CourseQtyByFamily]:
    "Apply a discount to any families taking multiple classes.",
};

export const discountTypeDescription: Record<DiscountType, string> = {
  [DiscountType.Percent]: "For example, 10% off tuition.",
  [DiscountType.Fixed]: `For example, ${currencyPlaceholder}10 off tuition.`,
};

export const discountSchemeCardIcon: Record<DiscountSchemeType, IconName> = {
  [DiscountSchemeType.StudentQtyByFamily]: "people",
  [DiscountSchemeType.CourseQtyByStudent]: "calendar",
  [DiscountSchemeType.CourseQtyByFamily]: "calendar",
};
export const discountSchemeFormIcon: Record<DiscountSchemeType, IconName> = {
  [DiscountSchemeType.StudentQtyByFamily]: "percentageOutline",
  [DiscountSchemeType.CourseQtyByStudent]: "calendarOutline",
  [DiscountSchemeType.CourseQtyByFamily]: "calendarOutline",
};

interface DiscountSchemeCardDetails {
  chipLabel: string;
  chipIcon: IconName;
  chipVariant: "success" | "neutral";
  actionTitle: string;
}

export const discountSchemeCardDetails = (
  discountScheme: DiscountSchemeDto,
): DiscountSchemeCardDetails => {
  if (discountScheme.enabled) {
    return {
      chipLabel: "Enabled",
      chipIcon: "checkmarkCircleOutline",
      chipVariant: "success",
      actionTitle: "Disable",
    };
  }

  return {
    chipLabel: "Disabled",
    chipIcon: "closeCircleOutline",
    chipVariant: "neutral",
    actionTitle: "Enable",
  };
};

const getTypeDetailIcon = (discountScheme: DiscountSchemeDto): IconName => {
  return getDiscountSchemeApplicationDetails(discountScheme).detailIcon;
};

export const getDiscountSchemeDetails = (discountScheme: DiscountSchemeDto) => {
  return {
    ...discountSchemeCardDetails(discountScheme),
    title: discountSchemeDetailPageTitle[discountScheme.scheme],
    typeDetails: {
      title: enumLabel("discountType", discountScheme.type),
      description: discountTypeDescription[discountScheme.type],
      icon: (discountScheme.type === DiscountType.Percent
        ? "percentage"
        : "cashOutline") as IconName,
    },
    applicationDetails: {
      title: getDiscountSchemeApplicationDetails(discountScheme).formLabel,
      description:
        getDiscountSchemeApplicationDetails(discountScheme).formDescription,
      icon: getTypeDetailIcon(discountScheme),
    },
  };
};

export const discountSchemeFormSteps: MultiStepsHeaderStep[] = [
  {
    label: "Discount scheme",
    index: 1,
  },
  {
    label: "Discount type",
    index: 2,
  },
  {
    label: "Discount application",
    index: 3,
  },
];

const ordinalColumnValue = (
  quantity: number,
  entity: EntityName,
  isLastItem: boolean,
): string => {
  const [entitySingular] = entityTranslations[entity];
  let quantitySuffix = "";

  if (isLastItem) {
    quantitySuffix = "+";
  }

  return `${numberToOrdinal(quantity)}${quantitySuffix} ${entitySingular.toLowerCase()}`;
};

const ordinalBannerTitle = (quantity: number, entity: EntityName): string => {
  const [entitySingular] = entityTranslations[entity];

  return `${numberToOrdinal(quantity)} ${entitySingular.toLowerCase()}`;
};

const getPluralisedLowercaseEntity = (
  quantity: number,
  entity: EntityName,
): string => {
  const [entitySingular, entityPlural] = entityTranslations[entity];

  return quantity > 1
    ? entityPlural.toLowerCase()
    : entitySingular.toLowerCase();
};

const plainBannerTitle = (quantity: number, entity: EntityName): string => {
  const entityLabel = getPluralisedLowercaseEntity(quantity, entity);

  return `${quantity} ${entityLabel}`;
};

const plainColumnValue = (
  quantity: number,
  entity: EntityName,
  isLastItem: boolean,
): string => {
  const entityLabel = getPluralisedLowercaseEntity(
    isLastItem ? 2 : quantity,
    entity,
  );
  let quantitySuffix = "";

  if (isLastItem) {
    quantitySuffix = "or more";
  }

  return `${quantity} ${quantitySuffix} ${entityLabel}`;
};

export const getDiscountSchemeApplicationDetails = (
  discountScheme: DiscountSchemeDto,
): DiscountSchemeApplicationDetailProps => {
  return discountSchemeApplicationDetails[discountScheme.scheme][
    discountScheme.type
  ][discountScheme.application];
};

export type DiscountSchemeApplicationDetailProps = {
  formLabel: string;
  formDescription: string;
  bannerTitle: (quantity: number) => string;
  bannerDescription: (quantity: number) => string;
  firstColumnName: string;
  firstColumnMapper: (
    schemeValue: DiscountSchemeValueDto,
    isLastItem: boolean,
  ) => string;
  detailIcon: IconName;
};

const ApplyToTotalTuition = "Apply to total tuition";

function applyToEachThingIndividually(thing: string) {
  return `Apply to each ${thing} individually`;
}

export const discountSchemeApplicationDetails: Record<
  DiscountSchemeType,
  Record<
    DiscountType,
    Record<DiscountSchemeApplication, DiscountSchemeApplicationDetailProps>
  >
> = {
  // Student Quantity by Family (Multiple Student Discount)
  [DiscountSchemeType.StudentQtyByFamily]: {
    [DiscountType.Percent]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription:
          "For example, a family with 3 students receives 10% off total tuition each billing period.",
        bannerTitle: (quantity: number) =>
          plainBannerTitle(quantity, "student"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a family that has ${quantity} enrolled ${getPluralisedLowercaseEntity(quantity, "student")}.`,
        firstColumnName: "Students per family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "student", isLastItem),
        detailIcon: "peopleOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("student"),
        formDescription:
          "For example, the 2nd student in a family receives 10% off their individual tuition costs each billing period and the 3rd student receives 15% off and so on.",
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "student"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} student in a family that enrolls.`,
        firstColumnName: "Student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "student", isLastItem),
        detailIcon: "personOutline",
      },
    },
    [DiscountType.Fixed]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription: `For example, a family with 3 students receives ${currencyPlaceholder}10 off total tuition each billing period.`,
        bannerTitle: (quantity: number) =>
          plainBannerTitle(quantity, "student"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a family that has ${quantity} enrolled ${getPluralisedLowercaseEntity(quantity, "student")}.`,
        firstColumnName: "Students per family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "student", isLastItem),
        detailIcon: "peopleOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("student"),
        formDescription: `For example, the 2nd student in a family receives ${currencyPlaceholder}10 off their individual tuition costs each billing period and the 3rd student receives ${currencyPlaceholder}15 off and so on.`,
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "student"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} student in a family that enrolls.`,
        firstColumnName: "Student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "student", isLastItem),
        detailIcon: "personOutline",
      },
    },
  },
  // Course Quantity by Student (Multiple class Discount by Student)
  [DiscountSchemeType.CourseQtyByStudent]: {
    [DiscountType.Percent]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription:
          "For example, a student enrolled in 6 classes receives 10% off total tuition each billing period.",
        bannerTitle: (quantity: number) => plainBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a student that is enrolled in ${quantity} ${getPluralisedLowercaseEntity(quantity, "course")}.`,
        firstColumnName: "Class per student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("class"),
        formDescription:
          "For example, the 2nd class a student takes is discounted by 10% each billing period, the 3rd class by 15% and so on.",
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} class a student enrolls for.`,
        firstColumnName: "Class by student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarClearOutline",
      },
    },
    [DiscountType.Fixed]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription: `For example, a student enrolled in 6 classes receives ${currencyPlaceholder}10 off total tuition each billing period.`,
        bannerTitle: (quantity: number) => plainBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a student that is enrolled in ${quantity} ${getPluralisedLowercaseEntity(quantity, "course")}.`,
        firstColumnName: "Classes per student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("class"),
        formDescription: `For example, the 2nd class a student takes is discounted by ${currencyPlaceholder}10 each billing period, the 3rd class by ${currencyPlaceholder}15 and so on.`,
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} class a student enrolls for.`,
        firstColumnName: "Class by student",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarClearOutline",
      },
    },
  },
  // Course Quantity by Family (Multiple class Discount by Family)
  [DiscountSchemeType.CourseQtyByFamily]: {
    [DiscountType.Percent]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription:
          "For example, a family enrolled in 6 classes receives 10% off total tuition each billing period.",
        bannerTitle: (quantity: number) => plainBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a family that is enrolled in ${quantity} ${getPluralisedLowercaseEntity(quantity, "course")}.`,
        firstColumnName: "Classes per family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("class"),
        formDescription:
          "For example, the 2nd class a family takes is discounted by 10% each billing period, the 3rd class by 15% and so on.",
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} class a family enrolls for.`,
        firstColumnName: "Class by family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarClearOutline",
      },
    },
    [DiscountType.Fixed]: {
      [DiscountSchemeApplication.TotalTuition]: {
        formLabel: ApplyToTotalTuition,
        formDescription: `For example, a family enrolled in 6 classes receives ${currencyPlaceholder}10 off total tuition each billing period.`,
        bannerTitle: (quantity: number) => plainBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for a family that is enrolled in ${quantity} ${getPluralisedLowercaseEntity(quantity, "course")}.`,
        firstColumnName: "Classes per family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          plainColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarOutline",
      },
      [DiscountSchemeApplication.IndividualItem]: {
        formLabel: applyToEachThingIndividually("class"),
        formDescription: `For example, the 2nd class a family takes is discounted by ${currencyPlaceholder}10 each billing period, the 3rd class by ${currencyPlaceholder}15 and so on.`,
        bannerTitle: (quantity: number) =>
          ordinalBannerTitle(quantity, "course"),
        bannerDescription: (quantity: number) =>
          `Set a discount for the ${numberToOrdinal(quantity)} class a family enrolls for.`,
        firstColumnName: "Class by family",
        firstColumnMapper: (schemeValue, isLastItem) =>
          ordinalColumnValue(schemeValue.quantity, "course", isLastItem),
        detailIcon: "calendarClearOutline",
      },
    },
  },
};

export const discountSchemeNoContentDescription: Record<
  DiscountSchemeType,
  string
> = {
  [DiscountSchemeType.StudentQtyByFamily]:
    "You can use them to apply discounting rules when a family has additional students.",
  [DiscountSchemeType.CourseQtyByStudent]:
    "You can use them to apply discounting rules when a student is enrolled for additional classes.",
  [DiscountSchemeType.CourseQtyByFamily]:
    "You can use them to apply discounting rules when a student is enrolled for additional classes.",
};

export const discountSchemeDetailPageTitle: Record<DiscountSchemeType, string> =
  {
    [DiscountSchemeType.StudentQtyByFamily]: "Multiple student discount",
    [DiscountSchemeType.CourseQtyByStudent]: "Class discount by student",
    [DiscountSchemeType.CourseQtyByFamily]: "Class discount by family",
  };

export const replaceCurrencySymbol = (text: string, currencySymbol: string) => {
  return text.replaceAll(currencyPlaceholder, currencySymbol);
};
