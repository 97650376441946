import * as ReactMenubar from "@radix-ui/react-menubar";

import { usePermission } from "@shared/components/permission/usePermission";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { ActionMenuItemContainer } from "@/modules/common/ui/actionMenu/ActionMenuItemContainer";
import { ActionMenuDesktopItems } from "@/modules/common/ui/actionMenu/desktop/ActionMenuDesktopItems";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const ActionMenuDefaultItems = ({
  items,
}: {
  items: ActionMenuItemProps[];
}) => {
  const { hasPermission } = usePermission();

  return items.map((item, index) => {
    if (item.group) {
      return (
        <div key={`group-${item.title}`}>
          <div
            className={`px-2 pb-1.5 pt-2 text-base font-semibold text-grey-900`}>
            {item.title}
          </div>

          {/*Recursively render the group items*/}
          <ActionMenuDesktopItems items={item.group.items} />

          {index < items.length - 1 && <HorizontalSeparator spacing={1} />}
        </div>
      );
    }

    return (
      <ProtectedOverlay
        protector={item.protector}
        permission={item.permission}
        key={item.title}>
        <ReactMenubar.Item
          disabled={item.disabled}
          onClick={e => {
            e.stopPropagation();
            if (item.permission && !hasPermission(item.permission)) {
              return;
            }
            item.onClick && item.onClick();
          }}
          className="cursor-pointer p-2 py-3 hover:rounded-sm hover:border-0 hover:bg-grey-100 focus-visible:outline-0">
          {item.children ? (
            item.children
          ) : (
            <ActionMenuItemContainer item={item} />
          )}
        </ReactMenubar.Item>
      </ProtectedOverlay>
    );
  });
};
