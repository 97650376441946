import {
  RegistrationFeeDto,
  RepetitionInterval,
} from "@justraviga/classmanager-sdk";

import { formatMoneyFromInteger } from "./intlFormatter";
import { dayjs } from "./lib/dayjs";

export const intervalMapToLabel = {
  [RepetitionInterval.OneOff]: "One off",
  [RepetitionInterval.Annual]: "Annual",
  [RepetitionInterval.PerSeason]: "Per season",
};

export function getRepetitionIntervalOptions() {
  return Object.values(RepetitionInterval).map(interval => ({
    label: intervalMapToLabel[interval],
    value: interval,
  }));
}

export function monthDayToZeroYearDate(mmdd: string) {
  const [month, day] = mmdd.split("-");
  return dayjs(`0000-${month}-${day}`).format("YYYY-MM-DD");
}

export function zeroYearDateToMonthDay(date: string) {
  return dayjs(date).format("MM-DD");
}

export function registrationFeeMobileRowDetailContent(
  item: RegistrationFeeDto,
) {
  const perStudentValue = formatMoneyFromInteger(item.amount);
  const maxPerFamilyValue = item.maxAmountPerFamily
    ? formatMoneyFromInteger(item.maxAmountPerFamily)
    : "";

  return `${perStudentValue} per student${maxPerFamilyValue ? ` • ${maxPerFamilyValue} max per family` : ""}`;
}
