import { Nav, NavLink } from "@/modules/common/nav/Nav";
import { SubNavTitle } from "@/modules/common/nav/SubNavTitle";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { SeasonsSubNav } from "@/modules/company/SeasonsSubNav";
import { Router } from "@/routing/router";

import { BillingOnlinePaymentsNavItem } from "../common/nav/BillingOnlinePaymentsNavItem";

const topLinks: NavLink[] = [
  {
    path: Router.CompanyHome(),
    label: "Dashboard",
    icon: "gridOutline",
  },
  {
    path: Router.StudentList(),
    label: "Members",
    icon: "peopleOutline",
    subItems: [
      {
        path: Router.StudentList(),
        label: "Students",
      },
      {
        path: Router.FamilyList(),
        label: "Families",
      },
    ],
  },
  {
    path: Router.SeasonList(),
    label: "Class Planner",
    icon: "calendarClearOutline",
    sortSubItems: false,
    subItems: [
      { component: <SubNavTitle title={"My seasons"} /> },
      { component: <SeasonsSubNav /> },
      { component: <HorizontalSeparator spacing={2} /> },
      {
        path: Router.SeasonList(),
        label: "Seasons",
      },
      {
        path: Router.Trials(),
        label: "Trials",
      },
      {
        path: Router.WaitingList(),
        label: "Waiting list",
      },
    ],
  },
  {
    path: Router.Billing(),
    label: "Billing",
    icon: "cardOutline",
    sortSubItems: false,
    subItems: [
      {
        path: Router.DiscountSchemeList(),
        label: "Discount schemes",
      },
      { component: <BillingOnlinePaymentsNavItem /> },
      {
        path: Router.CompanyRegistrationFees(),
        label: "Registration fees",
      },
      {
        path: Router.TransactionList(),
        label: "Transactions",
      },
    ],
  },
  {
    path: Router.StaffList(),
    label: "Company",
    icon: "storefrontOutline",
    subItems: [
      {
        path: Router.StaffList(),
        label: "Staff",
      },
      {
        path: Router.CompanyWaivers(),
        label: "Waivers & policies",
      },
      {
        path: Router.CompanyCustomFields(),
        label: "Custom Fields",
      },
      {
        path: Router.Holidays(),
        label: "Holidays",
      },
      {
        path: Router.LocationList(),
        label: "Locations",
      },
    ],
  },
  {
    path: Router.Emails(),
    label: "Email",
    icon: "mailOutline",
  },
  // @TODO: add back when we have quickbooks integration ready
  // {
  //   path: Router.Integrations(),
  //   label: "Integrations",
  //   icon: "appsOutline",
  // },
];

const sortedTopLinks = [...topLinks].map(link =>
  link.subItems
    ? {
        ...link,
        subItems:
          link.sortSubItems === true || link.sortSubItems === undefined
            ? link.subItems.sort((a, b) =>
                !("component" in a) && !("component" in b)
                  ? a.label?.localeCompare(b.label)
                  : 0,
              )
            : link.subItems,
      }
    : link,
);

const bottomLinks: NavLink[] = [
  {
    path: Router.CompanySettings(),
    label: "Settings",
    icon: "settingsOutline",
  },
];

export const CompanyNav = () => {
  return <Nav topLinks={sortedTopLinks} bottomLinks={bottomLinks} />;
};
