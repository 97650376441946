import { useEffect } from "react";

import type { UseSearchBoxProps } from "react-instantsearch-core";
import { useSearchBox } from "react-instantsearch-core";

import { useSearchDialog } from "shared/lib";

import { SearchInput } from "@/modules/common/form/SearchInput";
import { IconButton } from "@/modules/common/ui/button/IconButton";

const queryHook: UseSearchBoxProps["queryHook"] = (query, search) => {
  search(query);
};

export const GlobalSearchInput: React.FC = () => {
  const { handleOpenChange } = useSearchDialog();
  const { refine, clear } = useSearchBox({
    queryHook,
  });

  useEffect(() => {
    // Autofocus the search input on first render
    const searchInput = document
      .getElementById("global-search-input")
      ?.querySelector("input");

    if (searchInput) {
      searchInput.focus();
    }
  }, []);

  return (
    <div
      id="global-search-input"
      className={`align-center flex w-full items-center justify-between border-b border-grey-400 px-5 py-4`}>
      <div className="grow">
        <SearchInput
          placeholder={"Search"}
          className={"border-0 px-0 py-0"}
          height={`xs`}
          onChange={value => {
            if (!value || value === "") {
              clear();
            } else {
              refine(value);
            }
          }}
          clearBtn="text"
        />
      </div>

      <div className="-mr-2">
        <IconButton
          onClick={() => handleOpenChange(false)}
          data-testid="close-btn"
          variant="standard"
          icon="closeOutline"
          size="sm"
          aria-label="Clear search input"
        />
      </div>
    </div>
  );
};
