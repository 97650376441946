import { isValidElement, useEffect, useState } from "react";

import {
  ActionMenu as NewActionMenu,
  PermissionLayout,
} from "shared/components";
import {
  BaseCardProps,
  cardTextColorMap,
  cn,
  colors,
  usePermission,
} from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export type WebBaseCardProps = BaseCardProps<ActionMenuItemProps>;

// eslint-disable-next-line complexity
export const BaseCard = ({
  border = true,
  borderRadius = "rounded",
  fillColor,
  padding = 4,
  gap = 2,

  collapsed = false,
  collapsible,

  title,
  titleColor = "grey-600",
  titleSize = 14,
  titleWeight = 400,
  titleTruncate = false,

  description,
  descriptionSize = 14,
  descriptionWeight = 400,
  descriptionColor = "grey-600",

  onClick,

  headerAction,
  headerActions,
  headerMenu,
  actions,
  footerAction,

  headerRightSlot,
  leftSlot,
  bodySlot,
  rightSlot,
  footerSlot,

  icon,
  iconColor,
  iconPosition = "top",

  permission,
}: WebBaseCardProps) => {
  if (headerAction && headerActions) {
    throw new Error("Cannot provide both headerAction and headerActions");
  }

  const [isOpen, setIsOpen] = useState(!collapsed);

  // Watch external collapsed state for changes
  useEffect(() => {
    setIsOpen(!collapsed);
  }, [collapsed]);

  const { hasPermission } = usePermission();
  const permitted = permission ? hasPermission(permission) : true;

  const visibilityButton = collapsible ? (
    <div className="-ml-1">
      <IconButton
        data-testid="visibility-button"
        onClick={() => setIsOpen(!isOpen)}
        variant="standard"
        icon={isOpen ? "chevronUp" : "chevronDown"}
        size="xs"
        aria-label={isOpen ? "Hide" : "Show"}
        color={colors.grey[600]}
      />
    </div>
  ) : null;

  const titleIcon =
    icon && iconPosition === "title" ? (
      <div className="flex items-center justify-center">
        <Icon name={icon} size={16} color={colors.grey[600]} />
      </div>
    ) : null;

  const topIcon =
    icon && iconPosition === "top" ? (
      <div className="flex h-8 w-8 items-center justify-center rounded-sm bg-brand-100">
        <Icon name={icon} size={20} color={colors.brand[800]} />
      </div>
    ) : null;

  const titleContent =
    typeof title === "string" ? (
      <div className="flex h-full grow flex-row items-center justify-between gap-x-2">
        {titleIcon}
        <div className="grow">
          <h3
            className={cn("leading-relaxed", {
              "line-clamp-1": titleTruncate,
            })}
            style={{
              fontSize: titleSize,
              fontWeight: titleWeight,
              color: cardTextColorMap[titleColor],
            }}>
            {title}
          </h3>
        </div>
      </div>
    ) : isValidElement(title) ? (
      title
    ) : null;

  const ActionsButton = () => {
    if (!permitted) {
      return null;
    }

    let menu = null;
    if (headerAction) {
      menu = (
        <ProtectedOverlay permission={headerAction.permission}>
          <Button
            onClick={headerAction.onClick}
            variant={"subtle"}
            size={"xs"}
            text={headerAction.text}
          />
        </ProtectedOverlay>
      );
    } else if (headerActions && headerActions.length > 0) {
      menu = (
        <ActionMenu
          trigger={
            <IconButton
              size={"lg"}
              icon={"ellipsisHorizontal"}
              variant={"standard"}
            />
          }
          items={headerActions}
        />
      );
    } else if (headerMenu) {
      menu = headerMenu;
    } else if (actions) {
      menu = (
        <NewActionMenu
          trigger={
            <IconButton
              size={"lg"}
              icon={"ellipsisHorizontal"}
              variant={"standard"}
            />
          }
          {...actions.definition()}
        />
      );
    }

    if (menu) {
      return (
        <div className="flex-initial">
          <div className="-my-2 -mr-2">{menu}</div>
        </div>
      );
    }

    return null;
  };

  const header =
    visibilityButton ||
    topIcon ||
    titleContent ||
    headerRightSlot ||
    ActionsButton() ? (
      <div className="flex grow flex-row gap-x-2">
        {visibilityButton && (
          <div className="flex-initial">{visibilityButton}</div>
        )}
        <div
          className={cn("flex grow flex-col gap-y-1", {
            "cursor-pointer": collapsible,
          })}
          onClick={collapsible ? () => setIsOpen(!isOpen) : undefined}>
          {topIcon && <div className="flex-initial">{topIcon}</div>}
          {titleContent && <div className="grow">{titleContent}</div>}
        </div>
        {headerRightSlot && <div>{headerRightSlot}</div>}

        <ActionsButton />
      </div>
    ) : null;

  const left = isValidElement(leftSlot) ? (
    leftSlot
  ) : icon && iconPosition === "left" ? (
    <div className="flex h-6 w-6 items-center justify-center">
      <Icon name={icon} size={24} color={iconColor ?? colors.brand[800]} />
    </div>
  ) : icon && iconPosition === "left-framed" ? (
    <div className="flex h-12 w-12 min-w-[3rem] items-center justify-center rounded-sm bg-brand-100">
      <Icon name={icon} size={20} color={iconColor ?? colors.brand[800]} />
    </div>
  ) : null;

  const main = (
    <div
      className={cn("flex h-full w-full flex-col", {
        "gap-1": gap === 1,
        "gap-2": gap === 2,
      })}>
      {header}
      {description && isOpen && (
        <div>
          <p
            style={{
              fontSize: descriptionSize,
              fontWeight: descriptionWeight,
              color: cardTextColorMap[descriptionColor],
            }}>
            {description}
          </p>
        </div>
      )}
      {bodySlot && isOpen && (
        <div className="grow">
          <PermissionLayout permission={permission}>
            {bodySlot}
          </PermissionLayout>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cn(
        "flex flex-col justify-between",
        {
          "px-3 py-2": padding === 2,
          "p-4": padding === 4,
        },
        {
          "gap-1": gap === 1,
          "gap-2": gap === 2,
        },
        {
          border: border,
          "border-grey-300": border,
          "bg-white": fillColor === undefined,
          "cursor-pointer": onClick !== undefined,
          "hover:overlay-white-light":
            fillColor !== undefined && onClick !== undefined,
          "hover:bg-grey-100": fillColor === undefined && onClick !== undefined,
        },
        borderRadius === "rounded-md" ? "rounded-md" : "rounded",
      )}
      style={{
        ...(fillColor && { backgroundColor: fillColor }),
      }}
      onClick={onClick}>
      <div className="flex h-full w-full items-stretch gap-x-3">
        {left && isOpen && left}

        <div className="grow">{main}</div>

        {rightSlot && isOpen && <div>{rightSlot}</div>}
      </div>

      {footerSlot && isOpen && <div>{footerSlot}</div>}

      {footerAction && (
        <div>
          <div className="-m-2">
            <ProtectedOverlay permission={footerAction.permission}>
              <Button
                text={footerAction.text}
                variant="tertiaryLight"
                size="xs"
                onClick={footerAction.onClick}
              />
            </ProtectedOverlay>
          </div>
        </div>
      )}
    </div>
  );
};
