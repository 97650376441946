import { ChangeEvent, useRef, useState } from "react";

import { ImagePickerProps, ImagePickerValue } from "shared/lib";

import { uploadFile } from "@/lib/api/uploadFile";
import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const ImagePicker = ({
  description,
  label,
  name,
  onChange,
  value,
}: ImagePickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [localPath, setLocalPath] = useState<ImagePickerValue>(value ?? null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      setLocalPath(URL.createObjectURL(file));

      const path = await uploadFile(file);
      if (onChange) {
        onChange(path);
      }
    }
  };

  const handleUpload = () => {
    fileInputRef.current!.click();
  };

  const removeLogo = () => {
    setLocalPath(null);
    fileInputRef.current!.value = "";
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div className="space-y-4">
      {label && <p className="text-label-400 text-grey-900">{label}</p>}
      {description && <p className="text-xs text-grey-600">{description}</p>}
      {localPath ? (
        <>
          <img
            src={localPath}
            alt="Your logo"
            className="max-h-20 max-w-full overflow-clip rounded"
          />
          <p className="space-x-4">
            <Button variant="secondary" text="Edit" onClick={handleUpload} />
            <Button
              variant="destructive-outline"
              text="Remove"
              onClick={removeLogo}
            />
          </p>
        </>
      ) : (
        <>
          <div
            className={
              "flex w-[100px] justify-center rounded bg-grey-300 py-7"
            }>
            <Icon name="imageOutline" size={20} />
          </div>
          <p>
            <Button
              variant="secondary"
              text="Select photo"
              onClick={handleUpload}
            />
          </p>
        </>
      )}
      <input
        name={name}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
};
