import { useEffect, useState } from "react";

import { match } from "ts-pattern";

import {
  AmountSchema,
  useFormActions,
  usePaymentAmountForm,
} from "shared/components";
import { calculatePaymentBalance, formatMoneyFromInteger } from "shared/lib";

import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { EditPaymentAmountCard } from "@/modules/common/payments/EditPaymentAmountCard";
import { TakePayment } from "@/modules/common/payments/TakePayment";
import { MultiStepsHeader } from "@/modules/common/steps/MultiStepsHeader";

type PaymentFormStepType = 1 | 2;

interface MultistepPaymentFormProps {
  familyId: string;
  balance?: number;
}

export const MultistepPaymentForm = ({
  familyId,
  balance = 0,
}: MultistepPaymentFormProps) => {
  const { setCreateButtonText, setSubmit, setAllowCreateAdditional } =
    useFormActions();
  const [step, setStep] = useState<PaymentFormStepType>(1);
  const [amount, setAmount] = useState<number>(() =>
    calculatePaymentBalance(balance),
  );

  const onStepOneContinue = (data: AmountSchema) => {
    setAmount(data.amount);
    setStep(2);
  };

  const {
    form: paymentAmountForm,
    formHandlers: { handleSubmit },
  } = usePaymentAmountForm(useFormBuilder, onStepOneContinue, {
    amount: calculatePaymentBalance(balance),
  });

  useEffect(() => {
    if (step === 1) {
      setAllowCreateAdditional(false);
      setSubmit(handleSubmit(onStepOneContinue));
      setCreateButtonText("Continue");
    }

    if (step === 2) {
      setCreateButtonText(`Pay ${formatMoneyFromInteger(amount)}`);
    }
  }, [
    step,
    setAllowCreateAdditional,
    setCreateButtonText,
    setSubmit,
    handleSubmit,
    amount,
  ]);

  const form = match(step)
    .with(1, () => paymentAmountForm)
    .with(2, () => (
      <TakePayment
        amount={amount}
        familyId={familyId}
        operationType="capture"
      />
    ))
    .exhaustive();
  return (
    <div className="flex flex-col gap-4">
      <div className="mb-5">
        <MultiStepsHeader
          currentStep={step}
          steps={[
            { index: 1, label: "How much?" },
            { index: 2, label: "Pay by card" },
          ]}
        />
      </div>

      {step === 2 && (
        <div className="mb-5">
          <EditPaymentAmountCard amount={amount} onClick={() => setStep(1)} />
        </div>
      )}
      {form}
    </div>
  );
};
