import {
  AttendanceStatsDto,
  CompanyTrialSettingsDto,
  LessonAdjustmentReason,
  LessonAdjustmentType,
  LessonDto,
  LessonStatus,
  SortSchema,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { useAuthState } from "../auth/useAuthState";
import { getPlatformFunctions } from "../platformSpecific";

export interface LessonRecord {
  lesson: LessonDto;
  isOnHoliday: boolean;
  isCancelled: boolean;
  isAttendanceCompleted: boolean;
  trialsInformation: string | null;
}

export const useClassLessonsData = (courseId: string | null) => {
  const { account } = useAuthState();
  const { useApi } = getPlatformFunctions();

  const { data: lessons, isLoading: isLoadingLessons } = useApi(
    "listLesson",
    {
      where: {
        classId: {
          in: [courseId!],
        },
      },
    },
    {
      enabled: courseId !== null,
    },
  );

  const { data: attendanceStats, isLoading: isLoadingAttendanceStats } = useApi(
    "statsAttendanceStats",
    {
      courseId: courseId!,
    },
    {
      enabled: courseId !== null,
    },
  );

  const { data: trials, isLoading: isLoadingTrials } = useApi(
    "listTrial",
    {
      selectAll: true,
      where: {
        classId: {
          equals: courseId!,
        },
      },
      sort: { trialAt: SortSchema.Desc, time: SortSchema.Desc },
    },
    {
      enabled: courseId !== null,
    },
  );

  const isLoading =
    isLoadingLessons || isLoadingAttendanceStats || isLoadingTrials;

  const records = isLoading
    ? []
    : prepareLessonsRecords({
        lessons: excludeDateChangedLessons(lessons ?? []),
        attendanceStats: attendanceStats,
        trials: trials?.data,
        trialSettings: account?.company?.settings?.trial,
      });

  return {
    isLoading,
    records,
  };
};

const excludeDateChangedLessons = (lessons: LessonDto[]) =>
  lessons.filter(lesson => {
    const adjustments = lesson.adjustments;
    const isDateChanged = adjustments.some(
      adjustment =>
        adjustment.type === LessonAdjustmentType.Deduction &&
        adjustment.reason === LessonAdjustmentReason.ChangedDate,
    );

    return !isDateChanged;
  });

const prepareLessonsRecords = ({
  lessons,
  attendanceStats,
  trials,
  trialSettings,
}: {
  lessons: LessonDto[] | undefined;
  attendanceStats: AttendanceStatsDto[] | undefined;
  trials: TrialDto[] | undefined;
  trialSettings?: CompanyTrialSettingsDto;
}): LessonRecord[] => {
  if (
    lessons === undefined ||
    attendanceStats === undefined ||
    trials === undefined
  ) {
    return [];
  }

  return lessons.map(lesson => {
    const lessonAttendanceStats = attendanceStats.find(
      attendanceStats =>
        attendanceStats.classId === lesson.classId &&
        attendanceStats.date === lesson.date &&
        attendanceStats.time === lesson.startTime,
    );

    const lessonTrials = trials.filter(
      trial => trial.trialAt === lesson.date && trial.time === lesson.startTime,
    );

    const cancellationReasons = [
      LessonAdjustmentReason.CancelledLesson,
      LessonAdjustmentReason.CancelledClass,
    ];

    return {
      lesson,
      isOnHoliday: lesson.holidays.length > 0,
      isCancelled:
        lesson.status == LessonStatus.Inactive &&
        lesson.adjustments.some(adjustment =>
          cancellationReasons.includes(adjustment.reason),
        ),
      trialsInformation: getLessonTrialInformation({
        trialSettings,
        trialsQuantity: lessonTrials.length,
      }),
      isAttendanceCompleted: lessonAttendanceStats?.completePercent === 100,
    };
  });
};

const getLessonTrialInformation = ({
  trialSettings,
  trialsQuantity,
}: {
  trialSettings?: CompanyTrialSettingsDto;
  trialsQuantity: number;
}) => {
  if (!trialSettings || !trialSettings.enableOnPortal) {
    return null;
  }

  if (
    trialSettings.limitedCapacity &&
    trialSettings.maxCapacityPerLesson === trialsQuantity
  ) {
    return "No trial spaces";
  }

  return "Trial spaces available";
};
