import { SettingGroupKey, settingGroups } from "shared/lib";

import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { SettingGroup } from "@/modules/company/settings/components/SettingGroup";
import { useSettingItems } from "@/modules/company/settings/useSettingItems";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const SettingsPage = () => {
  const { getItemsForGroup } = useSettingItems();

  return (
    <CompanyLayout>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Settings"} />
      </CompanyTitleBar>
      <CompanyContent>
        <div className={"grid grid-cols-1 gap-8 md:grid-cols-2"}>
          {Object.entries(settingGroups).map(([groupKey, group]) => (
            <SettingGroup
              key={group.name}
              name={group.name}
              items={getItemsForGroup(groupKey as SettingGroupKey)}
            />
          ))}
        </div>
      </CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "settings:manage",
  SettingsPage,
  "Settings",
);

export { PermissionChecked as SettingsPage };
