import {
  TransactionAmountText,
  useTransactionsDatatable,
} from "shared/components";
import { cn } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const TransactionsListPage = () => {
  const transactionActions = useTransactionActions();
  const { setTitle: setSheetTitle } = useSheet();
  const { datatable, additionalActions } = useTransactionsDatatable({
    Datatable,
    api,
    useApi,
    transactionActions,
    goToFamily: item =>
      Router.push("FamilyDetails", { id: item.transaction.familyId }),
    renderTransactionAmount: (transaction, columnType) =>
      columnType === transaction.transaction.type && (
        <TransactionAmountText transaction={transaction} />
      ),
    setSheetTitle,
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Transactions"} />
        <div
          className={cn("flex space-x-5 md:hidden", {
            "-mx-2": additionalActions?.items,
          })}>
          <IconButton
            icon={"addOutline"}
            border={"rounded"}
            onClick={transactionActions.showCustomTransactionCreateForm}
            variant={"secondary-fill"}
          />

          {/* todo move this inside Datatable: https://class-manager.atlassian.net/browse/CLASS-744*/}
          <div>
            <ActionMenu
              showActionsAsBottomSheetOnMobile={true}
              header={
                <h3 className={"text-heading6-600 text-grey-900"}>
                  {additionalActions.title}
                </h3>
              }
              trigger={
                <IconButton icon={"ellipsisHorizontal"} variant={"standard"} />
              }
              items={additionalActions.items.map(action => ({
                title: action.title,
                protector: action.protector,
                onClick: action.onClick,
                leftIcon: action.icon,
              }))}
            />
          </div>
        </div>
      </CompanyTitleBar>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "financial:view",
  TransactionsListPage,
  "Transactions",
);

export { PermissionChecked as TransactionsListPage };
