import { WaitingListDto } from "@justraviga/classmanager-sdk";

import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  ExtendedWaitingListDto,
  makeWaitingListAddForm,
  makeWaitingListAddRequest,
} from "../formDefinitions/waitingListForm";
import { useGenericComponents } from "../GenericComponentsProvider";

type Model = ExtendedWaitingListDto;

export const useWaitingListActions = () => {
  const { GenericForm } = useGenericComponents();
  const { api, useApi, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<WaitingListDto[]>({
    entity: "waitingList",
  });

  return {
    showCreateForm: ({
      courseId,
      studentId,
    }: { courseId?: string; studentId?: string } = {}) => {
      defaultActions.showCreateForm({
        title: "Add to waiting list",
        form: (
          <GenericForm
            apiRequest={makeWaitingListAddRequest({ api, courseId, studentId })}
            defaultValues={{}}
            formDefinitionHook={makeWaitingListAddForm({
              useApi,
              courseId,
              studentId,
            })}
            onSuccess={defaultActions.makeOnCreateSuccess({
              message: studentId
                ? "Student added to waiting list successfully"
                : "Selected students added to the waiting list successfully.",
            })}
          />
        ),
      });
    },
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(
        () => api.waitingLists.deleteWaitingList({ id: entity.id }),
        {
          confirmButtonText: "Remove",
          title: "Remove from waiting list",
          description: `Are you sure you want to remove ${getFullName(entity.student)} from the waiting list? This action cannot be undone.`,
          confirmButtonStyle: "primary",
          successMessage: "Student removed from waiting list successfully",
        },
      ),

    cancel: (id: string) =>
      defaultActions.deleteOne(
        () => api.waitingLists.deleteWaitingList({ id }),
        {
          confirmButtonText: "Cancel request",
          cancelText: "I do not want to cancel",
          title: "Cancel request",
          description: `Are you sure you want to cancel this request to join the waiting list? This action cannot be undone.`,
          confirmButtonStyle: "primary",
          successMessage: "Waiting list request cancelled",
        },
      ),
  };
};
