import { Fragment } from "react";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { ActionMenuItemContainer } from "@/modules/common/ui/actionMenu/ActionMenuItemContainer";
import { MobileActionMenuItems } from "@/modules/common/ui/actionMenu/mobile/MobileActionMenuItems";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const MobileActionMenuDefaultItems = ({
  items,
  onActionClick,
}: {
  items: ActionMenuItemProps[];
  onActionClick: () => void;
}) => {
  return items.map((item, index) => {
    if (item.group) {
      return (
        <Fragment key={`action-menu-default-group-${item.title}`}>
          {index !== 0 && <HorizontalSeparator spacing={3} />}
          <div>
            <div
              className={`truncate px-2 pb-1.5 pt-2 text-base font-semibold text-grey-900`}>
              {item.title}
            </div>
            <MobileActionMenuItems
              items={item.group.items}
              onActionClick={onActionClick}
            />
          </div>
        </Fragment>
      );
    }

    return (
      <MenuItem
        key={`action-menu-default-${item.title}`}
        item={item}
        onActionClick={onActionClick}
      />
    );
  });
};

const MenuItem = ({
  item,
  onActionClick,
}: {
  item: ActionMenuItemProps;
  onActionClick: () => void;
}) => {
  return (
    <ProtectedOverlay protector={item.protector} permission={item.permission}>
      <div
        key={item.title}
        onClick={e => {
          e.stopPropagation();
          onActionClick();
          item.onClick && item.onClick();
        }}
        className="cursor-pointer p-2 py-3 hover:rounded-sm hover:border-0 hover:bg-grey-100 focus-visible:outline-0">
        {item.children ? (
          item.children
        ) : (
          <ActionMenuItemContainer item={item} />
        )}
      </div>
    </ProtectedOverlay>
  );
};
