import {
  CreateFamilyRequest,
  FamilyUpdateRequest,
  InviteFamilyRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useStripeStatus } from "../../payments/useStripeStatus";
import { useGenericComponents } from "../GenericComponentsProvider";

type CreateSchema = CreateFamilyRequest;
type UpdateSchema = FamilyUpdateRequest;
type InviteSchema = InviteFamilyRequest;

const useFamilyFormDefinition = (mode: "create" | "update") => {
  const { View, Banner } = useGenericComponents();
  const { status } = useStripeStatus();

  const form = new FormDefinitionBuilder<CreateSchema & UpdateSchema>()
    .text("firstname", { label: "First name", required: true })
    .text("lastname", { label: "Last name", required: true })
    .text("email", {
      label: "Email address",
      type: "email",
      required: true,
    })
    .switch("sendInvite", {
      label: "Send invitation to sign up for Class Manager",
      description:
        "Please provide the email address of the primary contact so we can send an invitation.",
    })
    .contentBlock(
      "after",
      "sendInvite",
      status !== "StripeAccountOkay" && (
        <View className={"pb-8"}>
          <Banner
            content={
              "Your members won't be able to use the portal until you have set up our online payment provider."
            }
            variant={"error"}
          />
        </View>
      ),
    )
    .text("phone", {
      label: "Phone number",
      type: "tel",
    });

  if (mode === "update") {
    form.without(["sendInvite"]);
  }

  return form.getDefinition();
};

export const useFamilyInviteFormDefinition = () => {
  return new FormDefinitionBuilder<InviteSchema>()
    .text("firstname", {
      label: "First name",
      required: true,
    })
    .text("lastname", {
      label: "Last name",
      required: true,
    })
    .text("email", {
      label: "Email",
      type: "email",
      required: true,
    })
    .getDefinition();
};

export const useFamilyCreateFormDefinition = () =>
  useFamilyFormDefinition("create") as FormDefinition<CreateSchema>;

export const useFamilyUpdateFormDefinition = () =>
  useFamilyFormDefinition("update") as FormDefinition<UpdateSchema>;

export const makeFamilyCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.families.createFamily({
      createFamilyRequest: formData,
    });
  };

export const makeFamilyUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.families.updateFamily({
      id,
      familyUpdateRequest: formData,
    });
  };

export const makeFamilyInviteRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: InviteSchema) => {
    return api.families.inviteFamily({ id, inviteFamilyRequest: formData });
  };
