import { useEffect, useState } from "react";

import {
  AggregateClassDto,
  FamilyDto,
  PricingScheme,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutLeftColumn } from "./CheckoutLeftColumn";
import { CheckoutRightColumn } from "./CheckoutRightColumn";
import { ItemListBySeason } from "./ItemListBySeason";
import { ItemListWidget } from "./ItemListWidget";
import { ProceedToCheckoutButton } from "./ProceedToCheckoutButton";
import { SpaceNotGuaranteedBanner } from "./SpaceNotGuaranteedBanner";
import { SummaryPageHeader } from "./SummaryPageHeader";
import { showAlert } from "../../../../alertState";
import {
  eligibleFamilyMembersForCourse,
  getQuoteFn,
  validateBasketItem,
} from "../../../../basket/basketUtils";
import { useCatalogueData } from "../../../../data/useCatalogueData";
import { useAuthenticatedAccountEntity } from "../../../../hooks/useAuthenticatedAccountEntity";
import {
  formatDate,
  formatMoneyFromInteger,
  formatTime,
} from "../../../../intlFormatter";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { enumLabel } from "../../../../translateUtils";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useAuthState } from "../../../AuthStateProvider";
import { useBasket } from "../../../basket/useBasket";
import { useCatalogueContext } from "../../../catalogue/useCatalogueContext";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  BannerComponent,
  ButtonComponent,
  HorizontalSeparatorComponent,
  IconButtonComponent,
  IconComponent,
  MultiSelectComponent,
} from "../../../interfaces";
import { Chip } from "../../../ui/Chip";
import { ContentPlaceholder } from "../../../ui/ContentPlaceholder";

export type BasketItem = EnrolmentItem | TrialItem;

export type EnrolmentItem = {
  type: "enrolment";
  course: AggregateClassDto;
  season: SeasonDto;
  trial?: never;
  students: StudentDto[];
};
export type TrialItem = {
  type: "trial";
  course: AggregateClassDto;
  season: SeasonDto;
  trial: {
    date: string;
    time: string;
  };
  students: StudentDto[];
};

export interface MemberBasketPageProps {
  Banner: BannerComponent;
  Button: ButtonComponent;
  HorizontalSeparator: HorizontalSeparatorComponent;
  IconButton: IconButtonComponent;
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  goToCheckout: () => void;
  Icon: IconComponent;
  goToCourse: (courseId: string) => void;
  familyMembers: StudentDto[];
}

export const MemberBasketPage = ({
  Banner,
  Button,
  HorizontalSeparator,
  IconButton,
  MultiSelect,
  useStudentActions,
  goToCheckout,
  Icon,
  goToCourse,
  familyMembers,
}: MemberBasketPageProps) => {
  const family = useAuthenticatedAccountEntity() as FamilyDto;
  const { Pressable, Text, View } = useGenericComponents();
  const { useApi, api } = getPlatformFunctions();
  const { contents, getQuote, addStudentToEnrolment, addStudentToTrial } =
    useBasket();
  const data = useCatalogueData(useApi, true);
  const { showPortalCreateForm: showCreateStudentForm } = useStudentActions();
  const [shouldValidate, setShouldValidate] = useState(false);
  const { company } = useCatalogueContext();
  const { isLoggedIn, account } = useAuthState();
  const [hasSetFamilyMember, setHasSetFamilyMember] = useState(false);

  const { data: enrolments, isLoading: isEnrolmentsLoading } = useApi(
    "listEnrolment",
    {
      selectAll: true,
    },
    {
      enabled: isLoggedIn,
    },
  );

  const isLoading = data.isLoading || isEnrolmentsLoading;

  useEffect(() => {
    if (!hasSetFamilyMember && !isLoading) {
      contents.enrolments.forEach((studentIds, courseId) => {
        if (studentIds.length > 0) {
          return;
        }
        const eligibleFamilyMembersOnCourse = eligibleFamilyMembersForCourse({
          courseId,
          courses: data.courses!,
          seasons: data.seasons!,
          company: company,
          familyMembers: familyMembers,
          enrolments: enrolments?.data,
        });
        if ((eligibleFamilyMembersOnCourse?.length ?? 0) === 1) {
          addStudentToEnrolment(courseId, eligibleFamilyMembersOnCourse![0].id);
        }
      });
      contents.trials.forEach((studentIds, trial) => {
        if (studentIds.length > 0) {
          return;
        }
        const [courseId] = trial.split("|");
        const eligibleFamilyMembersOnCourse = eligibleFamilyMembersForCourse({
          courseId,
          courses: data.courses!,
          seasons: data.seasons!,
          company: company,
          familyMembers: familyMembers,
          enrolments: enrolments?.data,
        });
        if ((eligibleFamilyMembersOnCourse?.length ?? 0) === 1) {
          addStudentToTrial(trial, eligibleFamilyMembersOnCourse![0].id);
        }
      });
      setHasSetFamilyMember(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents.enrolments, contents.trials, isLoading]);

  if (isLoading) {
    return (
      <View>
        <Text>Loading....</Text>
      </View>
    );
  }

  function getCourseById(id: string): AggregateClassDto {
    return data.courses!.find(course => course.entity.id === id)!;
  }

  function getSeasonById(id: string): SeasonDto {
    return data.seasons!.find(season => season.id === id)!;
  }

  function getStudentById(id: string): StudentDto {
    return familyMembers.find(student => student.id === id)!;
  }

  function getSeasonsFromBasketType(
    basketContents: Map<string, string[]>,
  ): SeasonDto[] {
    const seasonIds = new Set(
      [...basketContents].map(
        ([courseId]) => getCourseById(courseId).entity.seasonId,
      ),
    );
    return data.seasons!.filter(season => seasonIds.has(season.id));
  }

  const basketItemsForEnrolments: EnrolmentItem[] = [
    ...[...contents.enrolments].map<EnrolmentItem>(
      ([courseId, studentIdList]) => {
        const course = getCourseById(courseId);
        return {
          type: "enrolment",
          course,
          season: getSeasonById(course.entity.seasonId),
          // hypothetically we could have a studentIdList that contains a studentId that doesn't exist in the familyMembers list
          students: studentIdList
            .map(studentId => getStudentById(studentId))
            .filter(student => student),
        };
      },
    ),
  ];

  const basketItemsForTrials: TrialItem[] = [
    ...[...contents.trials].map<TrialItem>(([trialId, studentIdList]) => {
      const [courseId, trialDate, trialTime] = trialId.split("|");
      const course = getCourseById(courseId);
      return {
        type: "trial",
        course,
        season: getSeasonById(course.entity.seasonId),
        trial: {
          date: trialDate,
          time: trialTime,
        },
        // hypothetically we could have a studentIdList that contains a studentId that doesn't exist in the familyMembers list
        students: studentIdList
          .map(studentId => getStudentById(studentId))
          .filter(student => student),
      };
    }),
  ];

  function validateBasketAndGoToCheckout() {
    for (const { course, students, season } of basketItemsForEnrolments) {
      if (
        !validateBasketItem(
          course,
          season,
          students,
          enrolments?.data ?? [],
          company,
          true,
        ).valid
      ) {
        showAlert({
          content:
            "Some items in your basket have issues. Resolve them before continuing to checkout.",
          variant: "error",
        });
        setShouldValidate(true);
        return;
      }
    }
    for (const { course, students, season } of basketItemsForTrials) {
      if (
        !validateBasketItem(
          course,
          season,
          students,
          enrolments?.data ?? [],
          company,
          true,
        ).valid
      ) {
        showAlert({
          content:
            "Some items in your basket have issues. Resolve them before continuing to checkout.",
          variant: "error",
        });
        setShouldValidate(true);
        return;
      }
    }
    getQuote(getQuoteFn(api, account!.entityId!))
      .then(() => {
        goToCheckout();
      })
      .catch(e => {
        e.messages?.length > 0 &&
          showAlert({ variant: "error", content: e.messages[0] });
      });
  }

  const seasonsForEnrolments = getSeasonsFromBasketType(
    contents.enrolments,
  ).map(season => ({ ...season, name: season.name + " enrollments" }));

  return (
    <CheckoutLayout>
      <CheckoutLeftColumn>
        <View>
          <SummaryPageHeader
            Button={Button}
            showCreateForm={() => showCreateStudentForm(family)}
            title={"Select students"}
          />
        </View>
        <View>
          <SpaceNotGuaranteedBanner Banner={Banner} />
        </View>

        {basketItemsForTrials.length === 0 &&
        basketItemsForEnrolments.length === 0 ? (
          <View>
            <ContentPlaceholder
              icon={"helpCircleOutline"}
              title={"There's nothing in your basket"}
            />
          </View>
        ) : null}

        <View>
          <ItemListBySeason
            seasons={seasonsForEnrolments}
            basketItems={basketItemsForEnrolments}
            familyMembers={familyMembers}
            enrolments={enrolments?.data ?? []}
            shouldValidate={shouldValidate}
            Banner={Banner}
            IconButton={IconButton}
            MultiSelect={MultiSelect}
            useStudentActions={useStudentActions}
            HorizontalSeparator={HorizontalSeparator}
            Icon={Icon}
            itemTitle={({ course }) => (
              <Text>
                {enumLabel("weekDaysShort", course.entity.dayOfWeek)}
                {" • "}
                {formatTime(course.entity.startTime, "hourMinute")}
              </Text>
            )}
            itemSubtitle={({ course, season }) =>
              season.pricingScheme === PricingScheme.None ? (
                <Text>
                  {formatMoneyFromInteger(course.entity.price!)}/month
                </Text>
              ) : (
                <Pressable onClick={() => goToCourse(course.entity.id)}>
                  <Text>See pricing scheme</Text>
                </Pressable>
              )
            }
          />
        </View>

        {basketItemsForTrials.length > 0 && (
          <View>
            <ItemListWidget
              basketItems={basketItemsForTrials}
              header={
                <Text className={"text-heading5-600 text-grey-900"}>
                  Trials
                </Text>
              }
              Banner={Banner}
              IconButton={IconButton}
              MultiSelect={MultiSelect}
              useStudentActions={useStudentActions}
              HorizontalSeparator={HorizontalSeparator}
              shouldValidate={shouldValidate}
              Icon={Icon}
              enrolments={enrolments!.data}
              familyMembers={familyMembers}
              itemTitle={({ course, trial }) => (
                <Text>
                  {enumLabel("weekDaysShort", course.entity.dayOfWeek)}
                  {" • "}
                  {formatDate(trial!.date, "dayMonthYear")}
                  {" • "}
                  {formatTime(trial!.time, "hourMinute")}
                </Text>
              )}
              itemSubtitle={({ course }) => (
                <View className={"flex gap-2"}>
                  {company.settings.trial.price ? (
                    <Text>
                      {formatMoneyFromInteger(company.settings.trial.price)}
                      /lesson
                    </Text>
                  ) : (
                    <Pressable onClick={() => goToCourse(course.entity.id)}>
                      <Text>See pricing scheme</Text>
                    </Pressable>
                  )}
                  <Chip label={"Trial"} variant={"neutral"} size={"sm"} />
                </View>
              )}
            />
          </View>
        )}
      </CheckoutLeftColumn>
      <CheckoutRightColumn>
        <View className={"w-full md:hidden mt-8"}>
          <HorizontalSeparator spacing={4} />
        </View>
        <View>
          <ProceedToCheckoutButton
            Button={Button}
            validateBasketAndGoToCheckout={validateBasketAndGoToCheckout}
            disabled={
              basketItemsForTrials.length === 0 &&
              basketItemsForEnrolments.length === 0
            }
          />
        </View>
      </CheckoutRightColumn>
    </CheckoutLayout>
  );
};
