import { Breadcrumbs, BreadcrumbsProps } from "shared/components";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";

const options: Array<Option<BreadcrumbsProps>> = [
  {
    title: "Basic functionality",
    props: [
      {
        items: [
          {
            text: "Home",
            onClick: () => {},
          },
          {
            text: "Area",
            onClick: () => {},
          },
          {
            text: "Page",
          },
        ],
      },
    ],
  },
];

export const BreadcrumbPage = () => {
  return (
    <DocWrapper
      title={"Breadcrumbs"}
      component={Breadcrumbs}
      options={options}
    />
  );
};
