import { StatCountCard } from "./StatCountCard";
import { dateSubtract, dateToday } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";

const TotalStudentsWidget = () => {
  const { useApi } = getPlatformFunctions();

  const { data: total, isLoading: isLoadingTotal } = useApi(
    "countStudent",
    {},
    {},
  );

  const { data: recent, isLoading: isLoadingRecent } = useApi("countStudent", {
    where: {
      createdAt: {
        greaterThanOrEqual: dateSubtract(dateToday(), 28, "day"),
      },
    },
  });

  const isLoading = isLoadingTotal || isLoadingRecent;

  return (
    <StatCountCard
      isLoading={isLoading}
      title={"Total students"}
      totalCount={total?.count ?? 0}
      recentCount={recent?.count ?? 0}
    />
  );
};

const PermissionChecked = withPermissionCheck(
  "financial:view",
  TotalStudentsWidget,
);

export { PermissionChecked as TotalStudentsWidget };
