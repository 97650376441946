import {
  ActivityLogsApi,
  AddressesApi,
  AgreementsApi,
  AttendancesApi,
  AttendanceStatsApi,
  AttendeesApi,
  AuthApi,
  AvatarsApi,
  BalanceApi,
  BasketApi,
  BillApi,
  CompaniesApi,
  ContactsApi,
  CoursesApi,
  CustomFieldsApi,
  DiscountSchemesApi,
  DiscountSchemeValuesApi,
  EnrolmentAdjustmentsApi,
  EnrolmentsApi,
  FamiliesApi,
  FilesApi,
  HolidaysApi,
  LessonAdjustmentsApi,
  LessonsApi,
  LinkApi,
  LocationsApi,
  MessageInstancesApi,
  MessagesApi,
  PayApi,
  PricingSchemeValuesApi,
  QuickbooksApi,
  RegistrationFeesApi,
  RoomsApi,
  SearchApi,
  SeasonsApi,
  StaffApi,
  StripeApi,
  StudentsApi,
  TaxRatesApi,
  TransactionsApi,
  TrialAvailabilitiesApi,
  TrialsApi,
  UsersApi,
  WaitingListsApi,
  WaiversApi,
} from "@justraviga/classmanager-sdk";

import { configureApi, Middleware } from "./sdkConfig";
import {
  addAccountHeader,
  addAuthHeader,
  logoutIfNotAuthed,
  noCookies,
  showGenericApiErrors,
} from "./sdkMiddleware";
import { AuthStateInterface } from "../authState";

/**
 * The main API object that we'll use to make requests to the API.
 * e.g. `api.auth.login()`
 */
export const makeApi = ({
  authState,
  basePath,
  onLogout,
}: {
  authState: AuthStateInterface;
  basePath: string;
  onLogout: () => void;
}) => {
  const middleware: Middleware = {
    pre: [noCookies(), addAuthHeader(authState), addAccountHeader(authState)],
    post: [],
    onError: [logoutIfNotAuthed(authState, onLogout), showGenericApiErrors()],
  };

  const make = configureApi(basePath, middleware);

  return {
    activityLog: make(ActivityLogsApi),
    addresses: make(AddressesApi),
    agreements: make(AgreementsApi),
    attendances: make(AttendancesApi),
    attendanceStats: make(AttendanceStatsApi),
    attendees: make(AttendeesApi),
    auth: make(AuthApi),
    avatars: make(AvatarsApi),
    balances: make(BalanceApi),
    basket: make(BasketApi),
    bills: make(BillApi),
    companies: make(CompaniesApi),
    contacts: make(ContactsApi),
    courses: make(CoursesApi),
    customFields: make(CustomFieldsApi),
    discountSchemes: make(DiscountSchemesApi),
    discountSchemeValues: make(DiscountSchemeValuesApi),
    enrolmentAdjustments: make(EnrolmentAdjustmentsApi),
    enrolments: make(EnrolmentsApi),
    families: make(FamiliesApi),
    files: make(FilesApi),
    holidays: make(HolidaysApi),
    lessonAdjustments: make(LessonAdjustmentsApi),
    lessons: make(LessonsApi),
    links: make(LinkApi),
    locations: make(LocationsApi),
    messageInstances: make(MessageInstancesApi),
    messages: make(MessagesApi),
    pay: make(PayApi),
    pricingSchemeValues: make(PricingSchemeValuesApi),
    quickbooks: make(QuickbooksApi),
    registrationFees: make(RegistrationFeesApi),
    rooms: make(RoomsApi),
    search: make(SearchApi),
    seasons: make(SeasonsApi),
    staff: make(StaffApi),
    stripe: make(StripeApi),
    students: make(StudentsApi),
    taxRates: make(TaxRatesApi),
    transactions: make(TransactionsApi),
    trialAvailabilities: make(TrialAvailabilitiesApi),
    trials: make(TrialsApi),
    users: make(UsersApi),
    waitingLists: make(WaitingListsApi),
    waivers: make(WaiversApi),
  };
};
