import { MultiStepsHeaderProps } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { CircularIndicator } from "@/modules/common/ui/CircularIndicator";

export const MultiStepsHeader = ({
  currentStep,
  steps,
}: MultiStepsHeaderProps) => {
  const step = steps.find(step => step.index === currentStep);
  const totalSteps = steps.length;
  return (
    <div className="flex h-20 flex-row items-center justify-between rounded-md bg-grey-200 p-4">
      <div className="flex flex-row items-center gap-2">
        {step?.goBack && (
          <IconButton
            variant="secondary-fill"
            icon="chevronBackOutline"
            onClick={step.goBack}
          />
        )}
        <span className="text-body-600 font-semibold">{step?.label}</span>
      </div>
      <CircularIndicator
        className={"h-12 w-12"}
        value={currentStep}
        maxValue={totalSteps}
      />
    </div>
  );
};
