import { FC, ReactNode } from "react";

import { EntityName } from "@justraviga/classmanager-sdk";

import { useFormActions } from "./FormActionsProvider";
import { AlertProps } from "../alertState";
import { entityTranslations } from "../translateUtils";

/**
 * When our entity actions hooks are created, they should implement one or more
 * of the following functions, with the given signatures.
 */
export interface EntityActions<Model extends object> {
  // TODO: come back and figure out how to type these, but for now they all work as intended
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archive: (entity: Model, args?: any) => Promise<Model | void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archiveMany: (ids: string[], args?: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteMany: (ids: string[], args?: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteOne: (entity: Model, args?: any) => Promise<void>;
  showCreateForm: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args?: any,
    defaultValues?: Partial<Model>,
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showUpdateForm: (entity: Model, args?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unarchive: (entity: Model, args?: any) => Promise<Model>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unarchiveMany: (ids: string[], args?: any) => Promise<void>;
}

export type DefaultEntityActions<T extends object> = ReturnType<
  typeof useDefaultEntityActions<T>
>;

export type ConfirmationDialogProps = (options: {
  title: string;
  description: string;
  cancelText?: string;
  confirmButtonText: string;
  confirmButtonStyle?: "primary" | "destructive";
}) => Promise<boolean>;

export const useDefaultEntityActions = <Model extends object>({
  entity,
  showAlert,
  showConfirmationDialog,
  showForm,
  hideForm,
  CreateFooter,
  UpdateFooter,
}: {
  entity: EntityName;
  showAlert: (options: AlertProps) => void;
  showConfirmationDialog: ConfirmationDialogProps;
  showForm: (options: {
    title: string;
    content: ReactNode;
    footer: ReactNode | null;
  }) => void;
  hideForm: () => void;
  CreateFooter: FC;
  UpdateFooter: FC;
}) => {
  const { shouldCloseOnSuccess } = useFormActions();
  const { setShouldShowFooter, setAllowCreateAdditional } = useFormActions();
  const [entitySingular, entityPlural] = entityTranslations[entity];

  return {
    showForm,
    hideForm,
    showCreateForm({
      form,
      footer,
      title,
    }: {
      form: ReactNode;
      footer?: ReactNode;
      title?: string;
    }) {
      setShouldShowFooter(true);
      setAllowCreateAdditional(true);
      showForm({
        title: title ?? `Create ${entitySingular.toLowerCase()}`,
        content: form,
        footer: footer ? footer : <CreateFooter />,
      });
    },

    onCreateSuccess: () => {
      showAlert({
        content: `${entitySingular} created successfully`,
      });
      if (shouldCloseOnSuccess()) {
        hideForm();
      }
    },

    makeOnCreateSuccess:
      ({
        message,
        redirect,
      }: {
        message?: string;
        redirect?: (item: Model) => void;
      }) =>
      (entity: Model) => {
        showAlert({
          content: message ?? `${entitySingular} created successfully`,
        });
        if (shouldCloseOnSuccess()) {
          // Redirect if they're *not* creating another record
          if (redirect) {
            redirect(entity);
          }
          hideForm();
        }
      },

    showUpdateForm({
      form,
      footer,
      usePlural,
      title,
    }: {
      form: ReactNode;
      footer?: ReactNode;
      usePlural?: boolean;
      title?: string;
    }) {
      setShouldShowFooter(true);
      showForm({
        title:
          title ??
          `Edit ${usePlural ? entityPlural.toLowerCase() : entitySingular.toLowerCase()}`,
        content: form,
        footer: footer || <UpdateFooter />,
      });
    },

    onUpdateSuccess: (message?: string | object) => {
      showAlert({
        content:
          typeof message === "string"
            ? message
            : `${entitySingular} updated successfully`,
      });
      if (shouldCloseOnSuccess()) {
        hideForm();
      }
    },

    deleteOne(
      request: () => Promise<void>,
      params?: {
        title?: string;
        confirmButtonText?: string;
        cancelText?: string;
        description?: string;
        confirmButtonStyle?: "primary" | "destructive";
        successMessage?: string;
      },
    ) {
      return new Promise<void>((resolve, reject) => {
        showConfirmationDialog({
          title: params?.title ?? `Delete ${entitySingular.toLowerCase()}`,
          description:
            params?.description ??
            `Are you sure you want to permanently delete this ${entitySingular.toLowerCase()}? This cannot be undone.`,
          confirmButtonText: params?.confirmButtonText ?? "Delete",
          confirmButtonStyle: params?.confirmButtonStyle,
          cancelText: params?.cancelText ?? "Cancel",
        }).then(result => {
          if (!result) {
            return reject(undefined);
          }
          request().then(() => {
            showAlert({
              content:
                params?.successMessage ??
                `${entitySingular} deleted successfully`,
            });
            return resolve(undefined);
          });
        });
      });
    },

    deleteMany(
      request: () => Promise<void>,
      count: number,
      {
        title,
        description,
        confirmButtonText,
        successMessage,
      }: {
        title?: string;
        description?: string;
        confirmButtonText?: string;
        successMessage?: string;
      } = {},
    ) {
      return new Promise<void>(resolve => {
        showConfirmationDialog({
          title:
            title ??
            `Delete ${count} ${count > 1 ? entityPlural.toLowerCase() : entitySingular.toLowerCase()}`,
          description:
            description ??
            `Are you sure you want to permanently delete the selected ${entityPlural.toLowerCase()}? This cannot be undone.`,
          confirmButtonText: confirmButtonText ?? "Delete",
        }).then(result => {
          if (!result) {
            return resolve(undefined);
          }
          request().then(() => {
            showAlert({
              content: successMessage ?? `${entityPlural} deleted successfully`,
            });
            return resolve(undefined);
          });
        });
      });
    },

    archive(
      request: () => Promise<Model>,
      {
        confirmButtonText,
        description,
        extendedDescription,
        successMessage,
        title,
      }: {
        confirmButtonText?: string;
        description?: string;
        extendedDescription?: string;
        successMessage?: string;
        title?: string;
      } = {},
    ) {
      return new Promise<Model | void>(resolve => {
        showConfirmationDialog({
          title: title ?? `Archive ${entitySingular.toLowerCase()}`,
          description:
            description ??
            `Are you sure you want to archive this ${entitySingular.toLowerCase()}? ${extendedDescription ? extendedDescription : ""}`,
          confirmButtonText: confirmButtonText ?? "Archive",
          confirmButtonStyle: "primary",
        }).then(result => {
          if (!result) {
            return resolve(undefined);
          }
          request().then(record => {
            showAlert({
              content:
                successMessage ?? `${entitySingular} archived successfully`,
            });
            return resolve(record);
          });
        });
      });
    },

    archiveMany(
      request: () => Promise<void>,
      count: number,
      { extendedDescription }: { extendedDescription?: string } = {},
    ) {
      return new Promise<void>(resolve => {
        showConfirmationDialog({
          title: `Archive ${count} ${count > 1 ? entityPlural.toLowerCase() : entitySingular.toLowerCase()}`,
          description: `Are you sure you want to archive the selected ${entityPlural.toLowerCase()}? ${extendedDescription ? extendedDescription : ""}`,
          confirmButtonText: "Archive",
        }).then(result => {
          if (!result) {
            return resolve(undefined);
          }
          request().then(() => {
            showAlert({
              content: `${entityPlural} archived successfully`,
            });
            return resolve(undefined);
          });
        });
      });
    },

    unarchive(request: () => Promise<Model>) {
      return new Promise<Model>(resolve => {
        request().then(record => {
          showAlert({
            content: `${entitySingular} restored successfully`,
          });
          return resolve(record);
        });
      });
    },

    unarchiveMany(request: () => Promise<void>) {
      return new Promise<void>(resolve => {
        request().then(() => {
          showAlert({
            content: `${entityPlural} restored successfully`,
          });
          return resolve(undefined);
        });
      });
    },
  };
};
