import { LocationDto, RoomDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeRoomCreateRequest,
  makeRoomUpdateRequest,
  useRoomFormDefinition,
} from "../formDefinitions/roomForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";

type Model = RoomDto;

type ImplementedActions = Omit<
  EntityActions<Model>,
  "archive" | "unarchive" | "deleteMany" | "archiveMany" | "unarchiveMany"
>;

export const useRoomActions = (): ImplementedActions => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<LocationDto>({
    entity: "room",
  });

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() => api.rooms.deleteRoom({ id: entity.id })),

    showCreateForm: ({ locationId }: { locationId: string }) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeRoomCreateRequest({ api, locationId })}
            defaultValues={{}}
            formDefinitionHook={useRoomFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model, { locationId }: { locationId: string }) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeRoomUpdateRequest({
              api,
              id: entity.id,
              locationId,
            })}
            defaultValues={entity}
            formDefinitionHook={useRoomFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
