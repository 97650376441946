import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { AccountRole } from "@justraviga/classmanager-sdk";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

import { useAuthState } from "./AuthStateProvider";
import { Api } from "../api";
import { getPlatformFunctions } from "../platformSpecific";
import {
  buildSearchClientConfig,
  BuildSearchClientConfigParams,
  SearchClientBuilder,
} from "../search/searchClientBuilder";
import {
  SearchClientContext,
  SearchDialogContext,
  SearchStatusContext,
} from "../search/searchContext";
import { searchKeyLoader } from "../search/searchKeyLoader";
import { GlobalSearchStatus } from "../search/searchTypes";

interface SearchProviderProps extends PropsWithChildren {
  api: Api;
  config: BuildSearchClientConfigParams;
  children: ReactNode;
}

export const SearchProvider = ({
  api,
  config,
  children,
}: SearchProviderProps) => {
  const { logger } = getPlatformFunctions();
  const { account } = useAuthState();
  const [searchClient, setSearchClient] =
    useState<TypesenseInstantsearchAdapter | null>(null);
  const [status, setStatus] = useState<GlobalSearchStatus>(
    GlobalSearchStatus.PENDING,
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchClientBuilder] = useState(
    () =>
      new SearchClientBuilder(
        searchKeyLoader({ api }),
        buildSearchClientConfig(config),
        logger,
      ),
  );

  const resetClient = useCallback(async () => {
    searchClientBuilder
      .resetKey()
      .then(newClient => {
        if (newClient) {
          setStatus(GlobalSearchStatus.OK);
          setSearchClient(newClient);
        } else {
          setStatus(GlobalSearchStatus.ERROR);
        }
      })
      .catch(() => {
        setStatus(GlobalSearchStatus.ERROR);
      });
  }, [searchClientBuilder]);

  const openSearch = () => setIsSearchOpen(true);
  const closeSearch = () => {
    setIsSearchOpen(false);
    if (status === GlobalSearchStatus.LOADING) {
      setStatus(GlobalSearchStatus.OK);
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      closeSearch();
    }

    if (isOpen) {
      if (status !== GlobalSearchStatus.OK) {
        resetClient();
      }
      openSearch();
    }
  };

  useEffect(() => {
    if (account?.id && account.role === AccountRole.Staff) {
      resetClient();
    } else {
      setStatus(GlobalSearchStatus.HIDDEN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.id, account?.role]);

  return (
    <SearchStatusContext.Provider value={{ status }}>
      <SearchDialogContext.Provider value={{ isSearchOpen, handleOpenChange }}>
        <SearchClientContext.Provider value={{ searchClient }}>
          {children}
        </SearchClientContext.Provider>
      </SearchDialogContext.Provider>
    </SearchStatusContext.Provider>
  );
};
