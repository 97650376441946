import type { Hit } from "instantsearch.js";
import { match } from "ts-pattern";

import {
  colors,
  CourseDocument,
  findHighlightedAttributes,
  formatTime,
  SearchCollection,
} from "shared/lib";

import { SearchResult } from "@/modules/common/search/searchResults/SearchResult";
import { StyledHighlight } from "@/modules/common/search/StyledHighlight";
import { Icon } from "@/modules/common/ui/icon/Icon";

interface CourseSearchResultProps {
  hit: Hit<CourseDocument>;
}

const CourseDescription = ({ hit: course }: CourseSearchResultProps) => {
  const highlightedAttributes = findHighlightedAttributes<CourseDocument>(
    ["season", "location", "teacher", "room"],
    course,
  );

  const dayAbbreviation =
    course.dayOfWeek.slice(0, 3).charAt(0).toUpperCase() +
    course.dayOfWeek.slice(1, 3);
  const dayAndTime = `${dayAbbreviation} • ${formatTime(course.startTime, "hourMinute")}`;

  const defaultDescription = (
    <>
      {dayAndTime}
      {course.season && (
        <span>
          {" • "}
          <StyledHighlight attribute="season" hit={course} />
        </span>
      )}
    </>
  );

  return match(highlightedAttributes)
    .with({ season: true }, () => defaultDescription)
    .with({ location: true }, { room: true }, () => (
      <>
        <StyledHighlight attribute="location" hit={course} />
        {course.room && (
          <>
            {" • "}
            <StyledHighlight attribute="room" hit={course} />
          </>
        )}
      </>
    ))
    .with({ teacher: true }, () => (
      <StyledHighlight attribute="teacher" hit={course} />
    ))
    .otherwise(() => defaultDescription);
};

export const CourseSearchResult = ({
  hit: course,
}: CourseSearchResultProps) => {
  return (
    <SearchResult
      type={SearchCollection.course}
      title={<StyledHighlight attribute="name" hit={course} />}
      description={<CourseDescription hit={course} />} // Tue • 3:00pm
      id={course.id}>
      <div className={"rounded-full bg-brand-100 p-2"}>
        <Icon size={20} name={"calendarNumber"} color={colors.brand[800]} />
      </div>
    </SearchResult>
  );
};
