import {
  Currency,
  Locale,
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { enumLabel } from "../../../translateUtils";

type UpdateGeneral = Required<UpdateCompanySettingsRequest>["billing"] &
  Required<UpdateCompanySettingsRequest>["general"];

export const useLanguageAndCurrencyFormDefinition = () =>
  //   TODO: can maybe add a mode to conditionally show fields, in order to re-use this form definition for different forms on general setting
  {
    const form = new FormDefinitionBuilder<UpdateGeneral>()
      .select("locale", {
        label: "Language",
        data: Object.entries(Locale)
          .map(([_, value]) => ({
            value: value,
            label: enumLabel("locales", value),
          }))
          // Because of limits in our database, we have to keep old values for a while
          .filter(({ label }) => !label.startsWith("Old:")),
        required: true,
      })
      .select("currency", {
        label: "Currency",
        data: Object.entries(Currency).map(([_, value]) => ({
          value: value,
          label: enumLabel("currencies", value),
        })),
        required: true,
      });
    return form.getDefinition();
  };

export const makeLanguageAndCurrencyFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  (formData: UpdateGeneral) => {
    return api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          general: {
            locale: formData.locale,
          },
          billing: {
            currency: formData.currency,
          },
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });
        return company;
      });
  };
