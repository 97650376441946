type DateFormat =
  | "weekdayDayMonthYear"
  | "weekdayShortDayMonthYear"
  | "dayMonthYear"
  | "dayMonth"
  | "month"
  | "monthLong"
  | "monthYear"
  | "isoDate"
  | "year";
type TimeFormat = "hourMinute";
type DateTimeFormat =
  | DateFormat
  | TimeFormat
  | "dayMonthYearHourMinute"
  | "isoDate"
  | "dayMonthNumericYearHourMinute";

const dateTimeFormats: Record<
  DateTimeFormat,
  Parameters<typeof Intl.DateTimeFormat>[1]
> = {
  hourMinute: {
    hour: "numeric",
    minute: "numeric",
  },
  weekdayShortDayMonthYear: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  },
  weekdayDayMonthYear: {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "2-digit",
  },
  dayMonthYear: { year: "numeric", month: "short", day: "2-digit" },
  dayMonth: { month: "short", day: "2-digit" },
  month: { month: "short" },
  monthLong: { month: "long" },
  monthYear: { month: "short", year: "numeric" },
  isoDate: { year: "numeric", month: "2-digit", day: "2-digit" },
  dayMonthYearHourMinute: {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  },
  dayMonthNumericYearHourMinute: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  },
  year: {
    year: "numeric",
  },
};

type IntlFormatterOptions = {
  timezone: string;
  locale: string;
  use12HourClock: boolean;
  currency: string; // 'USD', 'GBP', etc
};

/**
 * A crude but effective way to check that functions that expect a ISO8601 format date string are actually getting a
 * date string, and not something random, or a string that includes a time component.
 */
export const assertValidDateFormat = (v: string) => {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(v)) {
    throw new Error(`Invalid date format: ${v}`);
  }
};

export class IntlFormatter {
  private timezone: string = "UTC";
  private locale: string = "en-US";
  private use12HourClock: boolean = true;
  private currency: string = "USD";

  constructor(options?: IntlFormatterOptions) {
    if (options) {
      this.setOptions(options);
    }
  }

  setOptions({
    timezone,
    locale,
    use12HourClock,
    currency,
  }: IntlFormatterOptions) {
    this.timezone = timezone;
    this.locale = locale;
    this.use12HourClock = use12HourClock;
    this.currency = currency;

    return this;
  }

  formatDate(date: string, format: DateFormat) {
    assertValidDateFormat(date);

    // For isoDate, we use the Swedish locale, as they use the YYYY-MM-DD format
    // @see https://stackoverflow.com/a/58633686/921476
    const locale = format === "isoDate" ? "sv-SE" : this.locale;

    return new Intl.DateTimeFormat(locale, {
      ...dateTimeFormats[format],
      timeZone: "UTC",
      hour12: this.use12HourClock,
    }).format(new Date(`${date}T00:00:00Z`));
  }

  formatDateTime(date: Date, format: DateTimeFormat) {
    // For isoDate, we use the Swedish locale, as they use the YYYY-MM-DD format
    // @see https://stackoverflow.com/a/58633686/921476
    const locale = format === "isoDate" ? "sv-SE" : this.locale;

    return new Intl.DateTimeFormat(locale, {
      ...dateTimeFormats[format],
      timeZone: this.timezone,
      hour12: this.use12HourClock,
    }).format(date);
  }

  formatTime(time: string, format: TimeFormat) {
    const date = new Date(`2000-01-01T${time}Z`);
    return new Intl.DateTimeFormat(this.locale, {
      ...dateTimeFormats[format],
      timeZone: "UTC",
      hour12: this.use12HourClock,
    }).format(date);
  }

  formatMoneyFromInteger(amount: number, decimalPlaces: number = 2): string {
    return Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currency,
      maximumFractionDigits: decimalPlaces,
    }).format(amount / 100);
  }
}

/**
 * Default singleton instance of IntlFormatter.
 */
const formatter = new IntlFormatter();

// Convenience functions to format dates and times using the default formatter
export const formatDate = formatter.formatDate.bind(formatter);
export const formatDateTime = formatter.formatDateTime.bind(formatter);
export const formatTime = formatter.formatTime.bind(formatter);
export const formatMoneyFromInteger =
  formatter.formatMoneyFromInteger.bind(formatter);

/**
 * Convenience function to set the options for the default formatter.
 *
 * When you need to set the timezone/locale/etc for a company, you can call:
 *   setIntlFormatterOptions({ timezone: company.timezone, ...etc });
 */
export const setIntlFormatterOptions = (
  options: {
    timezone: string;
    locale: string;
    use12HourClock: boolean;
    currency: string;
  } | null,
) => {
  // Set some sensible defaults, if not provided
  const {
    timezone = "UTC",
    locale = "en-US",
    use12HourClock = true,
    currency = "USD",
  } = options ?? {};
  formatter.setOptions({ timezone, locale, use12HourClock, currency });
};
