import {
  AttendanceDto,
  AttendeeDto,
  AttendeeStatus,
  EnrolmentAdjustmentDto,
  SortSchema,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { uniqueValuesForKey } from "../collectionUtils";
import { getPlatformFunctions } from "../platformSpecific";

export interface AttendanceRecord {
  student: StudentDto;
  trial?: TrialDto;
  attendance?: AttendanceDto;
  adjustments?: EnrolmentAdjustmentDto[];
}

export const useLessonAttendancesData = ({
  classId,
  date,
  time,
}: {
  classId: string;
  date: string;
  time: string;
}) => {
  const { useApi } = getPlatformFunctions();

  const { data: attendances, isLoading: isLoadingAttendances } = useApi(
    "listAttendance",
    {
      selectAll: true,
      where: {
        classId: {
          equals: classId,
        },
        date: {
          equals: date,
        },
        time: {
          equals: time,
        },
      },
    },
  );

  const { data: courseDto, isLoading: isLoadingClass } = useApi("getCourse", {
    id: classId,
  });

  const { data: attendees, isLoading: isLoadingAttendees } = useApi(
    "listAttendee",
    {
      where: [
        {
          classId: {
            equals: classId,
          },
          date: {
            equals: date,
          },
          time: {
            equals: time,
          },
        },
      ],
    },
  );

  const studentIds = uniqueValuesForKey("studentId", attendees ?? []);

  const { data: students, isLoading: isLoadingStudents } = useApi(
    "listStudent",
    {
      selectAll: true,
      where: {
        id: {
          in: studentIds,
        },
      },
      includeArchived: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    {
      enabled: studentIds.length > 0,
    },
  );

  const { data: season, isLoading: isLoadingSeason } = useApi(
    "getSeason",
    { id: courseDto?.entity.seasonId ?? "" }, // Fix no "entity" on page refresh
    { enabled: !isLoadingClass && !!courseDto?.entity },
  );

  const studentsData = students?.data ?? [];

  const records: AttendanceRecord[] = prepareAttendanceRecords({
    attendees: attendees ?? [],
    students: studentsData,
    attendances: attendances?.data ?? [],
  });

  const isLoading =
    isLoadingAttendances ||
    isLoadingStudents ||
    isLoadingClass ||
    isLoadingAttendees ||
    isLoadingSeason;

  return {
    season,
    courseDto: courseDto?.entity,
    records,
    isLoading,
  };
};

const prepareAttendanceRecords = ({
  attendees,
  students,
  attendances,
}: {
  attendees: AttendeeDto[];
  students: StudentDto[];
  attendances: AttendanceDto[];
}): AttendanceRecord[] => {
  return attendees
    .filter(attendee => attendee.status === AttendeeStatus.ExpectedToAttend)
    .map(attendee => {
      const student = students.find(
        student => student.id === attendee.studentId,
      )!;
      const attendance = attendances.find(
        attendance => attendance.studentId === attendee.studentId,
      );
      const adjustments = attendee.adjustments;

      return {
        student,
        attendance,
        trial: attendee.trials.length > 0 ? attendee.trials[0] : undefined,
        adjustments,
      };
    });
};
