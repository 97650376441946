import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

export type AmountSchema = {
  amount: number;
};

const paymentAmountDefinition = () => {
  return new FormDefinitionBuilder<AmountSchema>()
    .money("amount", {
      label: "Amount",
      required: true,
    })
    .getDefinition();
};

export const usePaymentAmountForm = (
  builder: FormBuilder,
  onSubmit: (data: AmountSchema) => void,
  defaultValues: DefaultValues<AmountSchema>,
) => {
  return builder(paymentAmountDefinition(), onSubmit, defaultValues);
};
