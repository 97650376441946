import {
  RegistrationFeeDto,
  RepetitionInterval,
} from "@justraviga/classmanager-sdk";

import { useTaxRates } from "../../data/useTaxRates";
import { getPlatformFunctions } from "../../platformSpecific";
import { zeroYearDateToMonthDay } from "../../registrationFeeUtils";
import {
  makeRegistrationFeeCreateRequest,
  makeRegistrationFeeUpdateRequest,
  useRegistrationFeeFormDefinition,
} from "../formDefinitions/registrationFeeForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = RegistrationFeeDto;

export const useRegistrationFeeActions = (
  defaultActions: DefaultEntityActions<Model>,
) => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { defaultTaxRateId } = useTaxRates();

  return {
    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.onCreateSuccess}
            apiRequest={makeRegistrationFeeCreateRequest({ api })}
            defaultValues={{
              repetitionInterval: RepetitionInterval.PerSeason,
              proRated: false,
              taxRateId: defaultTaxRateId,
            }}
            formDefinitionHook={useRegistrationFeeFormDefinition}
          />
        ),
      }),
    showUpdateForm: (item: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.onUpdateSuccess}
            apiRequest={makeRegistrationFeeUpdateRequest({ api, id: item.id })}
            defaultValues={{
              ...item,
              dueDate: item.dueDate
                ? zeroYearDateToMonthDay(item.dueDate)
                : undefined,
              proRated: item.proRated ?? false,
              taxRateId: item.taxRate?.id ?? undefined,
            }}
            formDefinitionHook={useRegistrationFeeFormDefinition}
          />
        ),
      }),
    deleteOne: (item: Model) =>
      defaultActions.deleteOne(() =>
        api.registrationFees.deleteRegistrationFee({ id: item.id }),
      ),
    archive: (item: Model) =>
      defaultActions.archive(() =>
        api.registrationFees.archiveRegistrationFee({ id: item.id }),
      ),
    restore: (item: Model) =>
      defaultActions.unarchive(() =>
        api.registrationFees.unarchiveRegistrationFee({ id: item.id }),
      ),
  };
};
