import { portalId } from "@/lib/hubspot/hubspotTypes";
import { logger } from "@/lib/logger/logger";

export const submitToHubspot = async <T>(formGuid: string, hubspotData: T) => {
  try {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(hubspotData),
      },
    );

    if (!response.ok) {
      logger.error("HubSpot submission failed");
      return null;
    }

    return await response.json();
  } catch (error) {
    logger.error("Error submitting to HubSpot:", error as Error);
    return null;
  }
};
