import {
  CreateStaffRequest,
  StaffPermissionSet,
  UpdateStaffRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type CreateSchema = CreateStaffRequest;
type UpdateSchema = UpdateStaffRequest;
type Schema = CreateSchema & UpdateSchema;

const useDefinition = () => {
  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic Information", [
      "firstname",
      "lastname",
      "email",
      "phone",
      "permissionSet",
    ])
    .text("firstname", { label: "First name", required: true })
    .text("lastname", { label: "Last name", required: true })
    .text("email", {
      label: "Email",
      type: "email",
    })
    .text("phone", {
      label: "Phone number",
      type: "tel",
    })
    .select("permissionSet", {
      label: "Role",
      data: Object.values(StaffPermissionSet).map(value => ({
        label: enumLabel("permissionSet", value),
        value,
      })),
    });

  return builder.getDefinition() as FormDefinition<Schema>;
};

// Yes, these 2 definitions are the same, but they'll probably diverge again in the not-so-distant future
export const makeStaffCreateFormDefinition = () => () => useDefinition();

export const makeStaffUpdateFormDefinition = () => () => useDefinition();

export const makeStaffCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.staff.createStaff({
      createStaffRequest: formData,
    });
  };

export const makeStaffUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.staff.updateStaff({
      id,
      updateStaffRequest: formData,
    });
  };
