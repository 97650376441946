import {
  Breadcrumb,
  ContentPlaceholder,
  useGenericComponents,
  useStripeStatus,
} from "shared/components";
import { useSharedFamilyPaymentMethods } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { Button } from "@/modules/common/ui/button/Button";
import { PaymentMethodCard } from "@/modules/common/ui/card/PaymentMethodCard";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { Router } from "@/routing/router";

function useFamilyPaymentMethodCrumbs(familyId: string): Breadcrumb[] {
  const { data: family, isSuccess } = useApi("getFamily", { id: familyId });

  const familyListLink: Breadcrumb = {
    text: "Families",
    onClick: () => Router.push("FamilyList"),
  };

  if (!isSuccess) {
    return [familyListLink];
  }

  return [
    familyListLink,
    {
      text: family.name,
      onClick: () => Router.push("FamilyDetails", { id: familyId }),
    },
    {
      text: "Payment methods",
    },
  ];
}

interface FamilyPaymentMethodsPageProps {
  familyId: string;
}

export const FamilyPaymentMethodsPage = ({
  familyId,
}: FamilyPaymentMethodsPageProps) => {
  const { ProtectedOverlay } = useGenericComponents();
  const { protector: stripeProtector } = useStripeStatus();
  const { paymentMethods, isSuccess, isLoading } =
    useSharedFamilyPaymentMethods(familyId);
  const { showAddPaymentMethodForm } = usePaymentMethods();

  const crumbs = useFamilyPaymentMethodCrumbs(familyId);

  return (
    <CompanyCardPage
      title="Payment methods"
      isEmpty={(!isSuccess && !isLoading) || paymentMethods?.length === 0}
      primaryAction={{
        icon: "addOutline",
        title: "Add",
        onClick: () => showAddPaymentMethodForm(familyId),
      }}
      crumbs={crumbs}
      hideGlobalHeader>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon="helpCircleOutline"
          title="No payment methods yet"
          description="This family does not have any saved payment methods."
          action={
            <ProtectedOverlay protector={stripeProtector}>
              <Button
                variant={"brand"}
                text={"Add"}
                size={"sm"}
                onClick={() => showAddPaymentMethodForm(familyId)}
              />
            </ProtectedOverlay>
          }
        />
      </CardContainerPlaceholder>
      <CardContainerContent>
        {isLoading && <LoadingSpinnerDark />}
        {!isLoading &&
          isSuccess &&
          paymentMethods.map(paymentMethod => (
            <PaymentMethodCard
              familyId={familyId}
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
            />
          ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
