import React from "react";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Switch, SwitchProps } from "@/modules/common/form/Switch";

const options: Array<Option<SwitchProps>> = [
  {
    title: "Variants",
    props: [
      {
        label: "Neutral",
        checked: true,
        onChange: () => {},
      },
      {
        label: "Brand",
        variant: "brand",
        checked: true,
        onChange: () => {},
      },
    ],
  },
  {
    title: "Label and Description",
    props: [
      {
        description: "Just the default text",
        checked: true,
        onChange: () => {},
      },
      {
        label: "With a title",
        description:
          "Some really long descriptive text that should wrap around when necessary",
        checked: true,
        onChange: () => {},
      },
    ],
  },
];

export const SwitchPage = () => {
  return (
    <>
      <DocWrapper
        title="Switch Input"
        component={Switch as React.ComponentType<SwitchProps>}
        options={options}
      />
    </>
  );
};
