import { CustomFieldEntity, StudentDto } from "@justraviga/classmanager-sdk";

import {
  Breadcrumb,
  CustomFieldResponsesCard,
  StudentScheduleWidget,
  useSharedContactActions,
} from "shared/components";
import { getFullName, useBreakpoint, WebActionMenuItemProps } from "shared/lib";

import { useData } from "@/lib/api/apiClient";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { ActionMenuProps } from "@/modules/common/ui/ActionMenu";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { ClickableNavItem } from "@/modules/company/common/details/ClickableNavItem";
import { NavItemContainer } from "@/modules/company/common/details/NavItemContainer";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { EmergencyContactCard } from "@/modules/company/members/student/detail/EmergencyContactCard";
import { MedicalInfoCard } from "@/modules/company/members/student/detail/MedicalInfoCard";
import { Profile } from "@/modules/company/members/student/detail/Profile";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StudentDetailsPage = ({ id }: { id: StudentDto["id"] }) => {
  const contactActions = useSharedContactActions();
  const studentActions = useStudentActions();
  const customFieldActions = useCustomFieldActions();
  const { md: isDesktop } = useBreakpoint();

  const student = useData("getStudent", { id });

  const editAction: WebActionMenuItemProps = {
    title: "Edit",
    onClick: () => {
      studentActions.showUpdateForm(student);
    },
    permission: "members:manage",
  };

  const actions: ActionMenuProps["items"] = [
    ...(isDesktop ? [] : [editAction]),
    {
      title: "Assign make up lesson",
      onClick: () => studentActions.makeUpLesson(student),
      leftIcon: "swapHorizontalOutline",
      permission: "enrolments:manage",
    },
    {
      title: student.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        student.archivedAt
          ? studentActions.unarchive(student).then()
          : studentActions.archive(student).then();
      },
      leftIcon: student.archivedAt ? "reloadOutline" : "archiveOutline",
      permission: "members:manage",
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        studentActions.deleteOne(student).then(() => {
          Router.push("StudentList");
        });
      },
      leftIcon: "trashOutline",
      permission: "members:manage",
    },
  ];

  const crumbs: Breadcrumb[] = [
    {
      text: "Students",
      onClick: () => {
        Router.push("StudentList");
      },
    },
    {
      text: getFullName(student),
    },
  ];

  function goToCourse(courseId: string) {
    return Router.push("ClassDetails", { id: courseId });
  }

  return (
    <DetailPageLayout
      isDesktop={isDesktop}
      archivedEntityName={"student"}
      restoreAction={() => studentActions.unarchive(student).then()}
      editAction={() => studentActions.showUpdateForm(student)}
      actions={actions}
      permissions={{
        edit: "members:manage",
        restore: "members:manage",
      }}
      crumbs={crumbs}
      isArchived={student.archivedAt !== null}
      header={
        <Profile
          student={student}
          isDesktop={isDesktop}
          uploadAction={() => studentActions.showAvatarUploadSheet(student)}
        />
      }>
      <DetailPageGrid>
        <DetailPageGridLeftColumn>
          <StudentScheduleWidget student={student} goToCourse={goToCourse} />
        </DetailPageGridLeftColumn>
        <DetailPageGridRightColumn>
          <div className={"w-full"}>
            <EmergencyContactCard
              student={student}
              familyId={student.familyId}
              openContactSheet={() => {
                contactActions.showCreateForm(student.familyId, {
                  isEmergency: true,
                });
              }}
            />
          </div>
          {isDesktop && <StudentNavItems student={student} />}
          <MedicalInfoCard student={student} />
          <div className={"w-full"}>
            <CustomFieldResponsesCard
              entityId={student.id}
              defaultEntity={CustomFieldEntity.Student}
              CustomFieldResponsesContent={CustomFieldResponsesContent}
              customFieldActions={customFieldActions}
            />
          </div>
          {!isDesktop && <StudentNavItems student={student} />}
        </DetailPageGridRightColumn>
      </DetailPageGrid>
    </DetailPageLayout>
  );
};

const StudentNavItems = ({ student }: { student: StudentDto }) => {
  const waitingList = useData("listWaitingList", {
    where: { studentId: { equals: student.id } },
  });

  const waitingListCount = waitingList.pagination.total;

  return (
    <NavItemContainer>
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentEnrolments", {
            studentId: student.id,
          })
        }
        text={"Enrollments"}
        iconName={"calendarClear"}
        permission="enrolments:view"
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentTrials", {
            studentId: student.id,
          })
        }
        text={"Trials"}
        iconName={"time"}
        permission="enrolments:view"
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentWaitingList", {
            studentId: student.id,
          })
        }
        text={"Waiting List"}
        iconName={"reorderFour"}
        badgeCount={waitingListCount}
        permission="enrolments:view"
      />
    </NavItemContainer>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "members:view",
  StudentDetailsPage,
  "Student details",
);

export { PermissionChecked as StudentDetailsPage };
