import { createContext, PropsWithChildren, useContext } from "react";

import { GenericFormComponent } from "./genericFormHooks";
import {
  ActionMenuComponent,
  BannerComponent,
  BaseCardComponent,
  ButtonComponent,
  CircularIndicatorComponent,
  HorizontalSeparatorComponent,
  IconButtonComponent,
  IconComponent,
  ImageComponent,
  LoaderComponent,
  PressableComponent,
  ProtectedOverlayComponent,
  TextComponent,
  ViewComponent,
} from "./interfaces";
import { LinkComponent } from "./interfaces/link";

/**
 * Only components that have to be implemented separately for each platform (web/mobile)
 * should be added here. Anything that can be implemented as a shared component
 * should just be imported as normal.
 */
export interface GenericComponents {
  /**
   * @deprecated - use shared action menu instead
   */
  ActionMenu: ActionMenuComponent;
  Banner: BannerComponent;
  BaseCard: BaseCardComponent;
  Button: ButtonComponent;
  CircularIndicator: CircularIndicatorComponent;
  GenericForm: GenericFormComponent;
  HorizontalSeparator: HorizontalSeparatorComponent;
  Icon: IconComponent;
  IconButton: IconButtonComponent;
  Image: ImageComponent;
  Link: LinkComponent;
  Loader: LoaderComponent;
  Pressable: PressableComponent;
  ProtectedOverlay: ProtectedOverlayComponent;
  Text: TextComponent;
  View: ViewComponent;
}

const GenericComponentsContext = createContext<GenericComponents>(
  {} as GenericComponents,
);

// eslint-disable-next-line react-refresh/only-export-components
export const useGenericComponents = () => useContext(GenericComponentsContext);

export const GenericComponentsProvider = ({
  children,
  ...rest
}: PropsWithChildren<GenericComponents>) => {
  return (
    <GenericComponentsContext.Provider value={rest}>
      {children}
    </GenericComponentsContext.Provider>
  );
};
