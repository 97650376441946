import { SortSchema } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export const useLocationDetails = (id: string) => {
  const { useApi } = getPlatformFunctions();

  const { data: location, isLoading: isLoadingLocation } = useApi(
    "getLocation",
    { id },
  );

  const { data: rooms, isLoading: isLoadingRooms } = useApi(
    "listRoom",
    {
      where: {
        locationId: {
          equals: location?.id,
        },
      },
      sort: {
        name: SortSchema.Asc,
      },
      onlyArchived: !!location?.archivedAt,
    },
    { enabled: !!location },
  );

  return {
    isLoading: isLoadingLocation || isLoadingRooms,
    location: location!,
    rooms: rooms!,
  };
};
