import { PropsWithChildren, ReactNode } from "react";

import {
  ContactSearchEntity,
  CourseSearchEntity,
  FamilySearchEntity,
  IdPrefix,
  idPrefix as Prefix,
  SearchCollectionType,
  SeasonSearchEntity,
  StaffSearchEntity,
  StudentSearchEntity,
} from "@justraviga/classmanager-sdk";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

export const searchableIdPrefixes = [
  Prefix.Staff,
  Prefix.Student,
  Prefix.Family,
  Prefix.Course,
  Prefix.Season,
] as const;
export type SearchableIdPrefix = Extract<
  IdPrefix,
  (typeof searchableIdPrefixes)[number]
>;
export type SearchableId = `${SearchableIdPrefix}_${string}`;

export const SearchCollection = {
  student: SearchCollectionType.Student,
  family: SearchCollectionType.Family,
  staff: SearchCollectionType.Staff,
  course: SearchCollectionType.Course,
  season: SearchCollectionType.Season,
} as const;
export type SearchCollection =
  (typeof SearchCollection)[keyof typeof SearchCollection];

export const GlobalSearchStatus = {
  // We haven't attempted to initialise yet
  PENDING: "pending",
  // Everything is working as expected
  OK: "ok",
  // We are loading search results
  LOADING: "loading",
  // There was an error loading results
  ERROR: "error",
  // We are unable to contact typesense due to missing apikey or other reason
  UNAVAILABLE: "unavailable",
  // the current user role doesn't shouldn't have search
  // functionality available
  HIDDEN: "hidden",
} as const;
export type GlobalSearchStatus =
  (typeof GlobalSearchStatus)[keyof typeof GlobalSearchStatus];

export interface SearchStatusContextType {
  status: GlobalSearchStatus;
}

export interface SearchDialogContextType {
  isSearchOpen: boolean;
  handleOpenChange: (isOpen: boolean) => void;
}

export interface SearchClientContextType {
  searchClient: TypesenseInstantsearchAdapter | null;
}

export const GlobalSearchResultsType = {
  clean: "clean",
  response: "response",
} as const;
export type GlobalSearchResultsType =
  (typeof GlobalSearchResultsType)[keyof typeof GlobalSearchResultsType];

export interface LinkedSearchResultType extends PropsWithChildren {
  id: SearchableId;
}

export interface SearchResultTitleType {
  title: string;
  count: number;
}

export interface SearchResultType {
  id: SearchableId;
  type: SearchCollection;
  title: ReactNode;
  description: ReactNode;
  children: React.ReactNode;
}

export interface StudentSearchResultType {
  id: SearchableId;
  firstname: string;
  lastname: string;
  birthdate: string;
  profilePicture: string | null;
}

export interface StaffSearchResultType {
  id: SearchableId;
  firstname: string;
  lastname: string;
  email: string;
  profilePicture: string | null;
}

// TODO: Remove self typed documents when backend begins exporting
// types via the SDK
export interface StaffDocument extends StaffSearchEntity {
  id: SearchableId;
}

export interface CourseDocument extends CourseSearchEntity {
  id: SearchableId;
}

export interface SeasonDocument extends SeasonSearchEntity {
  id: SearchableId;
}

export interface ContactDocument extends ContactSearchEntity {
  id: SearchableId;
}

export interface FamilyDocument extends FamilySearchEntity {
  id: SearchableId;
}

export interface StudentDocument extends StudentSearchEntity {
  id: SearchableId;
}

export interface SearchCollectionResponse<T> {
  count: number;
  documents: T[];
}

export const searchCollectionTitleMap = {
  [SearchCollection.family]: "Families",
  [SearchCollection.student]: "Students",
  [SearchCollection.staff]: "Staff",
  [SearchCollection.course]: "Courses",
  [SearchCollection.season]: "Seasons",
};
