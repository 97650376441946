import { useCallback, useState } from "react";

import { StripeAccountDto } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { showAlert } from "../alertState";
import { useAuthState } from "../auth/useAuthState";
import {
  checkProtection,
  lockedFeature,
  unlockedFeature,
} from "../components/protector";
import { getPlatformFunctions } from "../platformSpecific";

function hasRequirementErrors(account: StripeAccountDto): boolean {
  if (!account?.requirements) {
    return false;
  }

  return account.requirements.errors?.length > 0;
}

function hasRequirements(account: StripeAccountDto): boolean {
  if (!account?.requirements) {
    return false;
  }

  return (
    account.requirements.pastDue?.length > 0 ||
    account.requirements.eventuallyDue?.length > 0 ||
    account.requirements.currentlyDue?.length > 0
  );
}

function isPendingVerification(account: StripeAccountDto): boolean {
  if (!account?.requirements) {
    return false;
  }

  return account.requirements.pendingVerification?.length > 0;
}

type LockedStripeAccountStatus =
  | "StripeAccountFetchError" // error fetching from our backend
  | "StripeAccountHasErrors"
  | "StripeAccountNotReady"
  | "StripeAccountReady";

type UnlockedStripeAccountStatus = "StripeAccountPending" | "StripeAccountOkay";

type StripeAccountStatus =
  | LockedStripeAccountStatus
  | UnlockedStripeAccountStatus;

const getStripeMessage = (
  stripeAccountStatus: LockedStripeAccountStatus,
): string => {
  return match(stripeAccountStatus)
    .with(
      "StripeAccountFetchError",
      () => "Unable to load Stripe account. Please try again later.",
    )
    .with(
      "StripeAccountHasErrors",
      () =>
        "Payouts are at risk of being blocked. You need to provide additional company information.",
    )
    .with(
      "StripeAccountNotReady",
      () =>
        "You need to integrate our online payment provider to access this feature.",
    )
    .with(
      "StripeAccountReady",
      () =>
        "Class Manager Payments verification is processing. Check back soon.",
    )
    .exhaustive();
};

const determineStripeAccountStatus = ({
  stripeAccount,
  isPending,
  isError,
}: {
  stripeAccount?: StripeAccountDto;
  isPending: boolean;
  isError: boolean;
}): StripeAccountStatus => {
  if (isPending) {
    return "StripeAccountPending";
  }

  if (isError || stripeAccount === undefined) {
    return "StripeAccountFetchError";
  }

  if (hasRequirementErrors(stripeAccount)) {
    return "StripeAccountHasErrors";
  }

  if (hasRequirements(stripeAccount)) {
    return "StripeAccountNotReady";
  }

  if (isPendingVerification(stripeAccount)) {
    return "StripeAccountReady";
  }

  return "StripeAccountOkay";
};

export function useStripeStatus(hasImplicitCompany: boolean = false) {
  const { account } = useAuthState();
  const { useApi, openWebsite, api } = getPlatformFunctions();
  const {
    status: loadStripeAccountStatus,
    isLoading: isStripeAccountLoading,
    data: stripeAccount,
  } = useApi(
    "stripeRetrieveAccount",
    {},
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      staleTime: (query: any) => {
        const stripeAccount = query?.state.data || undefined;
        const queryStatus = query?.state.status;

        const stripeAccountStatus = determineStripeAccountStatus({
          stripeAccount,
          isPending: queryStatus === "pending",
          isError: queryStatus === "error",
        });

        if (stripeAccountStatus === "StripeAccountOkay") {
          return Infinity;
        }

        return 0;
      },
      enabled: hasImplicitCompany || Boolean(account && account.company),
    },
  );
  const [isLoadingOnboardingLink, setIsLoadingOnboardingLink] = useState(false);

  const redirectToStripe = useCallback(async () => {
    setIsLoadingOnboardingLink(true);
    try {
      const onboardingLink = await api.stripe.getOnboardingLink();
      if (onboardingLink && onboardingLink.url) {
        openWebsite(onboardingLink.url);
      }
    } catch (error) {
      console.error(error);
      showAlert({
        content: "Error loading link to stripe. Please try again later.",
        variant: "error",
      });
    } finally {
      setIsLoadingOnboardingLink(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isStripeAccountLoading;
  const status = determineStripeAccountStatus({
    stripeAccount,
    isPending: loadStripeAccountStatus === "pending",
    isError: loadStripeAccountStatus === "error",
  });
  const isProtected =
    status !== "StripeAccountOkay" && status !== "StripeAccountPending";
  const stripeFeatureProtector = isProtected
    ? lockedFeature(getStripeMessage(status))
    : unlockedFeature();

  return {
    status,
    protector: checkProtection([stripeFeatureProtector]),
    isLoading,
    stripeAccount,
    isLoadingOnboardingLink,
    redirectToStripe,
  };
}
