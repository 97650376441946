import { useGenericComponents } from "../GenericComponentsProvider";

interface NonLinkableCrumb {
  text: string;
}

export interface LinkableCrumb extends NonLinkableCrumb {
  onClick: () => void;
}

export type Breadcrumb = NonLinkableCrumb | LinkableCrumb;

export interface BreadcrumbsProps {
  items: Breadcrumb[];
}

const LinkableCrumb = ({ text, onClick }: LinkableCrumb) => {
  const { Pressable, Text, View } = useGenericComponents();
  return (
    <View>
      <Pressable onClick={onClick} className="">
        <Text className="text-label-600 text-grey-900 underline hover:text-grey-600 underline-offset-4">
          {text}
        </Text>
      </Pressable>
    </View>
  );
};

const NonLinkableCrumb = ({ text }: NonLinkableCrumb) => {
  const { Text, View } = useGenericComponents();
  return (
    <View>
      <Text className="text-label-400 text-grey-600">{text}</Text>
    </View>
  );
};

const Divider = () => {
  const { Text } = useGenericComponents();
  return <Text className="mx-3 text-label-600 text-grey-400">/</Text>;
};

// A React component that displays the breadcrumbs for the current page.
// The individual breadcrumbs will be supplied as an array of objects.
export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const { View } = useGenericComponents();
  return (
    <View className="flex flex-row items-center">
      {items.map((item, index) => (
        <View key={index} className="flex flex-row">
          {"onClick" in item ? (
            <LinkableCrumb {...item} />
          ) : (
            <NonLinkableCrumb {...item} />
          )}
          {index < items.length - 1 && <Divider />}
        </View>
      ))}
    </View>
  );
};
