import {
  StripeChargePaymentMethodRequest,
  StripePaymentMethodCardDataDto,
  StripePaymentMethodDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import {
  displayExpiryDate,
  displayObscuredLast4,
  isCardMethod,
} from "../../paymentMethodUtils";
import { UseApi } from "../apiQueryFactory";
import { CardTypeIconComponent, SelectItem } from "../interfaces";
import { SelectItemCardIcon } from "../payments/SelectItemCardIcon";

type Schema = StripeChargePaymentMethodRequest;

function paymentMethodSelectOptionLabel(last4: string, isDefault: boolean) {
  return `${displayObscuredLast4(last4)}${isDefault ? " (default)" : ""}`;
}

function paymentMethodSelectOptionDescription(
  expiryMonth: number,
  expiryYear: number,
) {
  return `Expires ${displayExpiryDate(expiryMonth, expiryYear)}`;
}

export function paymentMethodToSelectOption(
  paymentMethod: StripePaymentMethodDto,
  SelectItemCardIcon: CardTypeIconComponent,
): SelectItem {
  const { brand, last4, expiryMonth, expiryYear } =
    paymentMethod.data as StripePaymentMethodCardDataDto;

  return {
    label: paymentMethodSelectOptionLabel(last4, paymentMethod.isDefault),
    prepend: <SelectItemCardIcon cardType={brand} />,
    description: paymentMethodSelectOptionDescription(expiryMonth, expiryYear),
    value: paymentMethod.id,
  };
}

interface UseChargeCardOnFileFormProps {
  familyId: string;
  useApi: UseApi;
}

export const useChargeCardOnFileForm = ({
  familyId,
  useApi,
}: UseChargeCardOnFileFormProps) => {
  const { data: paymentMethods, isLoading } = useApi(
    "listStripePaymentMethod",
    {
      family: familyId,
    },
  );

  return () => {
    return new FormDefinitionBuilder<Schema>()
      .money("amount", {
        label: "Amount",
        required: true,
      })
      .select("paymentMethodId", {
        label: "Select card",
        required: true,
        loading: isLoading,
        data: (paymentMethods ?? [])
          .filter(paymentMethod => isCardMethod(paymentMethod.data))
          .map((paymentMethod: StripePaymentMethodDto) =>
            paymentMethodToSelectOption(paymentMethod, SelectItemCardIcon),
          ),
      })
      .group("Which card would you like to charge?", ["paymentMethodId"])
      .getDefinition() as FormDefinition<Schema>;
  };
};

export const makeChargeCardOnFileFormRequest =
  ({ api, familyId }: { api: Api; familyId: string }) =>
  (formData: Schema) => {
    return api.stripe.stripeChargePaymentMethod({
      familyId,
      stripeChargePaymentMethodRequest: formData,
    });
  };
