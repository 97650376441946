import { SortSchema } from "@justraviga/classmanager-sdk";

import { UseApi } from "../components/apiQueryFactory";
import { CourseSearchMode } from "../components/courseSearch/courseSearchTypes";
import {
  activeAndInUseLocations,
  activeCourses,
  activeSeasons,
} from "../components/courseSearch/courseWizardLogic";

/**
 * The aim of this hook is to have a single place where all the data for browsing the "catalogue" is fetched.
 * We assume a company context has already been set on any SDK requests.
 */
export const useCatalogueData = (
  useApi: UseApi,
  enabled: boolean,
  mode?: CourseSearchMode,
) => {
  // In order to have a single loading state at the start of the browsing experience,
  // we load all relevant data from the company, and then just filter in code from there on.
  const { data: courseResponse, isLoading: isCoursesLoading } = useApi(
    "listCourse",
    {},
    { enabled },
  );

  const { data: locationResponse, isLoading: isLocationsLoading } = useApi(
    "listLocation",
    {
      selectAll: true,
      sort: {
        name: SortSchema.Asc,
      },
    },
    { enabled },
  );

  const {
    data: pricingSchemeValueResponse,
    isLoading: isPricingSchemesLoading,
  } = useApi(
    "listPricingSchemeValue",
    {
      selectAll: true,
      sort: {
        quantity: SortSchema.Asc,
      },
    },
    { enabled },
  );

  const { data: roomResponse, isLoading: isRoomsLoading } = useApi(
    "listRoom",
    { selectAll: true },
    { enabled },
  );

  const { data: seasonResponse, isLoading: isSeasonsLoading } = useApi(
    "listSeason",
    { selectAll: true },
    { enabled },
  );

  const { data: staffResponse, isLoading: isStaffLoading } = useApi(
    "listStaff",
    { selectAll: true },
    { enabled },
  );

  const isLoading =
    isCoursesLoading ||
    isSeasonsLoading ||
    isLocationsLoading ||
    isPricingSchemesLoading ||
    isRoomsLoading ||
    isStaffLoading;

  if (isLoading) {
    return {
      isLoading,
      assignedLocationIds: undefined,
      courses: undefined,
      locations: undefined,
      pricingSchemeValues: undefined,
      rooms: undefined,
      seasons: undefined,
      staff: undefined,
    };
  }

  // Prefilter our data to only include courses that can be enrolled or trialled
  return {
    isLoading,
    assignedLocationIds: activeAndInUseLocations(
      locationResponse!.data,
      roomResponse!.data,
      courseResponse!.data,
      mode,
      seasonResponse!.data,
    ).map(l => l.id),
    courses: activeCourses(courseResponse!.data, mode, seasonResponse!.data),
    locations: locationResponse!.data,
    pricingSchemeValues: pricingSchemeValueResponse!.data,
    rooms: roomResponse!.data,
    seasons: activeSeasons(seasonResponse!.data),
    staff: staffResponse!.data,
  };
};
