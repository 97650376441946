import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { AccountSelectionContent } from "shared/components";
import { cn } from "shared/lib";

import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/modules/common/overlays/dialog/Dialog";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Version } from "@/modules/common/Version";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { useAccountSelectionDialog } from "@/modules/company/switcher/accountSelectionDialogHooks";
import { Router } from "@/routing/router";

export interface AccountSelectionDialogProps {
  account: UserAccountDto | null;
}

export const AccountSelectionDialog = ({
  account,
}: AccountSelectionDialogProps) => {
  const { isOpen, setOpen } = useAccountSelectionDialog();

  const goToLogin = () => Router.push("Login");

  const handleAccountChange = () => {
    setOpen(false);
    // We leave this deliberately vague, to allow the router to work out where they should land
    Router.push("Home");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      {account && (
        <DialogTrigger
          data-testid="account-selection-dialog-trigger"
          className="hidden md:flex"
          asChild>
          <Button
            text={account.company?.name ?? "Admin"}
            variant="tertiary"
            isFullWidth={false}
            rightIcon="chevronDown"
          />
        </DialogTrigger>
      )}
      <DialogContent
        data-testid="account-selection-dialog-content"
        className={cn(
          "fixed flex flex-col bg-white px-8 py-12", // the basics
          "top-0 md:top-16 md:max-h-[calc(100dvh-(2*4rem))]", // small screens are "full screen", others are inset
          "w-full min-w-min md:max-w-lg", // small screens are full width, others are ~512px
          "overflow-y-scroll", // allow scrolling inside
        )}>
        <div className={`relative flex justify-center`}>
          <ClassManagerLogo
            color="grey-900"
            height={classManagerLogoSize.standard.height}
            width={classManagerLogoSize.standard.width}
          />
          <DialogClose
            data-testid="close-account-selection-dialog-btn"
            className={"absolute inset-y-0 right-0"}
            asChild>
            <IconButton
              variant="standard"
              icon={"closeOutline"}
              size="lg"
              aria-label="Close"
            />
          </DialogClose>
        </div>
        <AccountSelectionContent
          onAccountChange={handleAccountChange}
          Version={Version}
          CompanyLogo={CompanyLogo}
          onLogout={goToLogin}
        />
      </DialogContent>
    </Dialog>
  );
};
