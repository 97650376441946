import {
  ListStudentWhereParameter,
  StudentGender,
} from "@justraviga/classmanager-sdk";

import { range } from "../../arrayUtils";
import { DatatableFilterOperator } from "../../datatable/datatableTypes";
import { convertAgeToBirthdate } from "../../dateUtils";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

const ageData = range(16).map(age => ({
  label: `Age ${age}`,
  value: age,
}));

export interface StudentFilterFormSchema {
  minAge: number;
  maxAge: number;
  gender: StudentGender[];
}

export const useStudentFilterForm = () =>
  new FilterFormDefinitionBuilder<
    StudentFilterFormSchema,
    ListStudentWhereParameter
  >()
    .select(
      "minAge",
      {
        label: "Minimum Age",
        data: ageData,
      },
      {
        filterField: "dateOfBirth",
        operator: DatatableFilterOperator.LessThanOrEqual,
        transformer: v => (v ? convertAgeToBirthdate(v) : null),
      },
    )
    .select(
      "maxAge",
      {
        label: "Maximum Age",
        data: ageData,
      },
      {
        filterField: "dateOfBirth",
        operator: DatatableFilterOperator.GreaterThan,
        transformer: v => (v ? convertAgeToBirthdate(v + 1) : null),
      },
    )
    .row(["minAge", "maxAge"], [50])
    .multiSelect(
      "gender",
      {
        label: "Gender",
        data: Object.entries(StudentGender).map(([, value]) => ({
          label: enumLabel("studentGender", value),
          value,
        })),
      },
      {
        filterField: "gender",
        operator: DatatableFilterOperator.In,
      },
    )
    .withArchiveFilter()
    .getDefinition();
