import {
  makeForgottenPasswordRequest,
  useForgottenPasswordFormDefinition,
  useFormActions,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

export const ForgottenPasswordPage = () => {
  const { submit, setIsLoading, isLoading } = useFormActions();

  const onSuccess = () => {
    setIsLoading(false);
    Router.push("PasswordResetLinkSent");
  };

  return (
    <NonAuthLayout>
      <AuthPageCard
        showTerms={false}
        header={
          <ClassManagerLogo
            color="grey-900"
            height={classManagerLogoSize.standard.height}
            width={classManagerLogoSize.standard.width}
            className={"mx-auto"}
          />
        }
        title={"Forgot password"}
        subtitle={
          "Enter your email address and we will send you a link to reset your password."
        }
        content={
          <>
            <div className={"space-y-4"}>
              <GenericForm
                apiRequest={makeForgottenPasswordRequest({ api })}
                defaultValues={{}}
                formDefinitionHook={useForgottenPasswordFormDefinition}
                onSuccess={onSuccess}
              />
              <Button
                onClick={submit}
                loading={isLoading}
                variant="classmanager"
                text="Send reset link"
                isFullWidth={true}
              />
            </div>
            <div className={"text-center"}>
              <Button
                variant={"tertiaryLight"}
                onClick={() => Router.push("Login")}>
                Back to log in
              </Button>
            </div>
          </>
        }
      />
    </NonAuthLayout>
  );
};
