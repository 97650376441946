import { LocationDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeLocationCreateRequest,
  makeLocationUpdateRequest,
  useLocationFormDefinition,
} from "../formDefinitions/locationForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";

type Model = LocationDto;

export type LocationActions = Omit<
  EntityActions<Model>,
  "deleteMany" | "archiveMany" | "unarchiveMany"
>;

export const useLocationActions = (): LocationActions => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<LocationDto>({
    entity: "location",
  });

  return {
    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeLocationCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useLocationFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: LocationDto) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeLocationUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={useLocationFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.locations.deleteLocation({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.locations.archiveLocation({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.locations.unarchiveLocation({ id: entity.id }),
      ),
  };
};
