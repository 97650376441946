import type { Hit, HitHighlightResult } from "instantsearch.js";

import { ContactDocument, FamilyDocument } from "./searchTypes";

export const findHighlightedAttributes = <T extends object>(
  attributes: Array<keyof T>,
  hit: Hit<T>,
): { [Key in keyof T]: boolean } => {
  return attributes.reduce(
    (acc, attr) => {
      const targetAttr =
        hit._highlightResult?.[attr as keyof HitHighlightResult];

      if (!targetAttr) {
        return {
          ...acc,
          [attr]: false,
        };
      }

      return {
        ...acc,
        [attr]: Array.isArray(targetAttr)
          ? targetAttr.some(attr => attr.matchLevel === "full")
          : targetAttr.matchLevel === "full",
      };
    },
    {} as { [Key in keyof T]: boolean },
  );
};

const targetAttributes = ["firstname", "lastname", "email", "phone"] as const;
type Attribute = (typeof targetAttributes)[number];

export type ExtendedHit<
  T extends NonNullable<object> = Record<string, unknown>,
> = Hit<T> & {
  hitAttribute?: Attribute;
};

export const findFirstContactHit = (hit: Hit<FamilyDocument>) => {
  const contacts = hit.contacts as ContactDocument[];

  if (!hit._highlightResult) {
    if (hit.contacts.length === 0) {
      return null;
    }
    return hit.contacts[0] as unknown as ExtendedHit<ContactDocument>;
  }

  const hitContacts = (
    hit._highlightResult?.contacts as HitHighlightResult[]
  ).map((contact, index) => {
    // check each of the nested contact attributes to find a hit.
    const attributeHits = targetAttributes.reduce(
      (acc, attr) => {
        const targetAttr = contact[attr];

        if (!targetAttr) {
          return {
            ...acc,
            [attr]: false,
          };
        }

        return {
          ...acc,
          [attr]: Array.isArray(targetAttr)
            ? targetAttr.some(attr => attr.matchLevel === "full")
            : targetAttr.matchLevel === "full",
        };
      },
      {} as Record<Attribute, boolean>,
    );

    const hitAttribute = Object.keys(attributeHits).find(
      key => attributeHits[key as Attribute],
    );
    return {
      hitAttribute,
      ...contacts[index],
      _highlightResult: contact,
    };
  });

  const firstHit = hitContacts.find(contact => contact.hitAttribute);

  if (!firstHit) {
    return hitContacts[0] as unknown as ExtendedHit<ContactDocument>;
  }

  return firstHit as unknown as ExtendedHit<ContactDocument>;
};
