import { Breadcrumb } from "shared/components";
import { isDefinedEntity, useClassEntity } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { Router } from "@/routing/router";

export const useCourseDetailsPageData = (id: string) => {
  const { entity: classEntity, isLoading: isLoadingCourse } =
    useClassEntity(id);

  const { data: season, isLoading: isLoadingSeason } = useApi(
    "getSeason",
    { id: isDefinedEntity(classEntity) ? classEntity.class.seasonId : "" },
    { enabled: isDefinedEntity(classEntity) },
  );

  const breakpoints = useBreakpoint();

  const crumbs: Breadcrumb[] = [
    {
      text: "Seasons",
      onClick: () => Router.push("SeasonList"),
    },
  ];

  if (isDefinedEntity(classEntity)) {
    crumbs.push(
      {
        text: season?.name ?? "",
        onClick: () => {
          Router.push("SeasonDetails", { id: classEntity.class.seasonId });
        },
      },
      {
        text: classEntity.class.name,
      },
    );
  }

  const isLoading = isLoadingSeason || isLoadingCourse;

  return {
    isLoading,
    classEntity,
    season,
    isDesktop: breakpoints.md,
    crumbs,
  };
};
