import { useEffect } from "react";

import { LoginDto } from "@justraviga/classmanager-sdk";

import { useAuthState, useFormActions } from "shared/components";

import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginForm } from "@/modules/auth/common/LoginForm";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import { Router } from "@/routing/router";

export const LoginPage = () => {
  const { submit, isLoading } = useFormActions();
  const { clearAuthState, onLogin, setOnLogin, setUser, user } = useAuthState();

  useEffect(() => {
    if (user !== undefined) {
      clearAuthState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user === undefined]);

  const onSuccess = async ({ user, token }: LoginDto) => {
    setUser(user, token);

    // Allow us to redirect to a different page after successful login
    if (onLogin !== undefined) {
      onLogin();
      setOnLogin(undefined);
    } else {
      Router.push("Accounts");
    }
  };

  const goToCompanyRegistration = () => Router.push("RegisterCompany");

  return (
    <NonAuthLayout>
      <AuthPageCard
        showTerms={false}
        header={
          <ClassManagerLogo
            color="grey-900"
            className={"mx-auto"}
            height={classManagerLogoSize.standard.height}
            width={classManagerLogoSize.standard.width}
          />
        }
        title={"Log in to your account"}
        content={
          <LoginForm
            submitHandler={submit}
            isLoading={isLoading}
            defaultValues={{}}
            onSuccess={onSuccess}
          />
        }
        footer={
          <SwitchAuthType
            message="Trying to register a studio?"
            onClick={goToCompanyRegistration}
            linkText="Sign up"
          />
        }
      />
    </NonAuthLayout>
  );
};
