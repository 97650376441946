import { AggregateClassDto, SeasonDto } from "@justraviga/classmanager-sdk";

import {
  FamilyCoursePreview,
  FamilyDashboard,
  useAuthState,
} from "shared/components";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { CenteredLoadingSpinner } from "@/modules/common/ui/CenteredLoadingSpinner";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const CustomerDashboardPage = () => {
  const { account } = useAuthState();
  const {
    showTakePaymentForm: openPaymentForm,
    showAddPaymentMethodForm: openPaymentMethodForm,
  } = usePaymentMethods();
  const studentActions = useStudentActions();
  const sheet = useSheet();

  const goToEnrol = () =>
    Router.push("CourseFilterWizard", {
      mode: "enrol",
      slug: account!.company!.slug,
    });

  const goToCourse = (course: AggregateClassDto, season: SeasonDto) => {
    /**
     * todo:
     *   ideally we'll load all data for the sheet here before showing it but we don't have the
     *   architecture to do that yet
     */
    sheet.openSheet({
      title: "Class details",
      content: (
        <FamilyCoursePreview
          course={course}
          season={season}
          HorizontalSeparator={HorizontalSeparator}
        />
      ),
    });
  };

  return (
    <CustomerLayout>
      <FamilyDashboard
        goToCourse={goToCourse}
        goToEnrol={goToEnrol}
        goToAddStudent={studentActions.showPortalCreateForm}
        goToMakePayment={openPaymentForm}
        openPaymentMethodForm={openPaymentMethodForm}
        LoadingSpinner={<CenteredLoadingSpinner />}
      />
    </CustomerLayout>
  );
};
