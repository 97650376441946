import { MultiStepsHeaderProps } from "../../forms/multiStepFormProps";
import { useGenericComponents } from "../GenericComponentsProvider";

export const MultiStepsHeader = ({
  currentStep,
  steps,
}: MultiStepsHeaderProps) => {
  const { Text, View, IconButton, CircularIndicator } = useGenericComponents();
  const step = steps.find(step => step.index === currentStep);
  const totalSteps = steps.length;

  return (
    <View className="flex h-20 flex-row items-center justify-between rounded-md bg-grey-200 p-4">
      <View className="flex flex-row items-center gap-2">
        {step?.goBack && (
          <IconButton
            variant="secondary-fill"
            icon="chevronBackOutline"
            onClick={step.goBack}
          />
        )}
        <Text className="text-body-600 font-semibold">{step?.label}</Text>
      </View>
      <CircularIndicator
        className={"h-12 w-12"}
        value={currentStep}
        maxValue={totalSteps}
      />
    </View>
  );
};
