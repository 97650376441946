import type { InstantSearchStatus, ScopedResult } from "instantsearch.js";
import { useInstantSearch } from "react-instantsearch-core";

import { SearchCollection } from "./searchTypes";

interface SearchResultCounts {
  [key: string]: {
    totalCount: number;
  };
}

export type SearchResultsCountStatus =
  | "awaiting-input"
  | "empty-results"
  | "success";

const arrayToObject = (array: ScopedResult[]) =>
  array.reduce<{ [key: string]: ScopedResult }>((obj, item) => {
    obj[item.indexId] = item;
    return obj;
  }, {});

export const emptySearchCollections = Object.keys(
  SearchCollection,
).reduce<SearchResultCounts>((acc, key) => {
  acc[key] = {
    totalCount: 0,
  };
  return acc;
}, {});

export interface SearchResultCount {
  groups: SearchResultCounts;
  total: number;
  status: SearchResultsCountStatus;
  searchStatus: InstantSearchStatus;
}

export function useSearchResultCount(): SearchResultCount {
  const {
    scopedResults,
    indexUiState,
    status: searchStatus,
  } = useInstantSearch();
  const keyedScopedResults = arrayToObject(scopedResults);

  const { query } = indexUiState;

  if (!query || query.length === 0) {
    return {
      groups: emptySearchCollections,
      total: 0,
      status: "awaiting-input",
      searchStatus,
    };
  }

  const groups = Object.keys(SearchCollection).reduce<SearchResultCounts>(
    (acc, key) => {
      acc[key] = {
        totalCount: keyedScopedResults[key]?.results?.nbHits || 0,
      };

      return acc;
    },
    {},
  );

  const total = Object.values(groups).reduce(
    (acc, group) => acc + group.totalCount,
    0,
  );

  return {
    groups,
    total,
    status: total === 0 ? "empty-results" : "success",
    searchStatus,
  };
}
