import type { Hit } from "instantsearch.js";

import { getInitials, SearchCollection, StudentDocument } from "shared/lib";

import { SearchResult } from "@/modules/common/search/searchResults/SearchResult";
import { StyledHighlight } from "@/modules/common/search/StyledHighlight";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";

interface StudentSearchResultProps {
  hit: Hit<StudentDocument>;
}

export const StudentSearchResult = ({
  hit: student,
}: StudentSearchResultProps) => {
  // familyName not yet supported but coming soon
  // doesn't display anything if attribute not found.
  return (
    <SearchResult
      type={SearchCollection.student}
      title={
        <>
          <StyledHighlight attribute="firstname" hit={student} />{" "}
          <StyledHighlight attribute="lastname" hit={student} />
        </>
      }
      description={<StyledHighlight attribute="familyName" hit={student} />}
      id={student.id}>
      <Avatar
        name={getInitials(student)}
        src={student.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};
