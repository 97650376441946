import { ContentPlaceholder } from "shared/components";
import { useDatatable } from "shared/lib";

import { datatableCreateWebButtonProps } from "@/modules/common/datatable/utilities";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const NoContentPlaceholder = () => {
  const { datatable } = useDatatable();

  const action = datatable.createAction ? (
    <ProtectedOverlay permission={datatable.permissions?.create}>
      <Button {...datatableCreateWebButtonProps(datatable)} />
    </ProtectedOverlay>
  ) : null;

  return (
    <section>
      <ContentPlaceholder
        icon={datatable.contentPlaceholders?.noContent?.icon ?? "peopleOutline"}
        title={
          datatable.contentPlaceholders?.noContent?.title ??
          "No records created yet"
        }
        description={datatable.contentPlaceholders?.noContent?.description}
        action={action}
      />
    </section>
  );
};
