import { createContext } from "react";

import { Logger, LoggerContextType } from "./loggerTypes";

export const defaultLogger: Logger = {
  info: (message: string) => console.info(message),
  debug: (message: string) => console.debug(message),
  log: (message: string) => console.log(message),
  warning: (message: string) => console.warn(message),
  error: (message: string) => console.error(message),
  fatal: (message: string) => console.error(message),
};

export const LoggerContext = createContext<LoggerContextType>({
  logger: defaultLogger,
});
