import { getPlatformFunctions } from "../platformSpecific";

export const useHasPayment = (transactionId: string): boolean => {
  const { useApi } = getPlatformFunctions();
  const { data: relatedTransactions, isLoading } = useApi("listTransaction", {
    where: {
      relatedTransactionId: {
        equals: transactionId,
      },
    },
    selectAll: true,
  });

  if (isLoading || relatedTransactions?.data === undefined) {
    return false;
  }

  return relatedTransactions.data.length > 0;
};
