import { ClassManagerTextLogo, cn } from "shared/lib";

import letterGrey500 from "@/assets/class-manager-letter-grey-500.svg";
import letterGrey600 from "@/assets/class-manager-letter-grey-600.svg";
import letterGrey900 from "@/assets/class-manager-letter-grey-900.svg";
import letterWhite from "@/assets/class-manager-letter-white.svg";
import logoGrey500 from "@/assets/class-manager-logo-grey-500.svg";
import logoGrey600 from "@/assets/class-manager-logo-grey-600.svg";
import logoGrey900 from "@/assets/class-manager-logo-grey-900.svg";
import logoWhite from "@/assets/class-manager-logo-white.svg";
import { classManagerLogoSize } from "@/modules/common/logoSizes";

const logos = {
  white: logoWhite,
  "grey-500": logoGrey500,
  "grey-600": logoGrey600,
  "grey-900": logoGrey900,
};
const letters = {
  white: letterWhite,
  "grey-500": letterGrey500,
  "grey-600": letterGrey600,
  "grey-900": letterGrey900,
};

export const ClassManagerLogo = ({
  color,
  width, // eg. '100%' or '50px'
  height, // eg. '100%' or '50px'
  isExpanded = true,
  className,
}: ClassManagerTextLogo<string>) => {
  const src = isExpanded ? logos[color] : letters[color];

  return (
    <div
      className={cn(
        "flex w-full justify-center",
        {
          "pt-[6px]": !isExpanded,
        },
        className,
      )}>
      <img
        className={"aspect-[75/11] object-fill"}
        src={src}
        alt={"Class Manager logo"}
        style={{
          height:
            height ||
            (isExpanded ? classManagerLogoSize.standard.height : "80px"),
          width:
            width ||
            (isExpanded ? classManagerLogoSize.standard.width : "80px"),
        }}
      />
    </div>
  );
};
