import {
  AggregateTransactionDto,
  ListTransactionWhereParameter,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { DatatableFilterOperator } from "../../datatable";
import { generateMonthNames, generateYearRange } from "../../dateUtils";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { UseApi } from "../apiQueryFactory";

type ExtraSchema = {
  month: string;
  year: string;
  familyId: string;
};

export type TransactionFilterFormSchema = AggregateTransactionDto & ExtraSchema;

interface TransactionFilterFormDefinition {
  useApi: UseApi;
}

export const useTransactionFilterForm = ({
  useApi,
}: TransactionFilterFormDefinition) => {
  const { data: familyList } = useApi("listFamily", {
    selectAll: true,
    sort: { name: SortSchema.Asc },
  });

  return new FilterFormDefinitionBuilder<
    TransactionFilterFormSchema,
    ListTransactionWhereParameter
  >()
    .row(["month", "year"], [50])
    .select(
      "month",
      {
        label: "Month",
        data: generateMonthNames().map((month, index) => ({
          label: month,
          value: index + 1,
        })),
      },
      {
        filterField: "date",
        operator: DatatableFilterOperator.Month,
      },
    )
    .select(
      "year",
      {
        label: "Year",
        data: generateYearRange(0, 11).map(year => ({
          label: year.toString(),
          value: year,
        })),
      },
      {
        filterField: "date",
        operator: DatatableFilterOperator.Year,
      },
    )
    .multiSelect(
      "familyId",
      {
        label: "Select family",
        data: (familyList?.data ?? []).map(family => {
          return {
            label: family.name,
            value: family.id,
          };
        }),
        localSearch: true,
      },
      {
        filterField: "familyId",
        operator: DatatableFilterOperator.In,
      },
    )
    .getDefinition();
};
