import { PropsWithChildren } from "react";

import { ChartTitle } from "./ChartTitle";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { CollapsibleWidget } from "../../../ui/CollapsibleWidget";

export const ChartWidget = ({
  children,
  title,
  chartTitle,
  lastUpdated,
  loading,
  empty,
  emptyText,
}: PropsWithChildren<{
  title: string;
  chartTitle: string;
  lastUpdated: Date;
  loading: boolean;
  empty: boolean;
  emptyText: string;
}>) => {
  const { View } = useGenericComponents();

  return (
    <CollapsibleWidget
      title={title}
      loading={loading}
      empty={empty}
      emptyText={emptyText}
      content={
        <View className={"flex-col flex-1 space-y-4"}>
          <View>
            <ChartTitle title={chartTitle} lastUpdated={lastUpdated} />
          </View>
          <View className="h-96 w-full">{children}</View>
        </View>
      }
    />
  );
};
