import {
  PricingScheme,
  PricingSchemeValueDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import {
  makeUpdateClassPricingTierRequest,
  makeUpdateDurationPricingTierRequest,
  useClassPricingTierDefinition,
  useDurationPricingTierDefinition,
} from "../../formDefinitions/pricingTierForm";
import { useGenericComponents } from "../../GenericComponentsProvider";
import { DefaultEntityActions } from "../../useDefaultEntityActions";

export const UpdatePricingTierCard = ({
  api,
  entity,
  season,
  defaultActions,
}: {
  api: Api;
  entity: PricingSchemeValueDto;
  season: SeasonDto;
  defaultActions: DefaultEntityActions<PricingSchemeValueDto>;
}) => {
  const { GenericForm } = useGenericComponents();
  switch (season.pricingScheme) {
    case PricingScheme.ClassCount:
      return (
        <GenericForm
          apiRequest={makeUpdateClassPricingTierRequest({
            api,
            id: entity.id,
          })}
          defaultValues={{
            ...entity,
          }}
          formDefinitionHook={useClassPricingTierDefinition}
          onSuccess={defaultActions.onUpdateSuccess}
        />
      );
    case PricingScheme.TotalDuration:
      return (
        <GenericForm
          apiRequest={makeUpdateDurationPricingTierRequest({
            api,
            id: entity.id,
          })}
          defaultValues={{
            ...entity,
            hours: Math.floor(entity.quantity / 60),
            minutes: entity.quantity % 60,
          }}
          formDefinitionHook={useDurationPricingTierDefinition}
          onSuccess={defaultActions.onUpdateSuccess}
        />
      );
    default:
      throw new Error("Undefined pricing scheme");
  }
};
