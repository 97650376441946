import { ReactNode } from "react";

import { PermissionContext } from "./permissionContext";
import { Permission, permissionsToRoles } from "../../permission";
import { getPlatformFunctions } from "../../platformSpecific";
import { useAuthState } from "../AuthStateProvider";

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { account } = useAuthState();
  const { useApi } = getPlatformFunctions();

  const { data: accountPermissionResponse, isLoading } = useApi(
    "getAccountPermissions",
    {},
    { enabled: !!account },
  );

  const permissions: string[] = accountPermissionResponse?.permissions ?? [];

  const hasPermission = (permission: Permission) => {
    return (
      account?.permissionSet !== undefined &&
      permissionsToRoles[permission].includes(account.permissionSet)
    );
  };

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        hasPermission,
        isLoading,
      }}>
      {children}
    </PermissionContext.Provider>
  );
};
