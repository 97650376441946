import { PropsWithChildren, ReactNode, useEffect, useRef } from "react";

import { Breadcrumb, Breadcrumbs } from "shared/components";
import { cn, useBreakpoint } from "shared/lib";

import { GlobalHeader } from "@/modules/common/GlobalHeader";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { CompanyNav } from "@/modules/company/CompanyNav";

export const CompanyLayout = ({
  children,
  footer,
  alwaysShowGlobalHeader = true,
  datatablePage = false,
}: {
  children: ReactNode;
  footer?: ReactNode;
  alwaysShowGlobalHeader?: boolean;
  datatablePage?: boolean;
}) => {
  const breakpoints = useBreakpoint();

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex">
      <CompanyNav />
      <div
        className={cn("flex flex-grow flex-col overflow-auto", {
          "pb-28": !datatablePage && footer === undefined,
        })}>
        {alwaysShowGlobalHeader ? (
          <GlobalHeader hasSideNav={true} />
        ) : (
          breakpoints.md && <GlobalHeader hasSideNav={true} />
        )}

        {children}
        {footer ? (
          <div className="sticky bottom-0 left-0 right-0 border-t border-grey-300 bg-white shadow">
            {/* padding left is for hubspot */}
            <div className={"pl-14 xl:pl-0"}>{footer}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const CompanyTitleBar = ({
  children,
  isTopLevel,
}: {
  children: ReactNode;
  isTopLevel: boolean;
}) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-between text-heading5-600 text-grey-900",
        {
          "px-8 py-5": breakpoints.md,
          "px-5 py-3": !breakpoints.md,
          "py-3": !isTopLevel,
        },
      )}>
      {children}
    </div>
  );
};

interface CompanyTitleBarTitle {
  title: string;
}

export const CompanyTitleBarTitle = ({ title }: CompanyTitleBarTitle) => {
  return (
    <span className={"line-clamp-1 text-heading5-600 text-grey-900"}>
      {title}
    </span>
  );
};

export const ResponsiveCompanyTitleBarTitle = ({
  title,
}: CompanyTitleBarTitle) => {
  return (
    <div className="flex flex-grow items-center justify-between">
      <CompanyTitleBarTitle title={title} />
      <IconButton
        className="md:hidden"
        data-testid="back-icon-btn"
        variant="standard"
        icon="closeOutline"
        onClick={() => window.history.back()}
      />
    </div>
  );
};

export const CompanyContent = ({
  children,
  onWidthChange,
}: PropsWithChildren & { onWidthChange?: (width: number) => void }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onWidthChange) {
      const updateWidth = () => {
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          onWidthChange(rect.width);
        }
      };

      updateWidth(); // Update width initially

      const resizeObserver = new ResizeObserver(updateWidth);
      if (ref.current) {
        resizeObserver.observe(ref.current);
      }

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [onWidthChange]);

  return (
    <div className={"flex-grow px-5 pb-5 pt-4 md:px-8 md:pb-8"} ref={ref}>
      {children}
    </div>
  );
};

interface CompanyBreadCrumbsProps {
  crumbs: Breadcrumb[];
}

export const CompanyBreadCrumbs = ({ crumbs }: CompanyBreadCrumbsProps) => {
  return (
    <div className={"px-8 py-3 max-md:hidden"}>
      <Breadcrumbs items={crumbs} />
    </div>
  );
};
