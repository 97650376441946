import { PricingScheme } from "@justraviga/classmanager-sdk";

import { IconName } from "./availableIcons";
import { BannerIcon, BannerVariant } from "./bannerTypes";
import { colors } from "./colors";

export const pricingSchemeTypeText: Record<PricingScheme, string> = {
  [PricingScheme.ClassCount]:
    "Set the price per month for 1 or more classes a week taken by the same student.",
  [PricingScheme.TotalDuration]:
    "Each hourly rate applies up to and including that number of hours. For example, a rate for 2 hours covers up to 2 hours of classes.",
  [PricingScheme.None]: "",
};

export const pricingSchemeTypeVariant: Record<PricingScheme, BannerVariant> = {
  [PricingScheme.ClassCount]: "important",
  [PricingScheme.TotalDuration]: "default",
  [PricingScheme.None]: "default",
};

export const pricingSchemeDescriptionIcon: Record<
  PricingScheme,
  BannerIcon | undefined
> = {
  [PricingScheme.ClassCount]: { name: "pricetag", color: colors.white },
  [PricingScheme.TotalDuration]: {
    name: "informationCircle",
    color: colors.blue["600"],
  },
  [PricingScheme.None]: { name: "informationCircle" },
};

export const pricingSchemeTypeIcon: Record<PricingScheme, IconName> = {
  [PricingScheme.None]: "cash",
  [PricingScheme.ClassCount]: "personAdd",
  [PricingScheme.TotalDuration]: "time",
};

export const pricingSchemeDescription: Record<PricingScheme, string> = {
  [PricingScheme.None]: "Charge a fixed monthly fee for each class.",
  [PricingScheme.ClassCount]:
    "Charge per month based on the number of classes a student takes per week.",
  [PricingScheme.TotalDuration]:
    "Charge per month based on the number of hours of classes a student takes per week.",
};
