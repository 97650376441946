import { useEffect } from "react";

import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import {
  makeChargeCardOnFileFormRequest,
  useChargeCardOnFileForm,
} from "../formDefinitions/chargeCardOnFileForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface ChargeCardOnFileFormProps {
  familyId: string;
  onSuccess: () => void;
}

export const ChargeCardOnFileForm = ({
  familyId,
  onSuccess,
}: ChargeCardOnFileFormProps) => {
  const { api, useApi } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { setCreateButtonText, setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setCreateButtonText("Confirm");
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericForm
      formDefinitionHook={useChargeCardOnFileForm({ familyId, useApi })}
      onSuccess={onSuccess}
      defaultValues={{}}
      apiRequest={makeChargeCardOnFileFormRequest({ api, familyId })}
    />
  );
};
