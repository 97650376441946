import {
  ListRegistrationFeeWhereParameter,
  RegistrationFeeDto,
} from "@justraviga/classmanager-sdk";

import { DatatableFilterOperator } from "../../datatable/datatableTypes";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { getRepetitionIntervalOptions } from "../../registrationFeeUtils";

export type RegistrationFeeFilterFormSchema = Pick<
  RegistrationFeeDto,
  "repetitionInterval" | "archivedAt"
>;

export const useRegistrationFeeFilterForm = () =>
  new FilterFormDefinitionBuilder<
    RegistrationFeeFilterFormSchema,
    ListRegistrationFeeWhereParameter
  >()
    .multiSelect(
      "repetitionInterval",
      {
        label: "Frequency",
        data: getRepetitionIntervalOptions(),
      },
      {
        filterField: "repetitionInterval",
        operator: DatatableFilterOperator.In,
      },
    )
    .withArchiveFilter()
    .getDefinition();
