import { StaffScheduleWidget as SharedStaffScheduleWidget } from "shared/components";

import { Router } from "@/routing/router";

export const StaffScheduleWidget = ({ staffId }: { staffId: string }) => {
  return (
    <SharedStaffScheduleWidget
      goToAttendances={params =>
        Router.push("ClassLessonAttendances", {
          ...params,
          id: params.courseId,
        })
      }
      goToLesson={params =>
        Router.push("LessonDetails", { ...params, id: params.courseId })
      }
      staffId={staffId}
    />
  );
};
