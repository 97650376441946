import { useEffect } from "react";

import { useAuthState } from "shared/components";
import { getCountryCode } from "shared/lib";

import { RegistrationForm } from "@/modules/auth/companyRegistration/RegistrationForm";
import { TestimonialSection } from "@/modules/auth/companyRegistration/TestimonialSection";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import { Button } from "@/modules/common/ui/button/Button";
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/modules/common/ui/card/Card";
import { Router } from "@/routing/router";

interface RegisterCompanyPageProps {
  email?: string | undefined;
}

export const RegisterCompanyPage = ({ email }: RegisterCompanyPageProps) => {
  const { isLoggedIn } = useAuthState();
  const countryCode = getCountryCode();

  // redirect to dashboard if user is already logged in
  useEffect(() => {
    if (isLoggedIn) {
      Router.push("Home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we may have been given an email address in the query string, so we add that as a default value to the form
  const defaultValues = {
    email,
  };

  function goToLogin() {
    Router.push("Login");
  }

  // If we're already logged in, show nothing and the redirect above will take care of it
  if (isLoggedIn) {
    return null;
  }

  return (
    <NonAuthLayout size={"lg"}>
      <div className={"grid grid-cols-1 lg:grid-cols-2"}>
        <div
          className={
            "space-y-8 rounded-bl rounded-tl border-0 border-grey-300 bg-white px-8 py-10 lg:border lg:px-8 lg:py-12"
          }>
          <CardHeader>
            <ClassManagerLogo
              color="grey-900"
              height={classManagerLogoSize.standard.height}
              width={classManagerLogoSize.standard.width}
              className={"mx-auto"}
            />
          </CardHeader>
          <CardHeader>
            <CardTitle>Register your company</CardTitle>
          </CardHeader>
          <CardContent className={"space-y-8"}>
            <RegistrationForm
              defaultValues={defaultValues}
              countryCode={countryCode}
            />
          </CardContent>
          <CardFooter className="flex-col space-y-8 p-0">
            <p className={"text-label-400 text-grey-600"}>
              By clicking register you have read and agree with Class Manager’s
              Terms & conditions and Privacy Policy.
            </p>
            <div className={"flex items-center justify-center space-x-2"}>
              <p className={"text-body-400 text-grey-600"}>
                Already have an account?
              </p>
              <Button variant={"link"} onClick={goToLogin} text={"Log in"} />
            </div>
          </CardFooter>
        </div>
        <TestimonialSection />
      </div>
    </NonAuthLayout>
  );
};
