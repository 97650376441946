import { useEffect } from "react";

import {
  CreateDiscountSchemeValueRequest,
  DiscountSchemeDto,
  DiscountType,
  UpdateDiscountSchemeValueRequest,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useFormActions } from "../FormActionsProvider";

type Schema = Pick<CreateDiscountSchemeValueRequest, "amount">;

export const useDiscountSchemeTierForm = (type: DiscountType) => {
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();

  // it is important to define useEffect here to prevent the stack trace error
  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Add");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const builder = new FormDefinitionBuilder<Schema>().group("Discount", [
    "amount",
  ]);

  match(type)
    .with(DiscountType.Percent, () => {
      builder.integer("amount", {
        label: "Discount amount",
        required: true,
        prefix: "%",
      });
    })
    .with(DiscountType.Fixed, () => {
      builder.money("amount", {
        label: "Discount amount",
        keepZeroValue: true,
        required: true,
      });
    })
    .exhaustive();

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeDiscountSchemeTierCreateRequest =
  ({
    api,
    quantity,
    discountScheme,
  }: {
    api: Api;
    quantity: number;
    discountScheme: DiscountSchemeDto;
  }) =>
  (data: Schema) => {
    return api.discountSchemeValues.createDiscountSchemeValue({
      createDiscountSchemeValueRequest: {
        amount: data.amount,
        discountSchemeId: discountScheme.id,
        quantity: quantity,
      } as CreateDiscountSchemeValueRequest,
    });
  };

export const makeDiscountSchemeTierUpdateRequest =
  ({
    api,
    id,
    discountScheme,
  }: {
    api: Api;
    id: string;
    discountScheme: DiscountSchemeDto;
  }) =>
  (data: Schema) => {
    return api.discountSchemeValues.updateDiscountSchemeValue({
      id,
      updateDiscountSchemeValueRequest: {
        amount: data.amount,
        discountSchemeId: discountScheme.id,
      } as UpdateDiscountSchemeValueRequest,
    });
  };
