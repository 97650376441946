import {
  CompanyIndustry,
  CompanyRegistrationDto,
  CompanySize,
  RegisterCompanyRequest,
} from "@justraviga/classmanager-sdk";

import {
  Api,
  CountryCodeParam,
  enumLabel,
  FormDefinitionBuilder,
  getTimezone,
  toCountryCodeEnum,
} from "shared/lib";

import { Banner } from "@/modules/common/ui/Banner";

export interface CompanyRegistrationDtoWithFormData
  extends CompanyRegistrationDto {
  form: RegisterCompanyRequest;
}

export const useRegistrationFormDefinition = (
  withoutAuthFields: boolean = false,
) => {
  const industryOptions: { label: string; value: CompanyIndustry }[] =
    Object.values(CompanyIndustry)
      .filter(industry => industry !== "other") // we'll add "other" to the end
      .map(industry => ({
        label: enumLabel("industry", industry),
        value: industry,
      }));
  // we want to make sure "other" is the last option
  industryOptions.push({
    label: "Other",
    value: CompanyIndustry.Other,
  });

  const sizeOptions = Object.values(CompanySize).map(size => ({
    label: enumLabel("companySize", size),
    value: size,
  }));

  const form = new FormDefinitionBuilder<RegisterCompanyRequest>();

  if (!withoutAuthFields) {
    form.contentBlock(
      "before",
      "name",
      <Banner
        className={"-mt-2 mb-4"}
        content={
          "You are currently logged in. When you create a new company we will add it to your existing account."
        }
        variant={"info"}
      />,
    );
  }

  form.text("name", {
    label: "Company name",
    required: true,
  });

  if (withoutAuthFields) {
    form
      .text("email", {
        label: "Email address",
        type: "email",
        required: true,
      })
      .password("password", {
        label: "Password",
        required: true,
        description: "Minimum 8 characters",
      })
      .row(["firstname", "lastname"])
      .text("firstname", { label: "First name", required: true })
      .text("lastname", { label: "Last name", required: true });
  }

  form
    .text("phone", { label: "Phone", required: true })
    .select("industry", {
      required: true,
      label: "Industry",
      data: industryOptions,
    })
    .select("size", {
      required: true,
      label: "How many students do you have?",
      data: sizeOptions,
    });

  return form.getDefinition();
};

export const useRegistrationForm = () => useRegistrationFormDefinition(true);
export const useRegistrationFormWithAuth = () =>
  useRegistrationFormDefinition(false);

export const makeRegistrationRequest =
  ({ api, countryCode }: { api: Api; countryCode: CountryCodeParam }) =>
  (formData: RegisterCompanyRequest) => {
    formData.countryCode = toCountryCodeEnum(countryCode);
    formData.timezone = getTimezone();

    return api.auth
      .registerCompany({
        registerCompanyRequest: formData,
      })
      .then<CompanyRegistrationDtoWithFormData>(
        (response: CompanyRegistrationDto) => {
          return {
            ...response,
            form: formData,
          };
        },
      );
  };
