import { useGenericComponents } from "../../../GenericComponentsProvider";

export const AccountSkeletonCard = () => {
  const { Text, View } = useGenericComponents();

  return (
    <View
      className={
        "flex flex-row h-20 items-center space-x-2 rounded border border-grey-300 p-2"
      }>
      <View className={"h-16 w-16 rounded-sm bg-grey-300 "} />
      <View className={"flex-1 space-y-2"}>
        <Text className="w-2/3 rounded-sm bg-grey-300 text-body-600">
          &nbsp;
        </Text>
        <Text className="w-3/4 rounded-sm bg-grey-300 text-label-400">
          &nbsp;
        </Text>
      </View>
    </View>
  );
};
