import { createContext, ReactNode, useContext } from "react";

import { Breadcrumbs, BreadcrumbsProps } from "shared/components";
import { cn, colors, Permission, WebActionMenuProps } from "shared/lib";

import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyContent,
  CompanyTitleBar,
} from "@/modules/company/CompanyLayout";

interface Permissions {
  edit?: Permission;
  restore?: Permission;
}

// eslint-disable-next-line complexity
export const DetailPageLayout = ({
  title,
  mobileTitle,
  children,
  crumbs,
  header,
  beforeActions,
  actions,
  editAction,
  restoreAction,
  additionalActions,
  primaryAction,
  archivedEntityName,
  isArchived,
  isDesktop,
  spacingVariant = "default",
  onWidthChange,
  permissions,
}: {
  title?: string | ReactNode;
  mobileTitle?: string | ReactNode;
  children: ReactNode;
  crumbs: BreadcrumbsProps["items"];
  isArchived?: boolean;
  header?: ReactNode;
  beforeActions?: ReactNode;
  editAction?: () => void;
  restoreAction?: () => void;
  additionalActions?: ReactNode;
  primaryAction?: PrimaryActionItemProps;
  archivedEntityName: string;
  actions?: WebActionMenuProps["items"];
  isDesktop: boolean;
  spacingVariant?: "default" | "tight" | "none";
  onWidthChange?: (width: number) => void;
  permissions?: Permissions;
}) => {
  const renderMobileTitle = (title: string | ReactNode) => {
    if (typeof title === "string") {
      return <h5 className={"line-clamp-1 text-heading5-600"}>{title}</h5>;
    }

    return title;
  };

  return (
    <>
      <CompanyTitleBar isTopLevel={false}>
        {isDesktop ? (
          <Breadcrumbs items={crumbs} />
        ) : (
          <>
            <div className={"-ml-2 flex flex-row items-center gap-x-2"}>
              <IconButton
                icon={"chevronBackOutline"}
                variant={"standard"}
                size={"lg"}
                onClick={() => history.back()}
              />
              {mobileTitle
                ? renderMobileTitle(mobileTitle)
                : title
                  ? renderMobileTitle(title)
                  : null}
            </div>
            <div className={"flex items-center space-x-4"}>
              {beforeActions && <div>{beforeActions}</div>}
              {primaryAction && (
                <div>
                  <IconButton
                    icon={primaryAction.icon}
                    border={"rounded"}
                    onClick={() => primaryAction.onClick()}
                    variant={"secondary-fill"}
                  />
                </div>
              )}
              {actions && (
                <ActionMenu
                  trigger={
                    <div className="-mr-2">
                      <IconButton
                        variant="standard"
                        icon={"ellipsisHorizontal"}
                        size="lg"
                        aria-label="Close"
                      />
                    </div>
                  }
                  width={"w-80"}
                  items={actions}
                />
              )}
            </div>
          </>
        )}
      </CompanyTitleBar>
      <CompanyContent onWidthChange={onWidthChange}>
        <div
          className={cn(
            "mt-2 flex h-full flex-col md:mt-4",
            {
              "gap-y-8": spacingVariant === "default",
              "gap-y-5": spacingVariant === "tight",
              "gap-y-0": spacingVariant === "none",
            },
            `md:gap-y-8`,
          )}>
          {(header || isDesktop) && (
            <div className={"flex flex-row justify-between gap-4"}>
              {header}
              {isDesktop && title && (
                <h1 className={"line-clamp-1 text-heading5-600"}>{title}</h1>
              )}
              {isDesktop &&
                (beforeActions ||
                  actions ||
                  editAction ||
                  additionalActions) && (
                  <div className={"flex flex-row items-center space-x-4"}>
                    {beforeActions && <div>{beforeActions}</div>}
                    {actions && (
                      <ActionMenu
                        trigger={
                          <Button
                            size={"sm"}
                            text={"Actions"}
                            role="staff-actions"
                            rightIcon={"chevronDown"}
                            variant={"secondary"}
                          />
                        }
                        width={"w-80"}
                        items={actions}
                      />
                    )}
                    {editAction && (
                      <ProtectedOverlay permission={permissions?.edit}>
                        <Button
                          size={"sm"}
                          text={"Edit"}
                          variant={"secondary"}
                          onClick={editAction}
                        />
                      </ProtectedOverlay>
                    )}

                    {additionalActions && additionalActions}
                  </div>
                )}
            </div>
          )}
          {isArchived && (
            <>
              <div className={"w-full"}>
                <Banner
                  className={"items-center"}
                  variant={"important"}
                  icon={{ name: "alertCircle", color: colors.white }}
                  content={
                    <div
                      className={"flex flex-row items-center justify-between"}>
                      <span>This {archivedEntityName} has been archived</span>
                      {restoreAction && (
                        <ProtectedOverlay permission={permissions?.restore}>
                          <Button
                            variant={"tertiary"}
                            text={"Restore"}
                            className={"text-white decoration-white"}
                            onClick={restoreAction}
                          />
                        </ProtectedOverlay>
                      )}
                    </div>
                  }
                />
              </div>
            </>
          )}
          {children}
        </div>
      </CompanyContent>
    </>
  );
};

export const DetailPageGridContext = createContext<number>(3);

const useDetailPageGridCols = () => useContext(DetailPageGridContext);

export const DetailPageGrid = ({
  children,
  cols = 3,
}: {
  children: ReactNode;
  cols?: 2 | 3;
}) => {
  return (
    <DetailPageGridContext.Provider value={cols}>
      <div
        className={cn("grid h-full grid-cols-1 gap-x-8", {
          "md:grid-cols-2": cols === 2,
          "md:grid-cols-3": cols === 3,
        })}>
        {children}
      </div>
    </DetailPageGridContext.Provider>
  );
};

export const DetailPageGridRightColumn = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      className={
        "mt-8 flex w-full flex-col items-end gap-y-8 md:col-span-1 md:mt-0"
      }>
      {children}
    </div>
  );
};

export const DetailPageGridLeftColumn = ({
  children,
  spacingVariant = "default",
}: {
  children: ReactNode;
  spacingVariant?: "default" | "tight";
}) => {
  const cols = useDetailPageGridCols();
  return (
    <div
      className={cn(
        "flex w-full flex-col",
        {
          "gap-y-8": spacingVariant === "default",
          "gap-y-5": spacingVariant === "tight",
        },
        "md:gap-y-8",
        {
          "md:col-span-1": cols === 2,
          "md:col-span-2": cols === 3,
        },
      )}>
      {children}
    </div>
  );
};
