import type { Hit } from "instantsearch.js";

import {
  colors,
  formatDate,
  SearchCollection,
  SeasonDocument,
} from "shared/lib";

import { SearchResult } from "@/modules/common/search/searchResults/SearchResult";
import { StyledHighlight } from "@/modules/common/search/StyledHighlight";
import { Icon } from "@/modules/common/ui/icon/Icon";

interface SeasonSearchResultProps {
  hit: Hit<SeasonDocument>;
}

export const SeasonSearchResult = ({
  hit: season,
}: SeasonSearchResultProps) => {
  return (
    <SearchResult
      type={SearchCollection.season}
      title={<StyledHighlight attribute="name" hit={season} />}
      description={`${formatDate(season.startAt, "dayMonthYear")} - ${formatDate(season.endAt, "dayMonthYear")}`}
      id={season.id}>
      <div className={"rounded-full bg-brand-100 p-2"}>
        <Icon size={20} name={"calendarClear"} color={colors.brand[800]} />
      </div>
    </SearchResult>
  );
};
