import * as ReactMenubar from "@radix-ui/react-menubar";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { ActionMenuItemContainer } from "@/modules/common/ui/actionMenu/ActionMenuItemContainer";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const ActionMenuDestructiveItems = ({
  items,
}: {
  items: ActionMenuItemProps[];
}) => {
  return items.map(item => {
    return (
      <ProtectedOverlay
        protector={item.protector}
        permission={item.permission}
        key={item.title}>
        <ReactMenubar.Item
          disabled={item.disabled}
          onClick={e => {
            e.stopPropagation();
            item.onClick && item.onClick();
          }}
          className="cursor-pointer p-2 py-3 hover:rounded-sm hover:border-0 hover:bg-grey-100 focus-visible:outline-0">
          <ActionMenuItemContainer item={item} />
        </ReactMenubar.Item>
      </ProtectedOverlay>
    );
  });
};
