import { FC, useMemo } from "react";

import { ChartAxisDto, ChartDataDto } from "@justraviga/classmanager-sdk";

import { ChartWidget } from "./ChartWidget";
import { dateSubtract, dateToday, dateToMonth } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";

interface Props {
  BillingHistoryChart: FC<{ data: BillingHistoryChartData[] }>;
}

export interface BillingHistoryChartData {
  month: string;
  transactions: number;
  creditNotes: number;
  totalCharges: number;
  payments: number;
  refunds: number;
  totalPayments: number;
}

const BillingHistoryWidget = ({ BillingHistoryChart }: Props) => {
  const { useApi } = getPlatformFunctions();
  const { data, isLoading } = useApi("chartByChargeAndPaymentTransaction", {});
  const chartData = useMemo(
    () => (data ? generateChartData(data) : []),
    [data],
  );

  return (
    <ChartWidget
      title="Billing"
      chartTitle="Last 6 months"
      lastUpdated={new Date()}
      loading={isLoading}
      empty={!data?.length}
      emptyText="Nothing to show here">
      <BillingHistoryChart data={chartData} />
    </ChartWidget>
  );
};

const monthsToDisplay = 6;

const extractValue = (monthData: ChartDataDto[], label: string): number =>
  monthData.flatMap(item => item.values).find(item => item.label === label)
    ?.value || 0;

const generateChartData = (data: ChartAxisDto[]) => {
  const currentDate = dateToday();

  return Array.from({ length: monthsToDisplay }, (_, index) => {
    const date = dateSubtract(
      currentDate,
      monthsToDisplay - 1 - index,
      "month",
    );
    const month = dateToMonth(date);
    const monthData = data.find(item => item.name === month)?.data || [];

    const transactions = extractValue(monthData, "Transactions");
    const creditNotes = extractValue(monthData, "Credit Notes");
    const totalCharges = transactions + creditNotes;
    const payments = extractValue(monthData, "Payments");
    const refunds = extractValue(monthData, "Refunds");
    const totalPayments = payments + refunds;

    return {
      month,
      transactions,
      creditNotes,
      totalCharges,
      payments,
      refunds,
      totalPayments,
    };
  });
};

const PermissionChecked = withPermissionCheck(
  "financial:view",
  BillingHistoryWidget,
);

export { PermissionChecked as BillingHistoryWidget };
