import { match } from "ts-pattern";

import { useStripeStatus } from "../../../../payments/useStripeStatus";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useAuthState } from "../../../AuthStateProvider";
import { BannerProps } from "../../../interfaces";

export const StripeBanner = ({ Banner }: { Banner: React.FC<BannerProps> }) => {
  const { account } = useAuthState();

  if (account?.permissionSet !== "owner") {
    return null;
  }
  return <StripeBannerAlert Banner={Banner} />;
};

const StripeBannerAlert = ({ Banner }: { Banner: React.FC<BannerProps> }) => {
  const { eventTracker } = getPlatformFunctions();
  const { status, isLoading, stripeAccount, redirectToStripe } =
    useStripeStatus();

  if (isLoading || stripeAccount === undefined) {
    return null;
  }

  return match(status)
    .with("StripeAccountPending", () => null)
    .with("StripeAccountFetchError", () => (
      <LoadingErrorBanner Banner={Banner} />
    ))
    .with("StripeAccountHasErrors", () => {
      eventTracker(
        "StripeAccountHasErrors",
        stripeAccount.requirements?.errors,
      );
      return (
        <HasRequirementErrorsBanner
          Banner={Banner}
          redirectToStripe={redirectToStripe}
        />
      );
    })
    .with("StripeAccountNotReady", () => {
      eventTracker("StripeAccountNotReady", stripeAccount.requirements);
      return (
        <HasRequirementsBanner
          Banner={Banner}
          redirectToStripe={redirectToStripe}
        />
      );
    })
    .with("StripeAccountReady", () => {
      eventTracker("StripeAccountReady", {});
      return <IsPendingVerificationBanner Banner={Banner} />;
    })
    .with("StripeAccountOkay", () => null)
    .exhaustive();
};

interface StripeBannerProps {
  Banner: React.FC<BannerProps>;
  redirectToStripe?: () => void;
}

const LoadingErrorBanner = ({ Banner }: StripeBannerProps) => {
  return (
    <Banner
      content={"Unable to load Stripe account. Please try again later."}
      variant="warning"
    />
  );
};

const HasRequirementErrorsBanner = ({
  Banner,
  redirectToStripe = () => {},
}: StripeBannerProps) => {
  return (
    <Banner
      content={
        "Payouts are at risk of being blocked. You need to provide additional company information."
      }
      variant="warning"
      action={{
        text: "Go to set up",
        onClick: redirectToStripe,
      }}
    />
  );
};

const HasRequirementsBanner = ({
  Banner,
  redirectToStripe = () => {},
}: StripeBannerProps) => {
  return (
    <Banner
      icon={{ name: "warning", color: "white" }}
      content={
        "You need to integrate our online payment provider. Your members won't be able to use the portal until this is complete."
      }
      variant="critical"
      action={{
        text: "Go to set up",
        onClick: redirectToStripe,
      }}
    />
  );
};

const IsPendingVerificationBanner = ({
  Banner,
}: {
  Banner: React.FC<BannerProps>;
}) => {
  return (
    <Banner
      icon={{ name: "time", color: "black" }}
      content={
        "Class Manager Payments verification is processing. Check back soon."
      }
      variant="warning"
    />
  );
};
