import { PropsWithChildren } from "react";

import { Breadcrumb, ContentPlaceholder } from "shared/components";
import { EmptyEntity, WaiverEntity } from "shared/lib";
import { Breakpoint } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  ResponsiveCompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { LockedWaiverIcon } from "@/modules/company/waivers/LockedWaiverIcon";
import { useWaiverDetailsPage } from "@/modules/company/waivers/useWaiverDetailsPage";
import { WaiverChip } from "@/modules/company/waivers/WaiverChip";

const WaiverDetailsContentWrapper = ({ children }: PropsWithChildren) => (
  <div className={"space-y-4 rounded border border-grey-300 p-4"}>
    {children}
  </div>
);

interface WaiverDetailsContentProps {
  isLoading: boolean;
  waiver: WaiverEntity | EmptyEntity;
}

const WaiverDetailsContent = ({
  isLoading,
  waiver,
}: WaiverDetailsContentProps) => {
  if (isLoading) {
    return (
      <WaiverDetailsContentWrapper>
        <LoadingSpinnerDark />
      </WaiverDetailsContentWrapper>
    );
  }

  if (waiver.type !== "defined") {
    return (
      <ContentPlaceholder
        icon={"helpCircleOutline"}
        title={"This page couldn’t be loaded"}
        description={"This page is unavailable, please check back soon."}
        action={
          <Button
            variant={"brand"}
            onClick={() => window.history.back()}
            text={"Go back"}
          />
        }
      />
    );
  }

  return (
    <WaiverDetailsContentWrapper>
      <div className={"flex gap-2 break-words text-grey-600"}>
        <p role="waiver-detail-name" className="line-clamp-1 text-label-400">
          <span>{waiver.props.name}</span>
        </p>
        <LockedWaiverIcon isLocked={waiver.isLocked()} />
      </div>
      <div className={"flex justify-start pb-2 text-body-400"}>
        <WaiverChip waiver={waiver} />
      </div>
      <p className={"whitespace-pre-wrap text-body-400"} role="waiver-content">
        {waiver.props.content}
      </p>
    </WaiverDetailsContentWrapper>
  );
};

interface WaiverDetailsRestoreBannerProps {
  isArchived: boolean;
  restore: () => void;
}

const WaiverDetailsRestoreBanner = ({
  isArchived,
  restore,
}: WaiverDetailsRestoreBannerProps) => {
  return isArchived ? (
    <div className="px-5 py-5 md:px-8">
      <Banner
        variant={"important"}
        content={"This waiver has been archived"}
        action={{
          text: "Restore",
          onClick: () => restore(),
        }}
      />
    </div>
  ) : null;
};

interface WaiverDetailsActions {
  menuItems: ActionMenuItemProps[];
  breakpoints: Record<Breakpoint, boolean>;
  showEditForm: () => void;
}

const WaiverDetailsActions = ({
  menuItems,
  breakpoints,
  showEditForm,
}: WaiverDetailsActions) => {
  return (
    <div className="flex gap-4">
      {menuItems.length > 0 && (
        <ActionMenu
          trigger={
            breakpoints.md ? (
              <Button
                text={"Actions"}
                aria-label={"Open action menu"}
                rightIcon={"chevronDown"}
                variant={"secondary"}
                size={"sm"}
              />
            ) : (
              <IconButton
                size={"lg"}
                icon={"ellipsisHorizontal"}
                variant={"standard"}
                aria-label={"Open action menu"}
              />
            )
          }
          items={menuItems}
        />
      )}
      <Button
        className={"max-md:hidden"}
        variant={"secondary"}
        size={"sm"}
        onClick={() => showEditForm()}
        text={"Edit"}
        data-testid="edit-button"
      />
    </div>
  );
};

interface WaiverDetailsBaseProps {
  actionMenuItems: ActionMenuItemProps[];
  breakpoints: Record<Breakpoint, boolean>;
  breadcrumbs: Breadcrumb[];
  isLoading: boolean;
  waiver: WaiverEntity | EmptyEntity;
  restore: () => void;
  showEditForm: () => void;
}

const WaiverDetailsLayout = ({
  actionMenuItems,
  breakpoints,
  breadcrumbs,
  isLoading,
  waiver,
  restore,
  showEditForm,
}: WaiverDetailsBaseProps) => (
  <CompanyLayout alwaysShowGlobalHeader={false}>
    <CompanyBreadCrumbs crumbs={breadcrumbs} />
    <CompanyTitleBar isTopLevel={false}>
      <div className={"flex w-auto grow justify-between gap-4"}>
        <ResponsiveCompanyTitleBarTitle title={"Waiver Details"} />
        <WaiverDetailsActions
          menuItems={actionMenuItems}
          breakpoints={breakpoints}
          showEditForm={showEditForm}
        />
      </div>
    </CompanyTitleBar>
    <WaiverDetailsRestoreBanner
      isArchived={waiver.isArchived()}
      restore={restore}
    />
    <CompanyContent>
      <WaiverDetailsContent isLoading={isLoading} waiver={waiver} />
    </CompanyContent>
  </CompanyLayout>
);

interface WaiverDetailsProps {
  id: string;
  from?: string;
}

export const WaiverDetails = ({ id, from }: WaiverDetailsProps) => {
  const {
    waiver,
    isLoading,
    actionMenuItems,
    breadcrumbs,
    breakpoints,
    restore,
    showEditForm,
  } = useWaiverDetailsPage({ id, from });

  return (
    <WaiverDetailsLayout
      breakpoints={breakpoints}
      showEditForm={showEditForm}
      breadcrumbs={breadcrumbs}
      actionMenuItems={actionMenuItems}
      restore={restore}
      isLoading={isLoading}
      waiver={waiver}
    />
  );
};
