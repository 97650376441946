import { ReactNode } from "react";

import {
  AggregateClassDto,
  FamilyDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { FamilyScheduleWidget } from "./FamilyScheduleWidget";
import { colors } from "../../../colors";
import { useAuthenticatedAccountEntity } from "../../../hooks/useAuthenticatedAccountEntity";
import { formatMoneyFromInteger } from "../../../intlFormatter";
import { getPlatformFunctions } from "../../../platformSpecific";
import { useAuthState } from "../../AuthStateProvider";
import { useGenericComponents } from "../../GenericComponentsProvider";
import { QuickActionButton } from "../../ui/QuickActionButton";

export const FamilyDashboard = ({
  goToCourse,
  goToEnrol,
  goToAddStudent,
  goToMakePayment,
  openPaymentMethodForm,
  LoadingSpinner,
}: {
  goToCourse: (course: AggregateClassDto, season: SeasonDto) => void;
  goToEnrol: () => void;
  goToAddStudent: (family: FamilyDto) => void;
  goToMakePayment: (accountId: string, amount: number) => void;
  openPaymentMethodForm: (familyId: string) => void;
  LoadingSpinner: ReactNode;
}) => {
  const { BaseCard, Banner, Button, View, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();
  const { account, user } = useAuthState();

  const family = useAuthenticatedAccountEntity() as FamilyDto;

  const { data: balanceData, isLoading: isLoadingBalance } = useApi(
    "getBalance",
    {
      familyId: account?.entityId ?? "",
    },
    { enabled: !!account?.entityId },
  );

  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useApi(
    "listStripePaymentMethod",
    {
      family: account?.entityId || "",
    },
    { enabled: !!account?.entityId },
  );

  const showPaymentMethodsBanner =
    !isLoadingPaymentMethods && (paymentMethods ?? []).length === 0;

  if (!account) {
    return null;
  }

  return (
    <View>
      <View className="py-5">
        <Text className="text-heading4-600 font-semibold text-grey-900">
          Hello, {user?.firstname} 👋
        </Text>
      </View>
      <View className="flex flex-row items-center justify-start space-x-5">
        <View className="w-24">
          <QuickActionButton
            icon="calendarClear"
            text="Enroll for classes"
            onClick={goToEnrol}
            variant="brand"
          />
        </View>
        <View className="w-24">
          <QuickActionButton
            icon="personAddOutline"
            text="Create new student"
            onClick={() => goToAddStudent(family)}
            variant="default"
          />
        </View>
      </View>
      {isLoadingBalance || isLoadingPaymentMethods ? (
        LoadingSpinner
      ) : (
        <>
          {showPaymentMethodsBanner && (
            <View className="mt-8 flex flex-col">
              <Banner
                variant="brand"
                icon={{
                  name: "alertCircle",
                  color: colors.white,
                }}
                title={"No saved card"}
                content="You need to add a payment method as soon as possible."
                action={{
                  text: "Add",
                  onClick: () => openPaymentMethodForm(account.entityId!),
                }}
              />
            </View>
          )}

          <View className="mt-8">
            <BaseCard
              fillColor={colors.grey["100"]}
              bodySlot={
                <View>
                  <View className="flex flex-row items-center justify-between">
                    <Text className="text-heading5-600 font-semibold text-grey-900">
                      {formatMoneyFromInteger(balanceData?.balance ?? 0)}
                    </Text>
                    <Button
                      variant="brand"
                      text="Make a payment"
                      onClick={() =>
                        goToMakePayment(
                          account.entityId!,
                          balanceData?.balance ?? 0,
                        )
                      }
                    />
                  </View>
                  <Text className="mt-2 text-body-400 text-grey-900">
                    Account balance
                  </Text>
                </View>
              }
            />
          </View>
        </>
      )}
      <View className="mt-8">
        <FamilyScheduleWidget
          goToCourse={goToCourse}
          familyId={account.entityId!}
        />
      </View>
    </View>
  );
};
