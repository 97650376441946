import { LocationDto, RoomDto, SortSchema } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export const useLocationsAndRooms = (
  options: { onlyArchived?: boolean; includeArchived?: boolean } = {
    onlyArchived: false,
    includeArchived: false,
  },
) => {
  const { useApi } = getPlatformFunctions();

  const { data: locations, isLoading: isLoadingLocations } = useApi(
    "listLocation",
    {
      sort: {
        name: SortSchema.Asc,
      },
      onlyArchived: options.onlyArchived,
      includeArchived: options.includeArchived,
      selectAll: true,
    },
  );

  const { data: rooms, isLoading: isLoadingRooms } = useApi(
    "listRoom",
    {
      where: {
        locationId: {
          in: locations?.data.map((location: LocationDto) => location.id),
        },
      },
      sort: {
        name: SortSchema.Asc,
      },
      selectAll: true,
      onlyArchived: options.onlyArchived,
    },
    { enabled: !!locations },
  );

  const isEmpty = locations === undefined || locations.data.length === 0;

  function roomCountForLocation(location: LocationDto) {
    if (rooms === undefined) {
      return 0;
    }

    return rooms?.data.filter(
      (room: RoomDto) => room.locationId === location.id,
    ).length;
  }

  return {
    locations: locations?.data ?? [],
    rooms: rooms?.data ?? [],
    roomCountForLocation,
    isEmpty,
    isLoading: isLoadingLocations || isLoadingRooms,
  };
};
