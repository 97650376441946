import { getPlatformFunctions } from "../platformSpecific";

export const useSharedFamilyPaymentMethods = (familyId: string) => {
  const { useApi } = getPlatformFunctions();
  const { data: paymentMethods, ...rest } = useApi(
    "listStripePaymentMethod",
    {
      family: familyId,
    },
    {
      enabled: !!familyId,
    },
  );

  return {
    ...rest,
    paymentMethods: (paymentMethods || []).sort(a => (a.isDefault ? -1 : 1)),
  };
};
