import { useContext } from "react";

import {
  SearchClientContext,
  SearchDialogContext,
  SearchStatusContext,
} from "./searchContext";

export function useSearchStatus() {
  const context = useContext(SearchStatusContext);
  if (!context) {
    throw new Error("useSearchStatus must be used within a SearchProvider");
  }
  return context;
}

export function useSearchDialog() {
  const context = useContext(SearchDialogContext);
  if (!context) {
    throw new Error("useSearchDialog must be used within a SearchProvider");
  }
  return context;
}

export function useSearchClient() {
  const context = useContext(SearchClientContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
}
