import { AccountSelectionContent } from "shared/components";

import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Card, CardContent, CardHeader } from "@/modules/common/ui/card/Card";
import { Version } from "@/modules/common/Version";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { Router } from "@/routing/router";

export const AccountSelectionPage = () => {
  const goToLogin = () => Router.push("Login");
  const goToLogout = () => Router.push("Logout");
  const onAccountChange = () => Router.push("Home");

  return (
    <NonAuthLayout>
      <Card className="min-w-[100%] space-y-8 overflow-y-auto border-0 px-5 py-5 md:min-w-[480px] md:border md:px-8 md:py-12">
        <CardHeader className="relative flex flex-row justify-center">
          <div className={"flex w-full items-center justify-center"}>
            <div className={"absolute left-0 flex items-center"}>
              <IconButton
                icon={"chevronBackOutline"}
                variant={"standard"}
                onClick={goToLogin}
              />
            </div>
            <ClassManagerLogo
              color={"grey-900"}
              height={classManagerLogoSize.standard.height}
              width={classManagerLogoSize.standard.width}
            />
          </div>
        </CardHeader>
        <CardContent className="space-y-8">
          <AccountSelectionContent
            onAccountChange={onAccountChange}
            onLogout={goToLogout}
            autoSelectSingleAccount={true}
            Version={Version}
            CompanyLogo={CompanyLogo}
            showLoginLink={false}
          />
        </CardContent>
      </Card>
    </NonAuthLayout>
  );
};
