import { useEffect, useState } from "react";

import {
  dayMonthOrderForLocale,
  DayMonthPickerProps,
  defaultDaysInMonths,
  IntlFormatter,
} from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";
import { Select } from "@/modules/common/form/select/Select";

export const DayMonthPicker = ({
  description,
  error = false,
  label,
  name,
  onBlur,
  onChange,
  required = false,
  locale,
  value = null,
}: DayMonthPickerProps) => {
  if (value !== null && !/^\d{2}-\d{2}$/.test(value)) {
    throw new Error(`Invalid value for DayMonthPicker: ${value}`);
  }

  const dateFormatter = new IntlFormatter({
    timezone: "UTC",
    locale,
    use12HourClock: false,
    currency: "USD", // Irrelevant for this component
  });

  const parts = value ? value.split("-").map(Number) : null;
  const initialMonth = parts ? parts[0] : null;
  const initialDay = parts ? parts[1] : null;

  const [month, setMonth] = useState<number | null>(initialMonth);
  const [day, setDay] = useState<number | null>(initialDay);

  const monthError = error || (month === null && day !== null);
  const dayError = error || (day === null && month !== null);

  const outputValue =
    month !== null && day !== null
      ? `${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`
      : null;

  useEffect(() => {
    if (onChange) {
      if (outputValue !== value) {
        onChange(outputValue);
      }
    }
  }, [onChange, outputValue, value]);

  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const days = Array.from(
    { length: month ? defaultDaysInMonths[month - 1] : 31 },
    (_, i) => i + 1,
  );

  const monthPicker = (
    <Select
      error={monthError}
      name={`${name}-month`}
      placeholder="Month"
      data={months.map(monthNumber => ({
        value: monthNumber,
        label: dateFormatter.formatDate(
          `2000-${monthNumber.toString().padStart(2, "0")}-01`,
          "monthLong",
        ),
      }))}
      value={month === null ? undefined : month}
      onSelect={v => {
        setMonth(v === null ? null : Number(v));
        const maxDay = defaultDaysInMonths[Number(v) - 1];
        if (day && day > maxDay) {
          setDay(maxDay);
        }
      }}
      onBlur={onBlur}
    />
  );

  const dayPicker = (
    <Select
      error={dayError}
      name={`${name}-day`}
      placeholder="Day"
      data={days.map(dayNumber => ({
        value: dayNumber,
        label: dayNumber.toString(),
      }))}
      value={day === null ? undefined : day}
      onSelect={v => setDay(v === null ? null : Number(v))}
      onBlur={onBlur}
    />
  );

  const pickers =
    dayMonthOrderForLocale(locale) === "month-day"
      ? [monthPicker, dayPicker]
      : [dayPicker, monthPicker];

  return (
    <Label text={label} required={required} useNativeLabel={false}>
      <div className="flex flex-row gap-x-5">
        {pickers.map((picker, i) => (
          <div key={i} className="flex-1">
            {picker}
          </div>
        ))}
      </div>
      <InputDescription description={description} />
    </Label>
  );
};
