import { FormEvent, useEffect, useRef } from "react";

import {
  cn,
  displayApiValidationErrors,
  FormDefinition,
  useFormBuilderValues,
} from "shared/lib";

import { FormGroup } from "@/modules/common/formBuilder/FormGroup";
import { Button } from "@/modules/common/ui/button/Button";

export const useFormBuilder = <Schema extends object>(
  definition: FormDefinition<Schema>,
  handleKeyboardSubmit: (data: Schema) => void,
  defaultValues: Partial<Schema>,
) => {
  const advancedFieldsRef = useRef<HTMLDivElement>(null);

  const {
    advancedGroups,
    formHandlers,
    regularGroups,
    showAdvanced,
    setShowAdvanced,
    watchedValues,
  } = useFormBuilderValues({
    defaultValues,
    definition,
  });

  useEffect(() => {
    if (showAdvanced) {
      advancedFieldsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showAdvanced]);

  /**
   * Handle form submissions triggered by the return/enter key
   */
  const onKeyboardSubmit = (e: FormEvent | InputEvent) => {
    e.preventDefault();
    formHandlers.handleSubmit(handleKeyboardSubmit)();
  };

  const formGroupElements = [
    ...regularGroups,
    ...(showAdvanced ? advancedGroups : []),
  ].map((group, i) => {
    return (
      <FormGroup
        heading={group.heading}
        description={group.description}
        fields={group.fields}
        formDefinition={definition}
        formHandlers={formHandlers}
        watchedValues={watchedValues}
        key={`group-${i}`}
        groupIndex={i}
      />
    );
  });

  const advancedButton = (
    <div ref={advancedFieldsRef} key="advanced-button">
      <Button
        type={"button"}
        // When closed, we need to space the "advanced" button away from the form footer
        className={cn("-ml-2", showAdvanced ? "-mb-4" : "mb-8")}
        variant="subtle"
        onClick={() => {
          setShowAdvanced(!showAdvanced);
        }}
        rightIcon={showAdvanced ? "chevronUp" : "chevronDown"}
        text="Advanced"
        size="xs"
      />
    </div>
  );

  // Insert the advanced button after the last regular group
  if (advancedGroups.length > 0) {
    formGroupElements.splice(regularGroups.length, 0, advancedButton);
  }

  const form = (
    <form onSubmit={onKeyboardSubmit} action="">
      {formGroupElements}
      {/* Hidden submit button to allow browsers to submit form when enter/return key is pressed */}
      <button type="submit" className="hidden">
        Submit
      </button>
    </form>
  );

  return {
    form,
    formHandlers,
    // @ts-expect-error - This is probably fixable, but it's not worth the effort
    displayApiValidationErrors: displayApiValidationErrors(formHandlers),
  };
};
