import { MultiSelectProps, SelectItem } from "shared/lib";

import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { MultiSelect } from "@/modules/common/form/select/MultiSelect";

const items: SelectItem[] = [
  {
    value: "laravel",
    label: "Laravel",
  },
  {
    value: "symfony",
    label: "Symfony",
  },
  {
    value: "node.js",
    label: "Node.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
  {
    value: "django",
    label: "Django",
  },
  {
    value: "react.js",
    label: "React.js",
  },
  {
    value: "vue.js",
    label: "Vue.js",
  },
];

const itemsWithAvatar = items.map((item: SelectItem) => {
  return {
    avatar: "https://github.com/shadcn.png",
    ...item,
  };
});

const itemsWithDualContent = items.map((item: SelectItem) => {
  return {
    description: "Here is dual content",
    ...item,
  };
});

const options: Array<Option<MultiSelectProps>> = [
  {
    title: "Simple multi select",
    props: [
      {
        data: items,
      },
    ],
  },
  {
    title: "With different entity name",
    props: [
      {
        data: items,
        entityName: "frameworks",
      },
    ],
  },
  {
    title: "With dual content",
    props: [
      {
        data: itemsWithDualContent,
      },
    ],
  },
  {
    title: "With avatar",
    props: [
      {
        data: itemsWithAvatar,
      },
    ],
  },
  {
    title: "With local search",
    props: [
      {
        data: items,
        localSearch: true,
      },
    ],
  },
  {
    title: "With loading",
    props: [
      {
        data: items,
        loading: true,
        onSelect: selectedItems =>
          console.log("selectedItems: ", selectedItems),
      },
    ],
  },
  {
    title: "No data found",
    props: [
      {
        data: [],
        onSelect: selectedItems =>
          console.log("selectedItems: ", selectedItems),
      },
    ],
  },
];

export const MultiSelectPage = () => {
  const transformProps = (
    props: MultiSelectProps[],
  ): CodeProps<MultiSelectProps>[] => {
    return props.map(function (prop: MultiSelectProps) {
      if (prop.data) {
        return {
          ...prop,
          data: "{data}",
        };
      }

      if (prop.transform) {
        return {
          ...prop,
          transform: "{(items: object[]) => void}",
        };
      }
      return prop;
    });
  };

  return (
    <DocWrapper
      title={"Multi Select"}
      component={MultiSelect}
      options={options}
      transformProps={transformProps}
    />
  );
};
