import {
  AggregateClassDto,
  CourseDto,
  CreateCourseRequest,
  LocationDto,
  PricingScheme,
  RoomDto,
  SeasonDto,
  TaxRateDto,
  UpdateCourseRequest,
} from "@justraviga/classmanager-sdk";
import type { ClassStatsDto } from "@justraviga/classmanager-sdk/dist/models/ClassStatsDto";
import { match, Pattern } from "ts-pattern";

import { range } from "./arrayUtils";
import {
  CreateSchema as ClassCreateSchema,
  UpdateSchema as ClassUpdateSchema,
} from "./components/formDefinitions/courseForm";
import { SelectItem } from "./components/interfaces";
import { LessonRecord } from "./data/useClassLessonsData";
import { calculateEndTime, dateToday } from "./dateUtils";
import { ClassEntityProps } from "./entities";
import { formatDate, formatTime } from "./intlFormatter";
import { omit } from "./objectUtils";
import { SeasonStatus } from "./seasonUtils";
import { entityTranslations, enumLabel } from "./translateUtils";

export const classDurations = [
  // 5-minute increments to 2 hours
  { label: "5m", value: 5 },
  { label: "10m", value: 10 },
  { label: "15m", value: 15 },
  { label: "20m", value: 20 },
  { label: "25m", value: 25 },
  { label: "30m", value: 30 },
  { label: "35m", value: 35 },
  { label: "40m", value: 40 },
  { label: "45m", value: 45 },
  { label: "50m", value: 50 },
  { label: "55m", value: 55 },
  { label: "1hr", value: 60 },
  { label: "1hr 5m", value: 65 },
  { label: "1hr 10m", value: 70 },
  { label: "1hr 15m", value: 75 },
  { label: "1hr 20m", value: 80 },
  { label: "1hr 25m", value: 85 },
  { label: "1hr 30m", value: 90 },
  { label: "1hr 35m", value: 95 },
  { label: "1hr 40m", value: 100 },
  { label: "1hr 45m", value: 105 },
  { label: "1hr 50m", value: 110 },
  { label: "1hr 55m", value: 115 },
  { label: "2hr", value: 120 },

  // 15-minute increments to 4 hours
  { label: "2hr 15m", value: 135 },
  { label: "2hr 30m", value: 150 },
  { label: "2hr 45m", value: 165 },
  { label: "3hr", value: 180 },
  { label: "3hr 15m", value: 195 },
  { label: "3hr 30m", value: 210 },
  { label: "3hr 45m", value: 225 },
  { label: "4hr", value: 240 },

  // 30-minute increments to 12 hours
  { label: "4hr 30m", value: 270 },
  { label: "5hr", value: 300 },
  { label: "5hr 30m", value: 330 },
  { label: "6hr", value: 360 },
  { label: "6hr 30m", value: 390 },
  { label: "7hr", value: 420 },
  { label: "7hr 30m", value: 450 },
  { label: "8hr", value: 480 },
  { label: "8hr 30m", value: 510 },
  { label: "9hr", value: 540 },
  { label: "9hr 30m", value: 570 },
  { label: "10hr", value: 600 },
  { label: "10hr 30m", value: 630 },
  { label: "11hr", value: 660 },
  { label: "11hr 30m", value: 690 },
  { label: "12hr", value: 720 },
];

export const classYearData = (): SelectItem[] => {
  const options: SelectItem[] = [
    { label: "0 years", value: 0 },
    { label: "6 months", value: 6 },
    { label: "12 months", value: 12 },
    { label: "18 months", value: 18 },
  ];

  range(129).forEach(year =>
    options.push({
      label: `${year + 1} years`,
      value: (year + 1) * 12,
    }),
  );

  return options;
};

export function createClassUpdateFormDefaultValues(
  aggClass: AggregateClassDto,
  taxRates: TaxRateDto[],
  defaultTaxRateId: string | undefined,
) {
  const { entity: classDto, settings } = aggClass;

  if (!taxRates.find(taxRate => settings.taxRateId === taxRate.id)) {
    settings.taxRateId = defaultTaxRateId;
  }

  return {
    ...classDto,
    limitedCapacity: classDto.capacity !== 0,
    durationInMinutes: classDto.durationInMinutes,
    ...settings,
  };
}

export const transformData = (
  data: ClassCreateSchema | ClassUpdateSchema,
): CreateCourseRequest | UpdateCourseRequest => {
  data.settings = {
    discountable: data.discountable,
    portalEnrolmentEnabled: data.portalEnrolmentEnabled,
    trialsEnabled: data.trialsEnabled ?? false,
    taxRateId: data.taxRateId,
  };

  /**
   * We only use the limitedCapacity flag on the form, we don't send it
   * to the API, so we need to check it here and set the capacity appropriately
   */
  if (!data.limitedCapacity) {
    data.capacity = 0;
  }

  return omit(data, [
    "discountable",
    "limitedCapacity",
    "taxRateId",
    "image",
    "trialsEnabled",
  ]);
};

export function minutesToHumanReadable(durationInMinutes: number): string {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  return [hours ? `${hours}hr` : "", minutes ? `${minutes}m` : ""]
    .join(" ")
    .trim();
}

export function monthsToHumanReadable(months: number): string | null {
  if (months === 0) return null;

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  const yearsPart = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
  const monthsPart =
    remainingMonths > 0
      ? `${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`
      : "";

  return [yearsPart, monthsPart].filter(Boolean).join(" ").trim();
}

export const getLocationDetails = (
  rooms: RoomDto[] | undefined,
  locations: LocationDto[] | undefined,
  roomId: string | null | undefined,
): string => {
  if (!roomId || !rooms || !locations) return "-";

  const room = rooms.find(r => r.id === roomId);
  const location = locations.find(l => l.id === room?.locationId);

  return `${location?.name} - ${room?.name}`;
};

export const ageRangeDescription = (minMonths: number, maxMonths: number) => {
  return match([minMonths, maxMonths])
    .with([0, 0], () => "Any age")
    .with(
      [Pattern.number.gt(0), 0],
      () => `${monthsToHumanReadable(minMonths)}+`,
    )
    .with(
      [0, Pattern.number.gt(0)],
      () => `Less than ${monthsToHumanReadable(maxMonths)}`,
    )
    .with(
      [Pattern.number.gt(0), Pattern.number.gt(0)],
      () =>
        `${monthsToHumanReadable(minMonths)} - ${monthsToHumanReadable(maxMonths)}`,
    )
    .run();
};

export const classEndTime = (startTime: string, durationInMinutes: number) => {
  return calculateEndTime(startTime, durationInMinutes);
};

export const classMinAge = ({ class: classDto }: ClassEntityProps) =>
  monthsToHumanReadable(classDto.minAgeMonths);

export const classMaxAge = ({ class: classDto }: ClassEntityProps) =>
  monthsToHumanReadable(classDto.maxAgeMonths);

export const courseIsSuitableForAge = (
  course: AggregateClassDto,
  ageInYears: number,
) => {
  return (
    course.entity.minAgeMonths / 12 <= ageInYears &&
    (course.entity.maxAgeMonths === 0 ||
      course.entity.maxAgeMonths / 12 >= ageInYears)
  );
};

export const displayLessonTimes = ({
  startTime,
  durationInMinutes,
}: {
  startTime: string;
  durationInMinutes: number;
}) => {
  const start = formatTime(startTime, "hourMinute");
  const end = formatTime(
    classEndTime(startTime, durationInMinutes),
    "hourMinute",
  );

  return `${start} - ${end}`;
};

export const displayLessonTimesExact = ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  const start = formatTime(startTime, "hourMinute");
  const end = formatTime(endTime, "hourMinute");

  return `${start} - ${end}`;
};

export const displayCourseDates = (startDate: string, endDate: string) => {
  const startYear = startDate.substring(0, 4);
  const endYear = endDate.substring(0, 4);

  const start =
    startYear === endYear
      ? formatDate(startDate, "month")
      : formatDate(startDate, "monthYear");
  const end = formatDate(endDate, "monthYear");

  return `${start} - ${end}`;
};

export const getAvailableSpacesForCourse = (course: AggregateClassDto) => {
  return (
    course.entity.capacity -
    course.stats.enrolments.currentCount -
    course.stats.enrolments.upcomingCount -
    course.stats.waitingLists.totalCount
  );
};

export const courseIsFull = (course: AggregateClassDto) => {
  if (course.entity.capacity === 0) {
    return false;
  }

  return getAvailableSpacesForCourse(course) <= 0;
};

interface SimplifiedAggregateCourse {
  entity: Pick<CourseDto, "capacity">;
  stats: Pick<ClassStatsDto, "enrolments">;
}

// If class capacity is unlimited: always show Card: 'Spaces available’ / Hover: 'Spaces available’
// If class capacity has spaces available and has a set capacity: Card: ‘5 spaces’ / Tooltip: ‘5 spaces available’
// If the class capacity has been reached OR exceeded: Card: ‘Full’ Hover: 'No spaces available' - should not see '-6 spaces' - shown on web small breakpoint currently
export const classAvailableSpacesInformation = ({
  course: {
    entity: { capacity },
    stats: { enrolments },
  },
  seasonStatus,
}: {
  course: SimplifiedAggregateCourse;
  seasonStatus: SeasonStatus;
}): {
  text: string;
  description: string;
} => {
  const enrolmentCount =
    seasonStatus === "current"
      ? enrolments.currentCount
      : seasonStatus === "upcoming"
        ? enrolments.upcomingCount
        : seasonStatus === "past"
          ? enrolments.pastCount
          : 0;

  if (capacity === 0) {
    return {
      text: "Spaces available",
      description: "Spaces available",
    };
  }

  if (enrolmentCount >= capacity) {
    return {
      text: "Full",
      description: "No spaces available",
    };
  }

  const spacesLeft = capacity - enrolmentCount;
  const spaceStr = spacesLeft === 1 ? "space" : "spaces";

  return {
    text: `${spacesLeft} ${spaceStr}`,
    description: `${spacesLeft} ${spaceStr} available`,
  };
};

export const classEnrolmentDescription = ({
  class: classDto,
  enrolments,
}: ClassEntityProps) => {
  if (classDto.capacity == 0) {
    return `${enrolments.currentCount} students`;
  }

  return `${enrolments.currentCount}/${classDto.capacity} students`;
};

export const coursePriceDisplay = (
  price: string,
  pricingScheme: PricingScheme,
) => {
  if (["class-count", "total-duration"].includes(pricingScheme)) {
    return "See pricing scheme";
  }

  if (pricingScheme === "none" && price) {
    return `${price} per month`;
  }

  return "-";
};

export const groupClassLessonRecordsByDate = (
  records: LessonRecord[],
): {
  upcoming: LessonRecord[];
  past: LessonRecord[];
} => {
  const now = dateToday();

  return {
    upcoming: records.filter(
      record => `${record.lesson.date} ${record.lesson.startTime}` >= now,
    ),
    past: records.filter(
      record => `${record.lesson.date} ${record.lesson.startTime}` < now,
    ),
  };
};

export const getClassSubtitle = (
  course: CourseDto,
  season?: SeasonDto,
  showEndTime = false,
) =>
  enumLabel("weekDaysShort", course.dayOfWeek) +
  " • " +
  formatTime(course.startTime, "hourMinute") +
  (showEndTime
    ? " - " +
      formatTime(
        classEndTime(course.startTime, course.durationInMinutes),
        "hourMinute",
      )
    : "") +
  (season ? " • " + season.name : "");

export const getClassTrialSubtitle = (course: CourseDto) =>
  enumLabel("weekDaysShort", course.dayOfWeek) +
  " • " +
  formatTime(course.startTime, "hourMinute");

export const getPricingForCourse = (
  mode: "enrol" | "trial",
  coursePrice: number | null,
  pricingScheme: PricingScheme,
  trialPrice: number,
): string | { amount: number; unit: string } => {
  if (mode === "enrol" && pricingScheme !== "none") {
    return "See pricing list";
  }

  if (mode === "trial" && trialPrice === 0) {
    return "FREE";
  }

  if (mode === "enrol" && !coursePrice) {
    return "FREE";
  }

  const price = mode === "enrol" ? coursePrice! : trialPrice;

  return {
    amount: price,
    unit: mode === "enrol" ? "month" : "lesson",
  };
};

export const getLessonLabel = (numberOfLessons: number) => {
  if (numberOfLessons === 1) {
    return "lesson";
  }

  return "lessons";
};

export const getClassLabel = (numberOfClasses: number) => {
  const [single, plural] = entityTranslations["course"];

  if (numberOfClasses > 1) {
    return plural.toLowerCase();
  }

  return single.toLowerCase();
};
