import { PropsWithChildren, ReactNode } from "react";

import { Breadcrumb, Breadcrumbs } from "shared/components";
import { useBreakpoint } from "shared/lib";

import {
  CardContainer,
  CardContainerActions,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";

interface CardPageProps extends PropsWithChildren {
  crumbs?: Breadcrumb[];
  hideGlobalHeader?: boolean;
  isEmpty: boolean;
  isLoading?: boolean;
  menuItems?: ActionMenuItemProps[];
  primaryAction?: PrimaryActionItemProps;
  title: string;
  banner?: ReactNode;
}

export const CompanyCardPage = ({
  title,
  primaryAction,
  menuItems,
  isEmpty,
  isLoading,
  crumbs = [],
  children,
  hideGlobalHeader = false,
  banner,
}: CardPageProps) => {
  const breakpoints = useBreakpoint();

  return (
    <CompanyLayout alwaysShowGlobalHeader={!hideGlobalHeader}>
      <CardContainer
        variant={"page"}
        isEmpty={isEmpty}
        isLoading={isLoading}
        primaryAction={primaryAction}
        menuItems={menuItems}>
        {breakpoints.md && (
          <CompanyTitleBar isTopLevel={false}>
            <Breadcrumbs items={crumbs} />
          </CompanyTitleBar>
        )}
        <CompanyTitleBar isTopLevel={true}>
          <div className="flex flex-row items-center justify-start">
            {hideGlobalHeader && !breakpoints.md && (
              <IconButton
                data-testid="back-icon-btn"
                variant="standard"
                icon="chevronBackOutline"
                onClick={() => window.history.back()}
              />
            )}
            <CompanyTitleBarTitle title={title} />
          </div>
          <CardContainerActions />
        </CompanyTitleBar>
        {banner && (
          <CompanyTitleBar isTopLevel={false}>{banner}</CompanyTitleBar>
        )}
        <CompanyContent>{children}</CompanyContent>
      </CardContainer>
    </CompanyLayout>
  );
};
